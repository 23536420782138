//barchart.js
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { ApplicationContext, ProviderContext } from "../../ApplicationState";
import * as ApiService from "../../services/ApiService";
import { numberOfReadings } from "../../services/Helpers";

const TotalReadingsPieChart: React.FC<any> = (props: any) => {
  const { providerState } = useContext(ProviderContext);
  const { userState } = useContext(ApplicationContext);
  const [showLoading, setShowLoading] = useState(true);
  const [readings, setReadings] = React.useState<any[]>([]);
  const [labels, setLabels] = useState([]);
  const dateFormatString = "YYYY-MM-DD";
  const lbls = [
    "Blood Pressure",
    "Heart Rate",
    "Oxygen Saturation",
    "Temperature",
    "Weight",
    "Blood Glucose",
  ];
  const [chartData, setData] = useState([]);
  const [pieColors, setPieColors] = useState([]);
  const [lineOptions, setLineOptions]: any = useState({
    tooltips: {
      enabled: true,
    },
    legend: {
      display: true,
      position: "right",
    },
    pieceLabel: {
      render: "value",
      fontSize: 12,
      fontColor: "#fff",
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      textShadow: true,
      shadowBlur: 10,
      shadowOffsetX: -5,
      shadowOffsetY: 5,
      shadowColor: "rgba(255,0,0,0.75)",
      arc: true,
    },
  });

  let displayLabels: boolean = true;
  const data = {
    labels,
    datasets: [
      {
        data: chartData,
        backgroundColor: pieColors,
      },
    ],
  };

  useEffect((): any => {
    let isSubscribed = false;

    let startDate = moment(new Date(), dateFormatString).add(-1, "months");
    let endDate = moment(new Date(), dateFormatString);
    if (props.type === "Group") {
      if (
        props?.groupId !== undefined &&
        userState.authenticatedUser !== undefined
      ) {
        ApiService.fetchGroupReadingsCount(
          userState.authenticatedUser,
          props.groupId,
          startDate.format(dateFormatString),
          endDate.format(dateFormatString)
        )
          .then((data) => {
            if (!isSubscribed) {
              if (data && data.length > 0) {
                var lblNames = [];
                var lblData = [];
                data.map((r) => {
                  lblNames.push(r.measurementType);
                  lblData.push(r.count);
                });
                setLabels(lblNames);
                setData(lblData);
                let colors: any = [];
                lblData.map((r) => {
                  colors.push(getRandomColor());
                });
                setPieColors(colors);
                setLineOptions({
                  tooltips: {
                    enabled: true,
                  },
                  legend: {
                    display: lblData.length < 10,
                    position: "right",
                  },
                });
              }
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    } else if (
      props.type === "User" &&
      userState.authenticatedUser !== undefined
    ) {
      if (props.userId !== undefined) {
        ApiService.fetchUserReadingsCount(
          userState.authenticatedUser,
          props.userId,
          startDate.format(dateFormatString),
          endDate.format(dateFormatString)
        )
          .then((data) => {
            if (!isSubscribed) {
              if (data && data.length > 0) {
                var lblNames = [];
                var lblData = [];
                data.map((r) => {
                  lblNames.push(r.measurementType);
                  lblData.push(r.count);
                });
                setLabels(lblNames);
                setData(lblData);
                let colors: any = [];
                lblData.map((r) => {
                  colors.push(getRandomColor());
                });
                setPieColors(colors);
                setLineOptions({
                  tooltips: {
                    enabled: true,
                  },
                  legend: {
                    display: lblData.length < 10,
                    position: "right",
                  },
                });
              }
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    }
    return () => (isSubscribed = true);
  }, [props.userId, props.type, providerState.providerId]);

  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  return <Pie data={data} options={lineOptions} />;
};
export default TotalReadingsPieChart;
