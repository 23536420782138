import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonMenuToggle,
  IonItem,
  IonLabel,
  IonIcon,
  IonRouterLink,
} from "@ionic/react";
import { Auth } from "aws-amplify";
import React, { useContext, useEffect } from "react";
import { ApplicationContext, ProviderContext } from "../../ApplicationState";
import * as Helpers from "../../services/Helpers";
import {
  addCircle,
  home,
  people,
  person,
  personAdd,
  call,
  exit,
  medkit,
  fileTrayStackedSharp,
  fileTrayFullSharp,
} from "ionicons/icons";
import { Chart, registerables } from "chart.js";
import * as Helper from "../../services/Helpers";
import { useHistory } from "react-router-dom";

export const Menu = () => {
  Chart.register(...registerables);
  const { userState, setUserState } = useContext(ApplicationContext);
  const { providerState } = useContext(ProviderContext);
  const [userAttributes, setUserAttributes] = React.useState<any | undefined>(
    {}
  );

  const logout = () => {
    setUserState({ authenticatedUser: undefined });
    Auth.signOut();
  };

  useEffect(() => {
    let user = userState?.authenticatedUser;
    let attr = Helper.getUserAttributes(
      user?.signInUserSession["idToken"].jwtToken
    );
    setUserAttributes(attr);
  }, [userState]);

  return (
    <IonMenu contentId="main">
      <IonHeader>
        <IonToolbar color="light">
          <IonTitle>MENU</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonMenuToggle auto-hide="false">
            <IonItem button routerLink={"/home"} routerDirection="none">
              <IonLabel>Home</IonLabel>
              <IonIcon icon={home} />
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle auto-hide="false">
            {userAttributes &&
              (userAttributes["roles"]?.includes("Administrator") ||
                userAttributes["roles"]?.includes("Provider")) ? (
              <IonItem button routerLink={"/providers"} routerDirection="none">
                <IonLabel>Providers</IonLabel>
                <IonIcon icon={people} />
              </IonItem>
            ) : (
              ""
            )}
            <IonItem button routerLink={"/users"} routerDirection="none">
              <IonLabel>System Users</IonLabel>
              <IonIcon icon={people} />
            </IonItem>
            <IonItem button routerLink={"/groups"} routerDirection="none">
              <IonLabel>Patients Group</IonLabel>
              <IonIcon icon={people} />
            </IonItem>
            <IonItem button routerLink={"/threshold"} routerDirection="none">
              <IonLabel>Threshold</IonLabel>
              <IonIcon icon={people} />
            </IonItem>
            <IonItem button routerLink={"/schedule"} routerDirection="none">
              <IonLabel>Schedule Notifications</IonLabel>
              <IonIcon icon={people} />
            </IonItem>
            {/* <IonItem button routerLink={"/survey"} routerDirection="none">
              <IonLabel>Survey</IonLabel>
              <IonIcon icon={people} />
            </IonItem> */}
            <IonItem button routerLink={"/patients"} routerDirection="none">
              <IonLabel>{Helpers.getDisplayLabel(providerState)}</IonLabel>
              <IonIcon icon={medkit} />
            </IonItem>
            <IonItem button routerLink={"/contact-us"} routerDirection="none">
              <IonLabel>Contact Us</IonLabel>
              <IonIcon icon={call} />
            </IonItem>
            {userAttributes &&
              userAttributes["roles"]?.includes("Administrator") ? (
              <IonItem
                button
                routerLink={"/billing-report"}
                routerDirection="none"
              >
                <IonLabel>Billing Report</IonLabel>
                <IonIcon icon={fileTrayFullSharp} />
              </IonItem>
            ) : (
              ""
            )}
            {
              userAttributes && userAttributes["roles"]?.includes("Administrator") && (
                <IonItem button routerLink={"/hangfire"} routerDirection="none">
                  <IonLabel>Hangfire Dashboard</IonLabel>
                  <IonIcon icon={people} />
                </IonItem>
              )
            }
            <IonItem button routerLink={"/fileManager"} routerDirection="none">
              <IonLabel>FileManager</IonLabel>
              <IonIcon icon={people} />
            </IonItem>
            <IonItem
              button
              onClick={() => logout()}
              routerDirection="none"
              routerLink={"/home"}
            >
              <IonLabel>Signout</IonLabel>
              <IonIcon icon={exit} />
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};
