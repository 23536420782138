import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonPage,
  IonRow,
  IonCol,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonGrid,
  IonSelect,
  IonSelectOption,
  IonBackButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import { ApplicationContext, ProviderContext } from "../../ApplicationState";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Register.css";
import * as ApiService from "../../services/ApiService";
import * as Helper from "../../services/Helpers";
import { useForm, Controller } from "react-hook-form";
import { IUserProps } from "../../models/user";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { arrowBackCircleSharp } from "ionicons/icons";

let initialValues: IUserProps = {};
const Register: React.FC<any> = (props) => {
  const { userState, setUserState } = useContext(ApplicationContext);
  const [userAttributes, setUserAttributes] = React.useState<any | undefined>(
    {}
  );
  const [data, setData] = useState();
  const { providerState } = useContext(ProviderContext);
  const [phone, setPhoneValue] = useState();
  const [present, dismiss] = useIonLoading();

  const { control, handleSubmit, formState, reset, errors } = useForm({
    defaultValues: { ...initialValues },
    mode: "onChange",
  });

  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div style={{ color: "red", fontWeight: "bold" }}>
        {error.message || "Field Is Required"}
      </div>
    ) : null;
  };

  useEffect(() => {
    let user = userState.authenticatedUser;

    let attr = Helper.getUserAttributes(
      user.signInUserSession["idToken"].jwtToken
    );

    setUserAttributes(attr);
  }, [userState]);

  const onSubmit = (data) => {
    if (providerState !== undefined) {
      if (providerState.providerId !== "") {
        let dataToSubmit = data;
        let user = userState.authenticatedUser;
        data.providerCode = providerState.providerId;
        alert(JSON.stringify(data, null, 2));
        setData(data);
        present({
          message: 'Loading...',
        })
        ApiService.addUser(user, data)
          .then((data) => {
            dismiss()
            if (data) {
              alert('New User Created!');
            }
          })
          .catch((err) => {
            dismiss()
            alert(`Oops Something went wrong. Please try again.`);
          });
      }
    }
  };

  return (
    <IonPage>
      <Header />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text="Go Back"
              icon={arrowBackCircleSharp}
              defaultHref="/"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonItem>Create New User</IonItem>
                </IonCardHeader>
                <IonCardContent>
                  <IonRow>
                    <IonCol sizeXs="12" sizeSm="12" sizeLg="6" sizeMd="6">
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ padding: 18 }}
                      >
                        <IonItem>
                          <IonLabel>First Name</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <IonInput onIonChange={onChange} slot="end" />
                            )}
                            control={control}
                            name="firstName"
                            rules={{
                              required: true,
                            }}
                          />
                        </IonItem>
                        {showError("firstName")}
                        <IonItem>
                          <IonLabel>LastName</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <IonInput onIonChange={onChange} slot="end" />
                            )}
                            control={control}
                            name="lastName"
                            rules={{
                              required: true,
                            }}
                          />
                        </IonItem>
                        {showError("lastName")}
                        <IonItem>
                          <IonLabel>Email (username)</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <IonInput onIonChange={onChange} slot="end" />
                            )}
                            control={control}
                            name="email"
                            rules={{
                              required: true,
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address",
                              },
                            }}
                          />
                        </IonItem>
                        {showError("email")}
                        <IonItem>
                          <IonLabel>Sex</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <IonSelect
                                placeholder="Select One"
                                onIonChange={onChange}
                                slot="end"
                              >
                                <IonSelectOption value="FEMALE">
                                  Female
                                </IonSelectOption>
                                <IonSelectOption value="MALE">
                                  Male
                                </IonSelectOption>
                              </IonSelect>
                            )}
                            control={control}
                            name="gender"
                            rules={{ required: false }}
                          />
                        </IonItem>
                        {showError("gender")}
                        <IonItem>
                          <IonLabel>Phone</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <PhoneInput
                                international
                                placeholder="Enter phone number"
                                value={phone}
                                defaultCountry="US"
                                onChange={onChange}
                                slot="end"
                              />
                            )}
                            control={control}
                            name="phone"
                            rules={{
                              required: true,
                            }}
                          />
                        </IonItem>
                        {showError("phone")}
                        <IonItem>
                          <IonLabel>Role</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <IonSelect
                                placeholder="Select One"
                                onIonChange={onChange}
                                slot="end"
                              >
                                <IonSelectOption value="Provider">
                                  Provider
                                </IonSelectOption>
                                {/* <IonSelectOption value="Patient">Patient</IonSelectOption> */}
                                {userAttributes["roles"]?.includes(
                                  "Administrator"
                                ) ? (
                                  <IonSelectOption value="Administrator">
                                    Administrator
                                  </IonSelectOption>
                                ) : (
                                  ""
                                )}
                                {userAttributes["roles"]?.includes(
                                  "Administrator"
                                ) ? (
                                  <IonSelectOption value="Support">
                                    Support
                                  </IonSelectOption>
                                ) : (
                                  ""
                                )}
                              </IonSelect>
                            )}
                            control={control}
                            name="roles"
                            rules={{ required: false }}
                          />
                        </IonItem>
                        {showError("roles")}
                        <IonButton
                          type="submit"
                          disabled={formState.isValid === false}
                        >
                          submit
                        </IonButton>
                      </form>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Register;
