import React, {
  Component,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Responsive, WidthProvider } from "react-grid-layout";
import { Pencil } from "react-ionicons";
import { Link } from "react-router-dom";
import { ComponentLayout, LayoutView } from "../../types";
import LayoutItem from "./LayoutItem";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const rowHeights: { [key: string]: number } = { lg: 5, md: 3, sm: 2, xs: 1 };
const cols = { lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 };
const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480 };

interface Props {
  view: LayoutView;
  rerenderParent: any;
}

const Layout: React.FC<Props> = ({ view, rerenderParent }) => {
  const staticView = useMemo(
    () => view.componentLayout.map((layout) => ({ ...layout, static: false })),
    [view.componentLayout]
  );

  const [height, setHeight] = useState(rowHeights.lg);

  const onBreakpointChange = useCallback(
    (newBreakpoint: string) => {
      // setHeight(rowHeights[newBreakpoint]);
    },
    [setHeight]
  );

  return (
    <div>
      <div className="card">
        <Link
          className="card-header"
          to={{ pathname: `/configuration/${view.id}` }}
        >
          <Pencil />
        </Link>

        <div className="card-body overflow-auto ">
          <ResponsiveReactGridLayout
            className="layout"
            cols={cols}
            layouts={{ lg: staticView }}
            breakpoints={breakpoints}
            rowHeight={height}
            isBounded={true}
            onBreakpointChange={onBreakpointChange}
            measureBeforeMount={true}
            autoSize={true}
          >
            {staticView.map((componentLayout) => (
              <div
                key={componentLayout.i}
              // className="overflow-hidden layout-width "
              >
                <LayoutItem
                  componentLayout={componentLayout}
                  render={rerenderParent}
                />
              </div>
            ))}
          </ResponsiveReactGridLayout>
        </div>
      </div>
    </div>
  );
};

export default Layout;
