//barchart.js
import { isEqual } from "date-fns";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { Redirect, useHistory, useLocation } from "react-router";
import { ApplicationContext, ProviderContext } from "../../ApplicationState";
import * as ApiService from "../../services/ApiService";
import { numberOfReadings } from "../../services/Helpers";

export type GroupedReadingMethodChartProps = {
  startDate: any;
  endDate: any;
};

const GroupedReadingMethodChart: React.FC<GroupedReadingMethodChartProps> = ({
  startDate,
  endDate,
}) => {
  const chartRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const { providerState } = useContext(ProviderContext);
  const { userState } = useContext(ApplicationContext);
  const [showLoading, setShowLoading] = useState(true);
  const [readings, setReadings] = React.useState<any[]>([]);
  const [labels, setLabels] = useState([]);
  const dateFormatString = "YYYY-MM-DD";

  let mLabels = [];

  const [lineOptions, setLineOptions]: any = useState({
    tooltips: {
      enabled: true,
    },
    legend: {
      display: true,
      position: "right",
    },
    pieceLabel: {
      render: "value",
      fontSize: 12,
      fontColor: "#fff",
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      textShadow: true,
      shadowBlur: 10,
      shadowOffsetX: -5,
      shadowOffsetY: 5,
      shadowColor: "rgba(255,0,0,0.75)",
      arc: true,
    },
  });
  const [tooltip, setTooltip] = useState({
    opacity: 0,
    top: 0,
    left: 0,
    date: "",
    value: "",
  }); //initial tooltip state
  const [chartData, setData] = useState([]);
  const cData: any = {
    labels: labels,
    datasets: chartData,
  };

  const state = {
    data: cData,
  };

  const options = {
    plugins: {
      indexAxis: "y" as const,
      responsive: true,
      legend: {
        display: true,
      },
      type: "bar",
      scales: {
        xAxes: [
          {
            stacked: false,
          },
        ],
        yAxes: [
          {
            stacked: false,
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
    tooltip: {},
  };

  useEffect((): any => {
    let isSubscribed = false;
    // let startDate = moment(new Date(), dateFormatString).add(-12, "months");
    // let endDate = moment(new Date(), dateFormatString);
    if (userState.authenticatedUser !== undefined) {
      if (!isSubscribed) {
        ApiService.fetchReadingCountWithMethod(
          userState.authenticatedUser,
          providerState.providerId,
          startDate.format(dateFormatString),
          endDate.format(dateFormatString)
        )
          .then((data) => {
            let automated = [];
            let manual = [];
            let newLabels = [];
            data.map((r) => {
              newLabels.push(r.measurementType);
              automated.push(r.automatedCount);
              manual.push(r.manualCount);
            });
            setLabels(newLabels);
            setData([
              {
                label: "Automated Reading",
                backgroundColor: "rgba(232,111,19,1)",
                borderColor: "rgba(232,111,19,1)",
                borderWidth: 1,
                //stack: 1,
                hoverBackgroundColor: "rgba(232,111,19,1,0.9)",
                hoverBorderColor: "rgba(232,111,19,1,1)",
                data: automated,
              },

              {
                label: "Manual Reading",
                backgroundColor: "rgba(25,0,166,1)",
                borderColor: "rgba(25,0,166,1)",
                borderWidth: 1,
                //stack: 1,
                hoverBackgroundColor: "rgba(25,0,166,0.9)",
                hoverBorderColor: "rgba(25,0,166,1)",
                data: manual,
              },
            ]);
          })
          .catch((err) => {
            alert(err);
          });
      }
      return () => (isSubscribed = true);
    }
  }, [providerState.providerId]);

  const onClick = (event) => {
    const elem = getElementAtEvent(chartRef.current, event);
    // if (elem[0] !== undefined) {
    //   console.log(elem[0]);
    //   console.log(elem[0].index);
    //   console.log(elem[0].datasetIndex);
    // }
    if (elem.length > 0) {
      let readingType = cData.datasets[elem[0].datasetIndex].label;
      let measurementType = labels[elem[0].index];
      //console.log(readingType, measurementType);
      let loc = {
        pathname: "/group-readings",
        state: {
          fromDashboard: true,
          measurementType: measurementType,
          readingType: readingType,
        },
      };
      history.push(loc);
      history.go(0);
    }
  };

  return (
    <div>
      <Bar
        data={state.data}
        options={options}
        onClick={onClick}
        ref={chartRef}
      />
      <div
        className="tooltip"
        style={{
          top: tooltip.top,
          left: tooltip.left,
          opacity: tooltip.opacity,
        }}
      >
        <p>{tooltip.date} </p>
        <p>{tooltip.value} </p>
      </div>
    </div>
  );
};
export default GroupedReadingMethodChart;
