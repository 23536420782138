import React from "react";
import Amplify, { Auth } from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import HomeProps from "./../pages/Home";
import { attachProps } from "@ionic/react/dist/types/components/utils";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { Component } from "ionicons/dist/types/stencil-public-runtime";
import { IUserProps } from "../models/user";
import { ProviderState } from "../ApplicationState";
import { boolean } from "yargs";

const mLabels = [
  "Blood Pressure",
  "Heart Rate",
  "Oxygen Saturation",
  "Temperature",
  "Weight",
  "Blood Glucose",
];

export const readingTypes = [
  "Automatic Reading",
  "Manual Reading",
  "All",
];

export const formatReading = (data: any) => {
  let readingObj: any = JSON.parse(data.reading);
  switch (data.measurementType) {
    case "Temperature":
      return readingObj.value + " " + readingObj.unit;
      break;
    case "Oxygen Saturation":
      return readingObj.value + " " + readingObj.unit;
      break;
    case "Heart Rate":
      if (readingObj.pulseRate !== undefined)
        return readingObj.pulseRate + " " + readingObj.unit;
      return readingObj.value + " " + readingObj.unit;
      break;
    case "Blood Pressure":
      return (
        readingObj.systolic +
        " / " +
        readingObj.diastolic +
        " " +
        readingObj.unit
      );
      break;
    case "Blood Glucose":
      return readingObj.value + " " + readingObj.unit;
      break;
    case "Weight":
      return readingObj.value + " " + readingObj.unit;
      break;
    default:
      return data.reading;
      break;
  }
};

export const formatMeasurementType = (data: any) => {
  let readingObj: any = JSON.parse(data.reading);
  switch (data.measurementType) {
    case "Heart Rate":
      if (readingObj.systolic !== undefined) return "Heart Rate (BP)";
      else if (readingObj.value !== undefined) return "Heart Rate (Spo2)";
      else return data.measurementType;
      break;
    default:
      return data.measurementType;
      break;
  }
};

export const filterReadings = (data: any, field: string, value: any) => {
  if (field === "isManualEntry") {
    if (value === "Manual Reading")
      return data.filter((e) => e.isManualEntry === true);
    else return data.filter((e) => e.isManualEntry === false);
  } else {
    return data;
  }
};

export const numberOfReadings = (data: any, type: string) => {
  //let readingObj :any = JSON.parse(data.reading);
  switch (type) {
    case "Temperature":
      return data.filter((e) => e.measurementType === "Temperature").length;
      break;
    case "Oxygen Saturation":
      return data.filter((e) => e.measurementType === "Oxygen Saturation")
        .length;
      break;
    case "Heart Rate":
      return data.filter((e) => e.measurementType === "Heart Rate").length;
      break;
    case "Blood Pressure":
      return data.filter((e) => e.measurementType === "Blood Pressure").length;
      break;
    case "Blood Glucose":
      return data.filter((e) => e.measurementType === "Blood Glucose").length;
      break;
    case "Weight":
      return data.filter((e) => e.measurementType === "Weight").length;
      break;
    default:
      return data.length;
      break;
  }
};

export const measurementTypes = () => {
  return mLabels;
};

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  if (array != undefined) {
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item.value,
      };
    }, initialValue);
  }
};

export const convertInches = (inches) => {
  if (inches == undefined) return "";
  let feetFromInches = Math.floor(inches / 12); //There are 12 inches in a foot
  let inchesRemainder = inches % 12;

  let result = feetFromInches + "' " + inchesRemainder + '"';
  return result;
};

export const getDecodedToken = (token: string) => {
  try {
    return jwt_decode(token);
  } catch (Error) {
    return null;
  }
};

export const getRoles = (token: string) => {
  try {
    var decoded = jwt_decode(token);
    return decoded["custom:Roles"].split(",");
  } catch (Error) {
    return null;
  }
};

export const getUserAttributes = (token: string) => {
  try {
    //get the userrole from the internal api call
    var decoded = jwt_decode(token);
    let attributes: IUserProps = {};
    attributes.username = decoded["cognito:username"];
    attributes.providerCode = decoded["custom:providercode"];
    attributes.firstName = decoded["given_name"];
    attributes.lastName = decoded["family_name"];
    attributes.email = decoded["email"];
    attributes.phoneNumber = decoded["phone_number"];
    attributes.height = decoded["custom:height"];
    attributes.roles = decoded["custom:Roles"].split(",");
    attributes.birthDate = decoded["birthdate"];
    attributes.groups = decoded["cognito:groups"];

    return attributes;
  } catch (Error) {
    return null;
  }
};

export const formatUserForUpdate = (user: any) => {
  try {
    let attributes: IUserProps = {};
    attributes.username = user["sub"];
    attributes.providerCode = user["custom:providercode"];
    attributes.firstName = user["given_name"];
    attributes.lastName = user["family_name"];
    attributes.email = user["email"];
    attributes.phoneNumber = user["phone_number"];
    attributes.height = user["custom:height"];
    attributes.roles = user["custom:Roles"]?.split(",")[0];
    attributes.birthDate = user["birthdate"];
    attributes.groups = user["cognito:groups"];
    attributes.gender = user["gender"] === undefined ? "" : user["gender"];
    return attributes;
  } catch (Error) {
    return null;
  }
};

export const orderReadingsDescending = (data: any) => {
  var sortOrder = 1;
  if (data[0] === "-") {
    sortOrder = -1;
    data = data.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result = a[data] < b[data] ? -1 : a[data] > b[data] ? 1 : 0;
    return result * sortOrder;
  };
};

export const formatReadingForChart = (data: any) => {
  let formatted: any = [];
  data.map((r) => {
    let readingObj: any = JSON.parse(r.reading);

    switch (r.measurementType) {
      case "Temperature":
        var type = {
          type: "Temperature",
          value: readingObj.value,
          date: readingObj.date,
          unit: readingObj.unit,
        };
        formatted.push(type);
        break;
      case "Oxygen Saturation":
        var type = {
          type: "Oxygen Saturation",
          value: readingObj.value,
          date: readingObj.date,
          unit: readingObj.unit,
        };
        formatted.push(type);
        break;
      case "Heart Rate":
        if (readingObj.pulseRate !== undefined)
          formatted.push({
            type: "Heart Rate",
            value: readingObj.pulseRate,
            date: readingObj.date,
            unit: readingObj.unit,
          });
        else
          formatted.push({
            type: "Heart Rate",
            value: readingObj.value,
            date: readingObj.date,
            unit: readingObj.unit,
          });
        break;
      case "Blood Pressure":
        formatted.push({
          type: "Systolic",
          value: readingObj.systolic,
          date: readingObj.date,
          unit: readingObj.unit,
        });
        formatted.push({
          type: "Diastolic",
          value: readingObj.diastolic,
          date: readingObj.date,
          unit: readingObj.unit,
        });

        break;
      case "Blood Glucose":
        formatted.push({
          type: "Blood Glucose",
          value: readingObj.value,
          date: readingObj.date,
          unit: readingObj.unit,
        });
        break;
      case "Weight":
        formatted.push({
          type: "Weight",
          value: readingObj.value,
          date: readingObj.date,
          unit: readingObj.unit,
        });
        break;
      default:
        break;
    }
  });
  var grouped = groupArrayOfObjects(formatted, "type");

  return grouped;
};

function groupArrayOfObjects(list, key) {
  return list.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export const base64ToBlob = (b64Data, contentType = "", sliceSize = 512) => {
  var b64Data = b64Data.replace(/\s/g, ""); //IE compatibility...
  let byteCharacters = atob(b64Data);
  let byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};

export function goBack(cntx): void {
  if (cntx.routerOutlet && cntx.routerOutlet.canGoBack()) {
    cntx.navCtrl.setDirection("back");
    cntx.routerOutlet.pop();
  } else {
    cntx.navCtrl.navigateBack("/home");
  }
}

export function getDisplayLabel(providerState: ProviderState): any {
  let label: string = "Patients";
  switch (providerState?.providerType) {
    case "Wellness Programs":
      label = "Users";
      break;
    case "Managed Care":
      label = "Members";
      break;
    case "Providers":
      label = "Patients";
      break;
    default:
      break;
  }
  return label;
}
