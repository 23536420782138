import React, { useContext, useEffect, useState } from "react";
import {
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonCol,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
} from "@ionic/react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./UserProfile.css";
import ReadingsComponent from "../../components/ReadingsComponent";
import { useHistory, useLocation } from "react-router";
import { convertArrayToObject, convertInches } from "./../../services/Helpers";
import { arrowBackCircleSharp, create } from "ionicons/icons";
import LatestReadings from "../Readings/LatestReadings";
import TotalReadingsChart from "../../components/Charts/TotalReadingsChart";
import MeasurementChart from "../../components/Charts/MeasurementChart";
import TotalReadingsPieChart from "../../components/Charts/TotalReadingsPieChart";
import GroupedReadingMethodChart from "../../components/Charts/GroupedReadingMethodChart";
import { CircularProgress } from "@mui/material";
import { DefaultThreshold } from "../../components/UsersThreshold/DefaultThreshold";
import { DefaultState } from "../Threshold/ThresholdModel";
import { formValidation } from "../../components/FormField/FormValidation";
import { ApplicationContext } from "../../ApplicationState";
import { format } from "date-fns";
import { isEmpty } from "@aws-amplify/core";
import { useUserProfile } from "./useUserProfile";

var _ = require("lodash");

const UserProfile: React.FC<any> = (props) => {
  const history: any = useHistory();
  const location: any = useLocation();
  const { fetchSelectedUserMeasurement } = useUserProfile();
  const [selectedUser, setSelectedUser] = useState({});
  const [displayName, setDisplayName] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [measurementValues, setMeasurementValues] = useState({});
  const [defaultValues, setDefaultValues] = React.useState<DefaultState>({
    bloodPressureSystolicMin: "",
    bloodPressureSystolicMax: "",
    bloodPressureDiastolicMin: "",
    bloodPressureDiastolicMax: "",
    weightMin: "",
    weightMax: "",
    temperatureMin: "",
    temperatureMax: "",
    oxygenMin: "",
    oxygenMax: "",
    bloodGlucoseMin: "",
    bloodGlucoseMax: "",
    bloodSaturation: "",
    pulseMin: "",
    pulseMax: "",
  });
  const [fieldDisabled, setFieldDisabled] = useState(true);
  const [inputChange, setInputChange] = useState(false);
  const { userState } = useContext(ApplicationContext);
  const [userData, setUserData] = useState(null);
  let measurement = [];

  function updateDisplayName(selectedUser) {
    let userAttributes: any = selectedUser;
    if (userAttributes !== undefined) {
      let given_name = userAttributes.given_name;
      let family_name = userAttributes.family_name;
      setDisplayName(given_name + " " + family_name);
      setShowLoading(false);
    }
  }

  useEffect(() => {
    if (_.isEmpty(location.state.userId)) {
      return;
    }
    fetchSelectedUserMeasurement(location.state.userId).then((data) => {
      if (data) {
        setUserData(data);
        const threshold = data.thresholds;
        const thresholdLength = threshold.length === 1;
        setMeasurementValues(data.measurements);
        setThresholdValue(threshold)
        var result = convertArrayToObject(data.attributes, "name");
        result.enabled = data.enabled;
        setSelectedUser(result);
        updateDisplayName(result);
      }
    });
  }, []);

  const setThresholdValue = (threshold) => {
    const diastolicMin = threshold.find(v => {
      return v.measurementTypePart.longName === 'Diastolic' && v.thresholdType === 0
    })
    const diastolicMax = threshold.find(v => {
      return v.measurementTypePart.longName === 'Diastolic' && v.thresholdType === 1
    })
    const systolicMin = threshold.find(v => {
      return v.measurementTypePart.longName === 'Systolic' && v.thresholdType === 0
    })
    const systolicMax = threshold.find(v => {
      return v.measurementTypePart.longName === 'Systolic' && v.thresholdType === 1
    })
    const WeightMin = threshold.find(v => {
      return v.measurementTypePart.longName === 'Weight' && v.thresholdType === 0
    })
    const WeightMax = threshold.find(v => {
      return v.measurementTypePart.longName === 'Weight' && v.thresholdType === 1
    })
    const TempMin = threshold.find(v => {
      return v.measurementTypePart.longName === 'Temperature' && v.thresholdType === 0
    })
    const TempMax = threshold.find(v => {
      return v.measurementTypePart.longName === 'Temperature' && v.thresholdType === 1
    })
    const OxygenMin = threshold.find(v => {
      return v.measurementTypePart.longName === 'Oxygen Saturation' && v.thresholdType === 0
    })
    const SugarMin = threshold.find(v => {
      return v.measurementTypePart.longName === 'Sugar' && v.thresholdType === 0
    })
    const SugarMax = threshold.find(v => {
      return v.measurementTypePart.longName === 'Sugar' && v.thresholdType === 1
    })
    const PulseMin = threshold.find(v => {
      return v.measurementTypePart.longName === 'PulseRate' && v.thresholdType === 0
    })
    const PulseMax = threshold.find(v => {
      return v.measurementTypePart.longName === 'PulseRate' && v.thresholdType === 1
    })
    setDefaultValues({
      bloodPressureSystolicMin: systolicMin.thresholdValue,
      bloodPressureSystolicMax: systolicMax.thresholdValue,
      bloodPressureDiastolicMin: diastolicMin.thresholdValue,
      bloodPressureDiastolicMax: diastolicMax.thresholdValue,
      weightMin: WeightMin.thresholdValue,
      weightMax: WeightMax.thresholdValue,
      temperatureMin: TempMin.thresholdValue,
      temperatureMax: TempMax.thresholdValue,
      oxygenMin: '',
      oxygenMax: '',
      bloodGlucoseMin: SugarMin.thresholdValue,
      bloodGlucoseMax: SugarMax.thresholdValue,
      bloodSaturation: OxygenMin.thresholdValue,
      pulseMin: PulseMin.thresholdValue,
      pulseMax: PulseMax.thresholdValue,
    });
  }

  function editProfile(profile) {
    history.push("/updateprofile", { user: profile });
  }

  const handleDefaultChange =
    (prop: keyof DefaultState) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const isMatch = formValidation(event);
        if (!isMatch) {
          return event.preventDefault;
        }
        setInputChange(true);
        setDefaultValues({ ...defaultValues, [prop]: event.target.value });
      };
  const handleDefaultEditFields = () => {
    setFieldDisabled(false);
  };

  if (inputChange) {
    if (!_.isEmpty(location.state) || location.state != undefined) {
      const thresholdLength = userData.thresholds.length === 1;
      let tempMin = {};
      if (!thresholdLength) {
        measurement = userData.thresholds.map((value) => {
          const { measurementTypePart, thresholdAlerts } = value;
          const fullName =
            userState.authenticatedUser.attributes.given_name +
            " " +
            userState.authenticatedUser.attributes.family_name;
          const date = new Date();
          const updatedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
          switch (measurementTypePart.longName) {
            case "Systolic":
              if (value.thresholdType === 0) {
                const systolicMin = {
                  ...value,
                  thresholdValue: defaultValues.bloodPressureSystolicMin,
                  updatedBy: fullName,
                  updatedOn: updatedDate,
                };
                return systolicMin;
              } else {
                const systolicMAX = {
                  ...value,
                  thresholdValue: defaultValues.bloodPressureSystolicMax,
                  updatedBy: fullName,
                  updatedOn: updatedDate,
                };
                return systolicMAX;
              }
            case "Diastolic":
              if (value.thresholdType === 0) {
                const diastolicMin = {
                  ...value,
                  thresholdValue: defaultValues.bloodPressureDiastolicMin,
                  updatedBy: fullName,
                  updatedOn: updatedDate,
                };
                return diastolicMin;
              } else {
                const diastolicMax = {
                  ...value,
                  thresholdValue: defaultValues.bloodPressureDiastolicMax,
                  updatedBy: fullName,
                  updatedOn: updatedDate,
                };
                return diastolicMax;
              }

            case "Sugar":
              if (value.thresholdType === 0) {
                const sugarMin = {
                  ...value,
                  thresholdValue: defaultValues.bloodGlucoseMin,
                  updatedBy: fullName,
                  updatedOn: updatedDate,
                };
                return sugarMin;
              } else {
                const sugarMAX = {
                  ...value,
                  thresholdValue: defaultValues.bloodGlucoseMax,
                  updatedBy: fullName,
                  updatedOn: updatedDate,
                };
                return sugarMAX;
              }

            case "PulseRate":
              if (value.thresholdType === 0) {
                const pulseMin = {
                  ...value,
                  thresholdValue: defaultValues.pulseMin,
                  updatedBy: fullName,
                  updatedOn: updatedDate,
                };
                return pulseMin;
              } else {
                const pulseMAX = {
                  ...value,
                  thresholdValue: defaultValues.pulseMax,
                  updatedBy: fullName,
                  updatedOn: updatedDate,
                };
                return pulseMAX;
              }

            case "Temperature":
              const thresholdID = {
                ...thresholdAlerts[0],
                id: 0,
                providerThresholdId: 0,
                providerThresholdAlertId: 0,
              };
              if (value.thresholdType === 0) {
                const temperatureMin = {
                  ...value,
                  thresholdType: 0,
                  thresholdValue: defaultValues.temperatureMin,
                  updatedBy: fullName,
                  updatedOn: updatedDate,
                };
                return temperatureMin;
              } else {
                const temperatureMax = {
                  ...value,
                  thresholdType: 1,
                  thresholdValue: defaultValues.temperatureMax,
                  updatedBy: fullName,
                  updatedOn: updatedDate,
                };
                tempMin = {
                  ...value,
                  id: 0,
                  thresholdType: 0,
                  thresholdAlerts: [thresholdID],
                  thresholdValue: defaultValues.temperatureMin,
                  updatedBy: fullName,
                  updatedOn: updatedDate,
                };
                return temperatureMax;
              }

            case "Weight":
              if (value.thresholdType === 0) {
                const weightMin = {
                  ...value,
                  thresholdValue: defaultValues.weightMin,
                  updatedBy: fullName,
                  updatedOn: updatedDate,
                };
                return weightMin;
              } else {
                const weightMAX = {
                  ...value,
                  thresholdValue: defaultValues.weightMax,
                  updatedBy: fullName,
                  updatedOn: updatedDate,
                };
                return weightMAX;
              }

            case "Oxygen Saturation":
              if (value.thresholdType === 0) {
                const oxygen = {
                  ...value,
                  thresholdValue: defaultValues.bloodSaturation,
                  updatedBy: fullName,
                  updatedOn: updatedDate,
                };
                return oxygen;
              }
              break;
            default:
              break;
          }
        });

        if (measurement.length === 12) {
          measurement.push(tempMin);
        }
      }
    } else {
      console.log("usermeasurement is empty");
    }
  }

  return (
    <IonPage>
      <Header />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text="Go Back"
              icon={arrowBackCircleSharp}
              defaultHref="/"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {_.isEmpty(location.state) ? (
        <CircularProgress
          size={24}
          sx={{
            color: "#3880ff",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      ) : (
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol className="col-4">
                <IonCard className="fill-vertical-space">
                  <IonCardHeader>
                    <IonItem>
                      <h3>
                        {displayName} (
                        {selectedUser["enabled"] ? "Active" : "Deactivated"})
                      </h3>
                      <IonIcon
                        className="link"
                        icon={create}
                        color="primary"
                        slot="end"
                        onClick={(e: any) => editProfile(selectedUser)}
                      />
                    </IonItem>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow>
                      <IonCol sizeXs="12" sizeSm="12" sizeMd="12">
                        <IonImg
                          className="profile-img"
                          src={
                            selectedUser["picture"] !== "" &&
                              selectedUser["picture"] !== null
                              ? "data:image/jpeg;base64," +
                              selectedUser["picture"]
                              : "/assets/img/noimageavailable.png"
                          }
                        />
                      </IonCol>
                      <IonCol sizeXs="12" sizeSm="12" sizeLg="8" sizeMd="8">
                        <IonItem>
                          <IonLabel>{displayName}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel>
                            Birthdate: {selectedUser["birthdate"]}
                          </IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel>Email: {selectedUser["email"]}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel>Gender: {selectedUser["gender"]}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel>
                            Phone Number: {selectedUser["phone_number"]}
                          </IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel>
                            Provider Code: {selectedUser["custom:providercode"]}
                          </IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel>
                            Height:{" "}
                            {convertInches(selectedUser["custom:height"])}
                          </IonLabel>
                        </IonItem>
                        {selectedUser["custom:Roles"] !== undefined ? (
                          <IonItem>
                            <IonLabel>
                              Roles: {selectedUser["custom:Roles"]}
                            </IonLabel>
                          </IonItem>
                        ) : (
                          ""
                        )}
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol className="col-8">
                <IonCard>
                  <DefaultThreshold
                    defaultValues={defaultValues}
                    fieldDisabled={fieldDisabled}
                    handleDefaultChange={handleDefaultChange}
                    handleDefaultEditFields={handleDefaultEditFields}
                    setFieldDisabled={setFieldDisabled}
                    measurement={measurement}
                    userState={userState}
                    providerData={userData}
                    inputChange={inputChange}
                    global={false}
                  />
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="col-12">
                {userData && (
                  <LatestReadings type="User" selectedUser={userData} />
                )}
              </IonCol>
              <IonCol className="col-12">
                <IonRow className="ion-charts">
                  <IonCard className=" col-5" >
                    <IonCardHeader>
                      <IonItem>
                        <h3>Readings count for last 30 days</h3>
                      </IonItem>
                    </IonCardHeader>
                    <IonCardContent>
                      <TotalReadingsPieChart
                        userId={selectedUser["sub"]}
                        type="User"
                      />
                    </IonCardContent>
                  </IonCard>

                  <IonCard className=" col-5">
                    <IonCardHeader>
                      <IonItem>
                        <h3>Last 10 readings</h3>
                      </IonItem>
                    </IonCardHeader>
                    <IonCardContent>
                      <MeasurementChart
                        userId={selectedUser["sub"]}
                        type="User"
                      />
                    </IonCardContent>
                  </IonCard>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
          <ReadingsComponent selectedUser={selectedUser} />
        </IonContent>
      )}
    </IonPage>
  );
};

export default UserProfile;
