import { FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material"

export const FormFields = (props) => {
    return (
        <>
            <InputLabel sx={{ color: 'black' }} htmlFor="standard-adornment-amount">{props.label}</InputLabel>
            <FormControl sx={props.style} variant="outlined">
                <InputLabel sx={{ color: 'black' }} htmlFor="outlined-adornment-amount">{props.prompt}</InputLabel>
                <OutlinedInput
                    id={props.id}
                    value={props.value}
                    onChange={props.handleFormValueChange}
                    endAdornment={<InputAdornment position="end"> {props.adornment} </InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                        'aria-label': 'weight',
                    }}
                    disabled={props.disabled}
                />
            </FormControl>
        </>
    )
}