import { useContext } from "react";
import { Link } from "react-router-dom";
import { ApplicationContext } from "../../ApplicationState";

function HangfireDashboard() {
    const { userState } = useContext(ApplicationContext);
    console.log("🚀 ~ file: HangfireDashboard.tsx:7 ~ HangfireDashboard ~ userState:", process.env.NODE_ENV)
    var url = ""
    const token = userState.authenticatedUser.signInUserSession.idToken.jwtToken
    if (process.env.NODE_ENV === 'development') {
        url = `http://localhost:5000/hangfire?access_token=${token}`
    }

    if (process.env.NODE_ENV === 'test') {
        url = `https://health-api-tst.medline.com/hangfire?access_token=${token}`
    }

    if (process.env.NODE_ENV === 'production') {
        url = `https://health-api-tst.medline.com/hangfire?access_token=${token}`
    }

    return (
        <div style={{ display: 'flex', margin: 30, fontSize: "30px" }}>
            <Link to={{ pathname: url }} target="_blank">Show Hangfire Dashboard</Link>
        </div>
    )
}
export default HangfireDashboard;
