import { configureStore } from '@reduxjs/toolkit'
import Layoutreducer from './reducer/Layoutreducer'
import { layoutViewReducer } from './reducer/layoutViewReducer'
import { useDispatch } from 'react-redux';

export const store = configureStore({
    reducer: {
        layoutViewComponent: Layoutreducer,
    } //add reducers here 
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

