import { IonCard, IonCardContent, IonCardHeader, IonContent, IonLabel, IonPage, IonSegment, IonSegmentButton, useIonLoading } from "@ionic/react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ApplicationContext, ProviderContext } from "../../ApplicationState";
import * as ApiService from '../../services/ApiService';

var _ = require('lodash');

const ScheduleDetails: React.FC<any> = (props) => {
    const columns: GridColDef[] = [
        {
            field: "scheduleTitle",
            headerName: "Title",
            width: 200,
            editable: false,
        },
        {
            field: "scheduleDesc",
            headerName: "Description",
            width: 200,
            editable: false,
        },
        {
            field: "date",
            headerName: "Date",
            width: 200,
            editable: false,
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
            editable: false,
        },
        {
            field: "delete",
            headerName: "  ",
            width: 200,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div>
                        <Button variant="contained" onClick={() => handleDelete(cellValues)}>Cancel</Button>
                    </div>

                );
            }
        },
    ]
    const { userState } = useContext(ApplicationContext);
    const { providerState } = useContext(ProviderContext);
    const history = useHistory()

    const [pendingScheduleList, setPendingScheduleList] = useState([])
    const [completedScheduleList, setCompletedScheduleList] = useState([])
    const [readScheduleList, setReadScheduleList] = useState([])

    const [selectedDefaults, setSelectedDefaults] = useState('Pending')

    const [open, setOpen] = useState(false);
    const [deletedRow, setDeletedRow] = useState([]);
    const [present, dismiss] = useIonLoading();


    useEffect(() => {
        ApiService.getSchedules(userState.authenticatedUser, providerState.providerId, props)
            .then(data => {
                if (data) {
                    const complete = _.filter(data, c => c.status === "Completed")
                    const pending = _.filter(data, c => c.status === "Pending")
                    const read = _.filter(data, c => c.status === "Read")

                    const completeList = _.map(complete, d => {
                        const scheduleDate = format(new Date(d.scheduleDate), 'yyyy-MM-dd hh:mm a')

                        return {
                            id: d.id,
                            scheduleTitle: d.scheduleName,
                            scheduleDesc: d.scheduleDesc,
                            date: scheduleDate,
                            status: 'Unread',
                            hangfireJobId: d.hangfireJobId
                        }
                    })

                    const pendingList = _.map(pending, d => {
                        const scheduleDate = format(new Date(d.scheduleDate), 'yyyy-MM-dd hh:mm a')

                        return {
                            id: d.id,
                            scheduleTitle: d.scheduleName,
                            scheduleDesc: d.scheduleDesc,
                            date: scheduleDate,
                            status: d.status,
                            hangfireJobId: d.hangfireJobId
                        }

                    })

                    const readList = _.map(read, d => {
                        const scheduleDate = format(new Date(d.scheduleDate), 'yyyy-MM-dd hh:mm a')

                        return {
                            id: d.id,
                            scheduleTitle: d.scheduleName,
                            scheduleDesc: d.scheduleDesc,
                            date: scheduleDate,
                            status: d.status,
                            hangfireJobId: d.hangfireJobId
                        }
                    })

                    setPendingScheduleList(pendingList)
                    setCompletedScheduleList(completeList)
                    setReadScheduleList(readList)
                }
            })
    }, [])

    const handleClose = () => {
        setOpen(false);
    };

    const handleYes = () => {
        present({
            message: 'Deleting....',
        })
        ApiService.deleteSingleNotification(userState.authenticatedUser, deletedRow[0], deletedRow[0].hangfireJobId)
            .then(data => {
                if (data.status === 200) {
                    dismiss()
                    if (data.data.status === "Pending") {
                        setPendingScheduleList(
                            pendingScheduleList.filter((r) => deletedRow.filter((sr) => sr.id === r.id).length < 1)
                        );
                    }

                    if (data.data.status === "Completed") {
                        setCompletedScheduleList(
                            completedScheduleList.filter((r) => deletedRow.filter((sr) => sr.id === r.id).length < 1)
                        );
                    }
                    alert('Deleted Successfully.')
                }
            }).catch(error => {
                dismiss()
                console.log("ðŸš€ ~ file: Notifications.tsx:374 ~ handleYes ~ error:", error)
                alert('OOps something went wrong. Please try again.')
            })

        setOpen(false);
    };

    const handleDelete = (cellValue) => {
        setOpen(true)
        setDeletedRow([cellValue.row])
    }

    return (
        <>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <IonSegment value={selectedDefaults} mode="ios" style={{ height: 50, width: 600, margin: 10, }} onIonChange={e => {
                    setSelectedDefaults(e.detail.value);
                }}>
                    <IonSegmentButton value='Pending'>
                        <IonLabel>Pending</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value='Completed'>
                        <IonLabel>Sent and Unread</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value='Read'>
                        <IonLabel>Read</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
            </div>

            {selectedDefaults === 'Pending' && (
                <div style={{ width: "100%" }}>
                    <DataGrid
                        sx={{
                            boxShadow: 2,
                            border: 2,
                            borderColor: 'primary.light',
                            '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                            },
                        }}
                        rows={pendingScheduleList.length === 0 || pendingScheduleList === null ? [] : pendingScheduleList}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        //rowsPerPageOptions={[10]}
                        checkboxSelection={false}
                        autoHeight={true}
                        localeText={{
                            noRowsLabel: "No Pending Schedules to display"
                        }} />
                </div>
            )}

            {selectedDefaults === 'Completed' && (
                <div style={{ width: "100%" }}>
                    <DataGrid
                        sx={{
                            boxShadow: 2,
                            border: 2,
                            borderColor: 'primary.light',
                            '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                            },
                        }}
                        rows={completedScheduleList.length === 0 || completedScheduleList === null ? [] : completedScheduleList}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        checkboxSelection={false}
                        autoHeight={true}
                        localeText={{
                            noRowsLabel: "No Completed Schedules to display"
                        }} />
                </div>
            )}

            {selectedDefaults === 'Read' && (
                <div style={{ width: "100%" }}>
                    <DataGrid
                        sx={{
                            boxShadow: 2,
                            border: 2,
                            borderColor: 'primary.light',
                            '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                            },
                        }}
                        rows={readScheduleList.length === 0 || readScheduleList === null ? [] : readScheduleList}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        checkboxSelection={false}
                        autoHeight={true}
                        localeText={{
                            noRowsLabel: "No Read Schedules to display"
                        }} />
                </div>
            )}

            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Deleting a Scheduled Notification"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this scheduled notification?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>No</Button>
                        <Button onClick={handleYes} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div></>
    )
}
export default ScheduleDetails;

