import { useContext, useEffect } from "react"
import { ApplicationContext, ProviderContext } from "../../ApplicationState";
import * as ApiService from '../../services/ApiService'

export const useUserProfile = () => {
    const { userState } = useContext(ApplicationContext);
    const { providerState } = useContext(ProviderContext);

    const fetchSelectedUserMeasurement = async (userID: string) => {
        return await ApiService.fetchUserMeasurement(userState.authenticatedUser, userID).then((res) => {
            return res
        }).catch(error => {
            console.log("🚀 ~ file: useUserProfile.ts ~ line 13 ~ ApiService.fetchUserMeasurement ~ error", error)
            alert('Something went wrong. Please try again.')
            return error
        })
    }

    return {
        fetchSelectedUserMeasurement
    }
}
