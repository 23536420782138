export enum MeasurementTypes {
    BloodPressureSystolicMin = "bloodPressureSystolicMin",
    BloodPressureSystolicMax = 'bloodPressureSystolicMax',
    BloodPressureDiastolicMin = "bloodPressureDiastolicMin",
    BloodPressureDiastolicMax = "bloodPressureDiastolicMax",
    WeightMin = 'weightMin',
    WeightMax = "weightMax",
    TemperatureMin = "temperatureMin",
    TemperatureMax = "temperatureMax",
    OxygenMin = "oxygenMin",
    OxygenMax = "oxygenMax",
    BloodGlucoseMin = "bloodGlucoseMin",
    BloodGlucoseMax = "bloodGlucoseMax",
    BloodSaturation = "bloodSaturation",
    PulseMin = "pulseMin",
    PulseMax = "pulseMax"
}