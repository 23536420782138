import React, { createContext, useEffect, useState } from "react";

function useStickyState(key, defaultValue) {
  const [state, setState] = React.useState(
    () => JSON.parse(localStorage.getItem(key)) || defaultValue
  );
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}

export interface ApplicationState {
  authenticatedUser: object | undefined;
}

export interface ApplicationStateContextType {
  userState: ApplicationState | undefined;
  // type, you get when hovering over `setState` from `useState`
  setUserState: (UserState) => {};
}

const DefaultUserStateValue = {
  userState: undefined,
  setUserState: (userState) => { },
};

export const ApplicationContext = createContext(DefaultUserStateValue);

export const ApplicationContextProvider = ({ children }) => {
  const [userState, setUserState] = useState(); //useStickyState(DefaultUserStateValue.userState, 'user-state');

  return (
    <ApplicationContext.Provider value={{ userState, setUserState }}>
      {children}
    </ApplicationContext.Provider>
  );
};

export interface ProviderState {
  id: string | undefined;
  providerId: string | undefined;
  providerName: string | undefined;
  providerType: string | undefined;
}

export interface ProviderStateContextType {
  providerserState: ProviderState | undefined;
  // type, you get when hovering over `setState` from `useState`
  setProviderState: (ProviderState) => {};
}
const DefaultProviderStateValue = {
  providerState: undefined,
  setProviderState: (providerState) => { },
};
export const ProviderContext = createContext(DefaultProviderStateValue);
export const ProviderContextProvider = ({ children }) => {
  const [providerState, setProviderState] = useStickyState(
    DefaultProviderStateValue.providerState,
    "providerState"
  ); //useState();//
  //const [providerState, setProviderState] = useState();
  return (
    <ProviderContext.Provider value={{ providerState, setProviderState }}>
      {children}
    </ProviderContext.Provider>
  );
};
