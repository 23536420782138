import { Layout } from "react-grid-layout";

export enum LoadingState {
    IDLE = "IDLE",
    PENDING = "PENDING",
    SUCCESS = "DONE",
    FAILURE = "FAILURE"
}

export interface State {
    views: LayoutView[];
    components: Component[],
    loading?: {
        state?: LoadingState;
        error?: string,
    }
};

export interface LayoutView {
    id: string;
    name: string;
    componentLayout: ComponentLayout[];
}

export interface ComponentLayout extends Layout {
    component?: Component;
}

export interface Component {
    id: number;
    name: string;
    displayName: string;
}

export interface DataResponse {
    views: LayoutView[];
    components: Component[],
}

export interface IComponent {
    type: "SET_COMPONENT";
    payload: Component;
}

export interface ILayoutViews {
    type: "SET_LAYOUT";
    payload: LayoutView;
}

export type StateActions = IComponent | ILayoutViews;

