import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { FC } from "react";
import { Link } from "@mui/material";
import { useHistory } from "react-router";
var _ = require('lodash');

const columns: GridColDef[] = [
  {
    field: "given_name",
    headerName: "Name",
    width: 200,
    editable: false,
    renderCell: (cellValues) => {
      return (
        <Link component="button" variant="body2">
          {cellValues.value}
        </Link>
      );
    },
  },
  {
    field: "email",
    headerName: "Username",
    width: 200,
    editable: false,
  },
  {
    field: "phone_number",
    headerName: "Phone",
    width: 200,
    editable: false,
  },
];

export type UserDefaultThresholdProps = {
  data: any;
  handleSelection: (value: any) => void;
  setRowSelectionModel: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
  rowSelectionModel: GridRowSelectionModel;
};

export const UsersThresholdComponent: FC<UserDefaultThresholdProps> = ({
  data,
  handleSelection,
  setRowSelectionModel,
  rowSelectionModel
}) => {
  const [selected, setSelected] = React.useState(false);
  const [profileClick, setProfileClick] = React.useState(true)

  const history = useHistory();

  const handleCellSelection = (value) => {
    console.log("handleCellSelection value", value.row);

    setSelected(true);
  };

  const handleRoute = (e) => {
    setProfileClick(false)
    history.push("/userprofile", { userId: e.row.userid });
  };

  const emptyPatients = () => {
    if (!_.isEmpty(data)) {
      const isEmpty = _.isEmpty(data[0].id) && _.isEmpty(data[0].email) && _.isEmpty(data[0].given_name) && _.isEmpty(data[0].phone_number) && _.isEmpty(data[0].userid)
      return isEmpty
    }
  }

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          width: "100%",
        }}
        loading={emptyPatients()}
        rows={data}
        autoHeight={true}

        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        localeText={{
          noRowsLabel: "Nothing to display"
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        onRowClick={handleRoute}
        // onSelectionModelChange={(GridSelectionModelChangeParams) => {
        //   handleSelection(GridSelectionModelChangeParams)

        // }}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel)
          handleSelection(newRowSelectionModel)
        }}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick
      />
    </div>
  );
};
