import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonPage,
  IonRow,
  IonCol,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonGrid,
  IonSelect,
  IonSelectOption,
  IonText,
  IonHeader,
  IonBackButton,
  IonButtons,
  IonToolbar,
  NavContext,
} from "@ionic/react";
import { ApplicationContext, ProviderContext } from "../../ApplicationState";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./UpdateProfile.css";
import * as ApiService from "../../services/ApiService";
import * as Helper from "../../services/Helpers";
import { useForm, Controller } from "react-hook-form";
import { IUserProps } from "../../models/user";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import { useHistory, useLocation } from "react-router";
import { ErrorMessage } from "@hookform/error-message";
import { arrowBackCircleSharp } from "ionicons/icons";

let initialValues: IUserProps = {};
const Register: React.FC<any> = (props) => {
  const { goBack } = useContext(NavContext);
  const location: any = useLocation();
  const history: any = useHistory();
  const { userState, setUserState } = useContext(ApplicationContext);
  const [userAttributes, setUserAttributes] = React.useState<any | undefined>(
    {}
  );
  const [data, setData] = useState();
  const { providerState } = useContext(ProviderContext);

  const {
    register,
    control,
    handleSubmit,
    formState,
    reset,
    errors,
    setValue,
  } = useForm({
    defaultValues: { ...initialValues },
    mode: "onChange",
  });

  useEffect(() => {
    let user = userState.authenticatedUser;

    let userToUpdate = Helper.formatUserForUpdate(location.state.user);
    let attr = Helper.getUserAttributes(
      user.signInUserSession["idToken"].jwtToken
    );
    setUserAttributes(attr);
    setValue("username", userToUpdate.username, { shouldValidate: true });
    setValue("firstName", userToUpdate.firstName, { shouldValidate: true });
    setValue("lastName", userToUpdate.lastName, { shouldValidate: true });
    setValue("email", userToUpdate.email, { shouldValidate: true });
    setValue("gender", userToUpdate.gender);
    setValue("phone", userToUpdate.phoneNumber, { shouldValidate: true });
    setValue("roles", userToUpdate.roles);
  }, []);

  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div style={{ color: "red", fontWeight: "bold" }}>
        {error.message || "Field Is Required"}
      </div>
    ) : null;
  };

  const onSubmit = (data: any) => {
    if (providerState !== undefined) {
      if (providerState.providerId !== "") {
        let dataToSubmit = data;
        let user = userState.authenticatedUser;
        data.providerCode = providerState.providerId;
        //alert(JSON.stringify(data, null, 2));
        setData(data);
        ApiService.updateUserAttributes(user, data)
          .then((response) => {
            if (response.status === 200) {
              alert("User has been updated successfully.");
              goBack();
              //history.push("/updateprofile", { user: profile });
            } else {
              alert(response);
              goBack();
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    }
  };

  const CompareWithGroup = (o1: any, o2: any) => {
    return o1 && o2 ? o1 === o2 : o1 === o2;
  };

  function onDeactivateUser(a: any): void {
    let user = userState.authenticatedUser;
    let userToUpdate = Helper.formatUserForUpdate(location.state.user);
    const isConfirmed = window.confirm(
      "Are you sure you want to deactivate the user?"
    );
    if (isConfirmed) {
      ApiService.deactivateUser(user, userToUpdate)
        .then((response) => {
          alert("User deactivated successfully");
          //goBack();
          if (
            userToUpdate.roles === undefined ||
            userToUpdate.roles.length === 0
          )
            window.location.href = "/patients";
          else window.location.href = "/users";
        })
        .catch((err) => {
          alert(err);
        });
    }
  }

  function onActivateUser(a: any): void {
    let user = userState.authenticatedUser;
    let userToUpdate = Helper.formatUserForUpdate(location.state.user);
    ApiService.activateUser(user, userToUpdate)
      .then((response) => {
        //
        alert("User activated successfully");
        goBack();
      })
      .catch((err) => {
        alert(err);
      });
  }

  return (
    <IonPage>
      <Header />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text="Go Back"
              icon={arrowBackCircleSharp}
              defaultHref="/"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonItem>
                    <IonLabel>Update User</IonLabel>
                    {location.state?.user?.enabled ? (
                      <IonButton
                        slot="end"
                        onClick={(a) => onDeactivateUser(a)}
                      >
                        Deactivate
                      </IonButton>
                    ) : (
                      <IonButton slot="end" onClick={(a) => onActivateUser(a)}>
                        Activate
                      </IonButton>
                    )}
                  </IonItem>
                </IonCardHeader>
                <IonCardContent>
                  <IonRow>
                    <IonCol sizeXs="12" sizeSm="12" sizeLg="6" sizeMd="6">
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ padding: 10 }}
                        noValidate
                      >
                        <Controller
                          hidden
                          render={({ onChange, value, name }) => (
                            <IonInput
                              onIonChange={onChange}
                              value={value}
                              name={name}
                              hidden={true}
                            />
                          )}
                          control={control}
                          name="username"
                        />
                        <IonItem>
                          <IonLabel>First Name</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value, name }) => (
                              <IonInput
                                onIonChange={onChange}
                                onIonBlur={onBlur}
                                value={value}
                                name={name}
                                slot="end"
                              />
                            )}
                            control={control}
                            name="firstName"
                            onChange={(e) =>
                              setValue("firstName", e.target.value)
                            }
                            rules={{
                              required: true,
                            }}
                          />
                        </IonItem>
                        {showError("firstName")}
                        <IonItem>
                          <IonLabel>LastName</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value, name }) => (
                              <IonInput
                                onIonChange={onChange}
                                value={value}
                                name={name}
                                slot="end"
                              />
                            )}
                            control={control}
                            name="lastName"
                            onChange={(e) =>
                              setValue("lastName", e.target.value)
                            }
                            rules={{
                              required: true,
                            }}
                          />
                        </IonItem>
                        {showError("lastName")}
                        <IonItem>
                          <IonLabel>Email</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value, name }) => (
                              <IonInput
                                onIonChange={onChange}
                                value={value}
                                name={name}
                                slot="end"
                              />
                            )}
                            control={control}
                            name="email"
                            onChange={(e) => setValue("email", e.target.value)}
                            rules={{
                              required: true,
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address",
                              },
                            }}
                          />
                        </IonItem>
                        {showError("email")}
                        <IonItem>
                          <IonLabel>Sex</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value, name }) => (
                              <IonSelect
                                placeholder="Select One"
                                onIonChange={onChange}
                                value={value}
                                name={name}
                                slot="end"
                              >
                                <IonSelectOption value="Female">
                                  Female
                                </IonSelectOption>
                                <IonSelectOption value="Male">
                                  Male
                                </IonSelectOption>
                                <IonSelectOption value="">
                                  Not Provided
                                </IonSelectOption>
                              </IonSelect>
                            )}
                            compareWith={CompareWithGroup}
                            control={control}
                            name="gender"
                            onChange={(e) => setValue("gender", e.target.value)}
                            rules={{ required: false }}
                          />
                        </IonItem>
                        <IonItem>
                          <IonLabel>Phone</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value, name }) => (
                              <PhoneInput
                                international
                                placeholder="Enter phone number"
                                value={value}
                                defaultCountry="US"
                                onChange={onChange}
                                name={name}
                                slot="end"
                              />
                            )}
                            control={control}
                            name="phone"
                            onChange={(e) => setValue("phone", e.target.value)}
                            rules={{
                              required: true,
                            }}
                          />
                        </IonItem>
                        {showError("phone")}
                        <IonItem>
                          <IonLabel>Role</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value, name }) => (
                              <IonSelect
                                placeholder="Select One"
                                onIonChange={onChange}
                                value={value}
                                compareWith={CompareWithGroup}
                                name={name}
                                slot="end"
                              >
                                {userAttributes["roles"]?.includes(
                                  "Administrator"
                                ) ? (
                                  <IonSelectOption value="Administrator">
                                    Administrator
                                  </IonSelectOption>
                                ) : (
                                  ""
                                )}
                                <IonSelectOption value="Patient">
                                  Patient
                                </IonSelectOption>
                                <IonSelectOption value="Provider">
                                  Provider
                                </IonSelectOption>
                                {userAttributes["roles"]?.includes(
                                  "Administrator"
                                ) ||
                                userAttributes["roles"]?.includes("Support") ? (
                                  <IonSelectOption value="Support">
                                    Support
                                  </IonSelectOption>
                                ) : (
                                  ""
                                )}
                              </IonSelect>
                            )}
                            control={control}
                            name="roles"
                            compareWith={CompareWithGroup}
                            onChange={(e) => setValue("roles", e.target.value)}
                            rules={{ required: false }}
                          />
                        </IonItem>
                        <IonButton
                          type="submit"
                          disabled={formState.isValid === false}
                        >
                          submit
                        </IonButton>
                      </form>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Register;
