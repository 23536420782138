import React, {
  useReducer,
  createContext,
  Dispatch,
  useEffect,
  useContext,
} from "react";
import {
  DataResponse,
  IComponent,
  ILayoutViews,
  LayoutView,
  LoadingState,
  State,
  StateActions,
} from "../types";
import { componentReducer } from "./reducer/componentReducer";
import { layoutViewReducer } from "./reducer/layoutViewReducer";
import * as ApiService from "../services/ApiService";
import { ApplicationContext, ProviderContext, ProviderContextProvider } from "../ApplicationState";
import { SET_LAYOUT } from "./ActionType";

export const APP_STATE_NAME = "LAYOUTVIEW";

export const initialState: State = {
  views: [
    {
      id: "1",
      name: "Dashboard",
      componentLayout: [
        {
          w: 24,
          h: 35,
          x: 0,
          y: 0,
          minH: 35,
          minW: 24,
          i: "Breaches",
          component: {
            id: 1,
            name: "Breaches",
            displayName: "Threshold Breaches",
          },
        },
        {
          w: 24,
          h: 35,
          x: 0,
          y: 35,
          minH: 35,
          minW: 24,
          i: "BillingCycle",
          component: {
            id: 5,
            name: "BillingCycle",
            displayName: "Patient Cycle Activity",
          },
        },
        {
          w: 18,
          h: 50,
          x: 0,
          y: 180,
          minH: 50,
          minW: 18,
          i: "PatientList",
          component: {
            id: 2,
            name: "PatientList",
            displayName: "List of Patients",
          },
        },
        {
          w: 6,
          h: 100,
          x: 18,
          y: 180,
          minH: 100,
          minW: 6,
          i: "Charts",
          component: {
            id: 3,
            name: "Charts",
            displayName: "Charts",
          },
        },
        {
          w: 18,
          h: 60,
          x: 0,
          y: 120,
          minH: 60,
          minW: 18,
          i: "LatestTenReadings",
          component: {
            id: 4,
            name: "LatestTenReadings",
            displayName: "Latest 10 Readings",
          },
        },
        {
          w: 24,
          h: 80,
          x: 0,
          y: 70,
          minH: 35,
          minW: 24,
          i: "RewardProgram",
          component: {
            id: 6,
            name: "RewardProgram",
            displayName: "Rewards Program",
          },
        },

        {
          w: 24,
          h: 80,
          x: 0,
          y: 70,
          minH: 35,
          minW: 24,
          i: "ScheduleMessages",
          component: {
            id: 7,
            name: "ScheduleMessages",
            displayName: "Schedule Messages",
          },
        },

      ],
    },
  ],

  components: [
    {
      id: 1,
      name: "Breaches",
      displayName: "Threshold Breaches",
    },
    {
      id: 2,
      name: "PatientList",
      displayName: "Patient List",
    },
    {
      id: 3,
      name: "Charts",
      displayName: "Charts",
    },
    {
      id: 4,
      name: "LatestTenReadings",
      displayName: "Latest 10 Readings",
    },
    {
      id: 5,
      name: "BillingCycle",
      displayName: "Patient Activity Cycle",
    },
    {
      id: 6,
      name: "RewardProgram",
      displayName: "Rewards Program",
    },
    {
      id: 6,
      name: "ScheduleMessages",
      displayName: "Schedule Messages",
    },
  ],
  loading: {
    state: LoadingState.IDLE
  }
};

const LayoutContext = createContext<{
  state: State;
  dispatch: Dispatch<StateActions>;
}>({ state: initialState, dispatch: () => null });

const combinedReducers = (
  { views, components }: State,
  action: IComponent | ILayoutViews
) => ({
  views: layoutViewReducer(views[0], action),
  components: componentReducer(components, action),
});

const LayoutProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { userState } = useContext(ApplicationContext);
  const { providerState } = useContext(ProviderContext);

  let currentState = JSON.parse(
    localStorage.getItem(
      APP_STATE_NAME + "_" + userState.authenticatedUser.username
    )!
  )
    ? JSON.parse(
      localStorage.getItem(
        APP_STATE_NAME + "_" + userState.authenticatedUser.username
      )!
    )
    : initialState;


  const [state, dispatch] = useReducer(combinedReducers, currentState);


  // Watches for any changes in the state and keeps the state update in sync
  //Refresh state on any action dispatched
  // useEffect(() => {
  //   //Update the localstorage after detected change
  //   //localStorage.setItem(APP_STATE_NAME, JSON.stringify(state));
  //   //debugger;
  //   ApiService.fetchProviderDashboardItems(
  //     userState.authenticatedUser,
  //     providerState.providerId
  //   ).then((data) => {
  //     console.log("🚀 ~ file: layout-context.tsx:199 ~ ).then ~ data:----->", data)
  //     if (data && data.length > 0) {
  //       state.views = initialState.views.map((r) => {
  //         return {
  //           ...r,
  //           componentLayout: r.componentLayout.filter((cl) => {
  //             console.log("🚀 ~ file: layout-context.tsx:207 ~ componentLayout:r.componentLayout.filter ~ cl:", cl)
  //             const clayout = data.find((d) => d.id === cl.component.id)
  //             console.log("🚀 ~ file: layout-context.tsx:209 ~ componentLayout:r.componentLayout.filter ~ clayout:", clayout)
  //             return clayout;
  //           }),
  //         };
  //       });

  //       state.components = initialState.components.filter((r) =>
  //         data.find((a) => a.id === r.id)
  //       );
  //     }

  //     // dispatch({
  //     //   type: SET_LAYOUT,
  //     //   payload: { ...state.views[0], componentLayout: state.components },
  //     // });

  //     localStorage.setItem(
  //       APP_STATE_NAME + "_" + userState.authenticatedUser.username,
  //       JSON.stringify(state)
  //     );
  //     //window.location.reload();
  //   });
  // }, [state]);

  return (
    <LayoutContext.Provider value={{ state, dispatch }}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
export { LayoutContext, LayoutProvider };
