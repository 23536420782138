import React, { useContext, useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import { Pie } from "react-chartjs-2";
import classes from "./LineGraph.module.css";
import { ApplicationContext } from "../../ApplicationState";
import * as ApiService from "../../services/ApiService";
import { numberOfReadings } from "../../services/Helpers";
import Chart from "chart.js";
import * as Helpers from "../../services/Helpers";
import { options } from "ionicons/icons";
import "chart.piecelabel.js";
import saveAs from "save-as";

const UsersCountChart: React.FC<any> = (props: any) => {
  const { userState } = useContext(ApplicationContext);
  const [labels, setLabels] = useState([]);
  const [chartData, setData] = useState([]);
  const [pieColors, setPieColors] = useState([]);
  const [lineOptions, setLineOptions]: any = useState({
    tooltips: {
      enabled: true,
    },
    legend: {
      display: true,
      position: "right",
    },
    pieceLabel: {
      render: "value",
      fontSize: 12,
      fontColor: "#fff",
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      textShadow: true,
      shadowBlur: 10,
      shadowOffsetX: -5,
      shadowOffsetY: 5,
      shadowColor: "rgba(255,0,0,0.75)",
      arc: true,
    },
  });

  let displayLabels: boolean = false;
  const data = {
    labels,
    datasets: [
      {
        data: chartData,
        backgroundColor: pieColors,
      },
    ],
  };

  useEffect((): any => {
    let isSubscribed = false;
    if (userState.authenticatedUser !== undefined) {
      ApiService.getUserCount(userState.authenticatedUser)
        .then((data) => {
          if (!isSubscribed) {
            if (data && data.length > 0) {
              var lbls = [];
              var lblData = [];
              data.map((r) => {
                lbls.push(r.providerName);
                lblData.push(r.count);
              });
              setLabels(lbls);
              setData(lblData);
              let colors: any = [];
              lblData.map((r) => {
                colors.push(getRandomColor());
              });
              setPieColors(colors);
              setLineOptions({
                tooltips: {
                  enabled: true,
                },
                legend: {
                  display: lblData.length < 2,
                  position: "right",
                },
              });
            }
          }
        })
        .catch((err) => {
          alert(err);
        });

      return () => (isSubscribed = true);
    }
  }, []);

  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  return <Pie data={data} options={lineOptions} />;
};

export default UsersCountChart;
