import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { APP_STATE_NAME, initialState } from '../layout-context'
import * as ApiService from "../../services/ApiService";
import { useContext } from 'react';
import { ApplicationContext, ProviderContext } from '../../ApplicationState';
import { LayoutView, LoadingState } from '../../types';

export const fetchDashboardItems = createAsyncThunk(
    'dashboardItems/fetch',
    (data: any) => {
        // const { userState } = useContext(ApplicationContext);
        // const { providerState } = useContext(ProviderContext);

        const items = ApiService.fetchProviderDashboardItems(data.userState.authenticatedUser, data.providerState.providerId)
        return items
        // localStorage.setItem(
        //     APP_STATE_NAME + "_" + userState.authenticatedUser.username,
        //     JSON.stringify(state)
        // );
    }
);

export const LayoutSlice = createSlice({
    name: 'dashboardItems',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        updateLayout: (state, action) => {
            state.views[0].componentLayout = action.payload.componentLayout
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardItems.pending, (state) => {
                state.loading.state = LoadingState.PENDING
            })
            .addCase(fetchDashboardItems.fulfilled, (state, action) => {
                state.loading.state = LoadingState.SUCCESS
                state.views = initialState.views.map((r) => {
                    return {
                        ...r,
                        componentLayout: r.componentLayout.filter((cl) => {
                            const clayout = action.payload.find((d) => d.id === cl.component.id)
                            return clayout;
                        }),
                    };
                });

                state.components = initialState.components.filter((r) =>
                    action.payload.find((a) => a.id === r.id)
                );
                localStorage.setItem(
                    APP_STATE_NAME + "_" + action.meta.arg.userState.authenticatedUser.username,
                    JSON.stringify(state)
                );
            })
            .addCase(fetchDashboardItems.rejected, (state) => {
                state.loading.state = LoadingState.FAILURE
            })
    }
})

export const { updateLayout } = LayoutSlice.actions


export default LayoutSlice.reducer


