import { attachProps } from "@ionic/react/dist/types/components/utils";
import { find as _find } from "lodash";
import React, { useCallback, useContext, useRef, useState } from "react";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { SET_LAYOUT } from "../../context/ActionType";
import { LayoutContext } from "../../context/layout-context";
import { Component, ComponentLayout } from "../../types";
import asyncComponent from "./AsyncComponent";
import DraggableComponent from "./DraggableComponent";
import LayoutItem from "./LayoutItem";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../context/store";
import { updateLayout } from "../../context/reducer/Layoutreducer";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

//dashboard item dimension when drag and drops
const droppingItem = {
  i: "__dropping-elem__",
  w: 20,
  h: 36,
};
const droppingCharts = {
  i: "__dropping-Charts__",
  w: 5,
  h: 50,
};

const ConfigurationPage = () => {
  //const dispatch = useAppDispatch();
  const history = useHistory();

  let { id } = useParams<{ id: string }>();

  //const { state, dispatch } = useContext(LayoutContext);
  const state = useSelector((state: RootState) => state.layoutViewComponent);
  // console.log("🚀 ~ file: Home.tsx:55 ~ layoutState:----->", layoutState)
  const dispatch: AppDispatch = useDispatch()

  // console.log(
  //   "🚀 ~ file: ConfigurationPage.tsx ~ line 28 ~ ConfigurationPage ~ state",
  //   state.components
  // );

  //const view = useAppSelector(selectors.getViewById(id));
  const draggableComponents = state.components;
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [layout, setlayout] = useState<ComponentLayout[]>(
    state.views ? state.views[0].componentLayout : []
  );
  const [component, setComponent] = useState<Component>();

  const mapLayout = useCallback(
    (currentLayout: ComponentLayout[], newLayout: Layout[]) => {
      return currentLayout.map((componentLayout) => {
        const found = _find(newLayout, ["i", componentLayout.i]);
        return { ...componentLayout, ...found };
      });
    },
    []
  );

  const handleDragStart = useCallback((dragComponent: Component) => {
    console.log(
      "🚀 ~ file: ConfigurationPage.tsx ~ line 49 ~ handleDragStart ~ dragComponent",
      dragComponent
    );
    setComponent(dragComponent);
  }, []);

  const onDrop = useCallback(
    (layouts: Layout[], item: Layout, e: any) => {
      item.w = item.w + (20 / 100) * item.w; //readjust the width when droping the item
      setlayout((prev) =>
        mapLayout(prev, layouts).concat({
          ...item,
          i: new Date().getTime().toString(),
          component,
          isDraggable: undefined,
        })
      );
    },
    [component, mapLayout]
  );

  const onRemoveItem = useCallback((i: string) => {
    setlayout((prev) => prev.filter((l) => l.i !== i));
  }, []);

  const compactType = null;
  const onLayoutChange = useCallback(
    (newLayout: Layout[]) => {
      setlayout((prev) => mapLayout(prev, newLayout));
    },
    [mapLayout]
  );

  const handleSave = useCallback(() => {
    if (state.views[0]) {

      dispatch(updateLayout({ ...state.views[0], componentLayout: layout }))
      // dispatch({
      //   type: SET_LAYOUT,
      //   payload: { ...state.views[0], componentLayout: layout },
      // });
      //dispatch(setLayoutView({ ...state.views[0], componentLayout: layout }));
    }
    history.push("/");
  }, [dispatch, history, state.views, layout]);

  if (!state.views) {
    return <Redirect push to="/" />;
  }

  return (
    <div className="row justify-content-between">
      <div className="col-2 p-0">
        <h4 className="mb-4 pb-3 border-bottom">Components</h4>
        <div className="layout">
          {draggableComponents.map((component) => (
            <DraggableComponent
              key={component.id}
              component={component}
              handleDragStart={handleDragStart}
            />
          ))}
        </div>
      </div>
      <div className="col-12 col-sm-10">
        <div className="row mb-4 pb-2 border-bottom justify-content-between">
          <h4 className="col p-0">
            Configuration Screen - {state.views[0].name}
          </h4>
          <div className="col-2 text-end p-0">
            <button
              className="btn btn-primary float-right"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
        <div className="card-body overflow-auto">
          <ResponsiveReactGridLayout
            className="react-grid-layout layout border"
            cols={{ lg: 24 }}
            layouts={{ lg: layout }}
            breakpoints={{ lg: 0 }}
            rowHeight={5}
            width={1920}
            compactType={"vertical"}
            isDroppable
            onDrop={onDrop}
            onLayoutChange={onLayoutChange}
            isBounded={true}
            preventCollision={false}
            droppingItem={
              component?.name === "Charts" ? droppingCharts : droppingItem
            }
            useCSSTransforms={false}
            style={{ minHeight: windowSize.current[1] }}
          >
            {layout.map((componentLayout) => (
              <div key={componentLayout.i} className="card bg-light p-4">
                <span
                  role="button"
                  className="remove position-absolute end-0 p-2 top-0"
                  onClick={() => onRemoveItem(componentLayout.i)}
                >
                  x
                </span>
                <LayoutItem
                  componentLayout={componentLayout}
                  render={setlayout}
                />
              </div>
            ))}
          </ResponsiveReactGridLayout>
        </div>
      </div>
    </div>
  );
};

export default ConfigurationPage;
