import React, { useContext, useEffect, useState } from "react";
import {
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonLabel,
  IonInput,
  IonToast,
  IonText,
  IonPage,
  IonContent,
  IonCol,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonCardHeader,
  IonIcon,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonSearchbar,
  IonBackButton,
  IonListHeader,
  IonDatetime,
  IonLoading,
} from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import * as Helpers from "../../services/Helpers";
import * as ApiService from "../../services/ApiService";
import "./Readings.css";
import {
  ApplicationContext,
  ProviderContextProvider,
} from "../../ApplicationState";
import ReadingsComponent from "../../components/ReadingsComponent";
import Header from "../../components/Header/Header";

import MeasurementChart from "../../components/Charts/MeasurementChart";
import TotalReadingsChart from "../../components/Charts/TotalReadingsChart";
import { convertInches, formatReading } from "../../services/Helpers";
import LatestReadings from "./LatestReadings";
import moment from "moment";
import {
  arrowBackCircleSharp,
  arrowDown,
  arrowUp,
  create,
  expandSharp,
  location,
  swapVerticalSharp,
} from "ionicons/icons";
import saveAs from "save-as";
import { CloudDownloadSharp } from "react-ionicons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
var _ = require("lodash");

const Readings: React.FC = (props: any) => {
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(true);
  const location: any = useLocation();
  const [searchText, setSearchText] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [readings, setReadings] = React.useState<any[]>([]);
  const [filteredReadings, setFilteredReadings] = React.useState<any[]>([]);
  const { userState } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [disableLoadMoreButton, setDisableButton] = React.useState(false);
  const [disableInfiniteScroll, setDisableInfiniteScroll] =
    useState<boolean>(false);
  const [sortKey, setSortKey] = React.useState("readingOn");
  const [sortDirection, setSortDirection] = React.useState(0);
  const [note, setNote] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [NoteText, setNoteText] = useState("");
  const dateFormatString = "YYYY-MM-DD";
  let startDate = moment(new Date());
  let endDate = moment(new Date());
  const [selectedStartDate, setSelectedStartDate] = React.useState<string>(
    startDate?.add(-6, "months").format(dateFormatString)
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState<string>(
    endDate?.format(dateFormatString)
  );
  const [selectedMeasurementTypes, setSelectedMeasurementTypes] =
    React.useState([]);

  function updateDisplayName(selectedUser) {
    let userAttributes: any = selectedUser;
    if (userAttributes !== undefined) {
      let given_name = userAttributes.given_name;
      let family_name = userAttributes.family_name;
      setDisplayName(given_name + " " + family_name);
    }
  }
  let isSubscribed = true;
  useEffect((): any => {
    setSelectedUser(location.state.selectedUser);
    updateDisplayName(location.state.selectedUser);
    let pageNum = 1;
    setShowLoading(true);
    if (location.state.selectedUser.sub !== undefined) {
      ApiService.fetchUserReadings(
        userState.authenticatedUser,
        location.state.selectedUser.sub,
        pageSize,
        pageNum,
        moment(selectedStartDate).format(dateFormatString),
        moment(selectedEndDate).format(dateFormatString),
        sortKey,
        sortDirection,
        searchText,
        selectedMeasurementTypes.join(",")
      )
        .then((data) => {
          setShowLoading(false);
          if (data && data.length > 0) {
            if (isSubscribed) {
              setPageNumber(pageNum + 1);
              setReadings(data);
              if (searchText === "") {
                setFilteredReadings(data);
              } else {
                let filtered = readings.filter((r) => {
                  return r.measurementType
                    .toLowerCase()
                    .includes(searchText.toLowerCase());
                });
                setFilteredReadings(filtered);
              }

              setDisableInfiniteScroll(data.length < pageSize);
            } else {
              setDisableInfiniteScroll(true);
            }
          } else {
            setReadings(data);
            setFilteredReadings(data);
            setDisableButton(true);
            setDisableInfiniteScroll(true);
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
    return () => (isSubscribed = false);
  }, [
    searchText,
    sortKey,
    sortDirection,
    selectedStartDate,
    selectedEndDate,
    selectedMeasurementTypes,
  ]);

  async function fetchReadings($event: CustomEvent<void>) {
    let user = userState.authenticatedUser;
    if (location.state.selectedUser.sub !== undefined) {
      await ApiService.fetchUserReadings(
        userState.authenticatedUser,
        location.state.selectedUser.sub,
        pageSize,
        pageNumber,
        moment(selectedStartDate).format(dateFormatString),
        moment(selectedEndDate).format(dateFormatString),
        sortKey,
        sortDirection,
        searchText,
        selectedMeasurementTypes.join(",")
      ).then((data) => {
        if (data && data.length > 0) {
          setPageNumber(pageNumber + 1);
          setReadings([...readings, ...data]);
          setFilteredReadings([...readings, ...data]);
          // if (searchText === "") {
          //   setFilteredReadings([...readings, ...data]);
          // } else {
          //   let filtered = data.filter((r) => {
          //     return r.measurementType
          //       .toLowerCase()
          //       .includes(searchText.toLowerCase());
          //   });
          //   setFilteredReadings([...readings, ...filtered]);
          // }
          setDisableInfiniteScroll(data.length < pageSize);
        } else {
          //setDisableButton(false);
          setDisableInfiniteScroll(true);
        }
      });
      ($event.target as HTMLIonInfiniteScrollElement).complete();
    }
  }

  //sort

  const sortBy = (key) => {
    if (key == sortKey) {
      setSortDirection((sortDirection % 2) + 1);
      setSortKey(key);

      //sort(key, sortDirection + 1);
    } else {
      setSortDirection(1);
      setSortKey(key);

      //sort(key, 1);
    }
  };
  /*
  function sort(key, direction) {
    if (direction == 1) {
      var sfilteredReadings = filteredReadings.sort((a, b) => {
        const valA = a[key];
        const valB = b[key];
        return valA.localeCompare(valB);
      });
      setFilteredReadings(sfilteredReadings);
    } else if (direction == 2) {
      var sfilteredReadings = filteredReadings.sort((a, b) => {
        const valA = a[key];
        const valB = b[key];
        return valB.localeCompare(valA);
      });
      setFilteredReadings(sfilteredReadings);
    } else {
      setSortDirection(0);
      setSortKey("");
      // if (searchText === "") {
      //   setFilteredReadings(readings);
      // } else {
      //   let filtered = readings.filter((r) => {
      //     return r.measurementType
      //       .toLowerCase()
      //       .includes(searchText.toLowerCase());
      //   });
      //   setFilteredReadings(filtered);
      // }
      setFilteredReadings(readings);
    }
  }*/
  //end sort

  const onSearchTextChange = (searchTerm) => {
    setPageNumber(1);
    setSortDirection(1);
    setSortKey("");
    setSearchText(searchTerm);
    // if (searchTerm === "") {
    //   setFilteredReadings(readings);
    //   return;
    // }
    // //
    //
    // let filtered = readings.filter((r) => {
    //   return r.measurementType.toLowerCase().includes(searchTerm.toLowerCase());
    // });
    //
    // setFilteredReadings(filtered);
  };

  const downloadUserPdf = () => {
    ApiService.downloadUserPdf(
      userState.authenticatedUser,
      location.state.selectedUser.sub,
      moment(selectedStartDate).format(dateFormatString),
      moment(selectedEndDate).format(dateFormatString),
      sortKey,
      sortDirection,
      searchText,
      selectedMeasurementTypes.join(",")
    ).then((data) => {
      const downloadedFile = Helpers.base64ToBlob(
        data.fileContents,
        data.contentType
      );
      saveAs(downloadedFile);
    });
  };
  const downloadXls = () => {
    ApiService.downloadUserXls(
      userState.authenticatedUser,
      location.state.selectedUser.sub,
      moment(selectedStartDate).format(dateFormatString),
      moment(selectedEndDate).format(dateFormatString),
      sortKey,
      sortDirection,
      searchText,
      selectedMeasurementTypes.join(",")
    ).then((data) => {
      const downloadedFile = Helpers.base64ToBlob(
        data.fileContents,
        data.contentType
      );
      saveAs(downloadedFile);
    });
  };

  const onMeasurementSelectionChange = (measurementTypes) => {
    setSelectedMeasurementTypes(measurementTypes);
  };

  const handleClickOpen = (id, breachValues) => {
    let rowid = 0;
    if (breachValues != null) {
      const breach = breachValues.map((d) => {
        return d.reviewStatuses;
      });
      const breachNote = breach.map((v) => {
        if (!_.isEmpty(v)) {
          const note = v[0].note;
          const fullName = v[0].reviewedBy;
          const dateFromString = new Date(v[0].reviewedOn);
          const formatDate = format(dateFromString, "yyyy-MM-dd");
          const formatTime = format(dateFromString, "hh:mm a");
          const fullDate = formatDate + " " + formatTime;
          rowid = rowid + 1;
          setNoteText("");
          return { note, id: rowid, fullName, fullDate };
        } else {
          setNoteText("");
          return {
            note: "",
            id: "",
            fullName: "",
            fullDate: "",
          };
        }
      });
      setNote(_.reverse(breachNote));
    } else {
      setNoteText("");
      setNote([
        {
          note: "",
          id: "",
          fullName: "",
          fullDate: "",
        },
      ]);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns: GridColDef[] = [
    { field: "note", headerName: "Note", width: 150 },
    { field: "fullName", headerName: "Review BY", width: 200 },
    { field: "fullDate", headerName: "Review Date", width: 200 },
  ];

  return (
    <IonPage>
      <Header />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text="Go Back"
              icon={arrowBackCircleSharp}
              defaultHref="/"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonTitle>
          <h3>Displaying readings for {displayName}</h3>
        </IonTitle>
        <IonItem>
          <IonRow>
            <IonCol>
              <IonImg
                className="profile-img"
                src={
                  selectedUser["picture"] !== "" &&
                  selectedUser["picture"] !== null
                    ? "data:image/jpeg;base64," + selectedUser["picture"]
                    : "/assets/img/noimageavailable.png"
                }
              />
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel>{displayName}</IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel>Birthdate: {selectedUser["birthdate"]}</IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel>Email: {selectedUser["email"]}</IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel>Gender: {selectedUser["gender"]}</IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel>
                  Phone Number: {selectedUser["phone_number"]}
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel>
                  Provider Code: {selectedUser["custom:providercode"]}
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel>
                  Height: {convertInches(selectedUser["custom:height"])}
                </IonLabel>
              </IonItem>
              {selectedUser["custom:Roles"] !== undefined ? (
                <IonItem>
                  <IonLabel>Roles: {selectedUser["custom:Roles"]}</IonLabel>
                </IonItem>
              ) : (
                ""
              )}
            </IonCol>
          </IonRow>
        </IonItem>
        <IonCard>
          <IonGrid>
            <IonRow>
              <IonItem className="col">
                <IonButton onClick={() => downloadUserPdf()} slot="end">
                  PDF&nbsp;&nbsp;&nbsp;
                  <CloudDownloadSharp
                    color={"#00000"}
                    title="Download pdf"
                    height="15px"
                    width="15px"
                  />
                </IonButton>
                <IonButton onClick={() => downloadXls()} slot="end">
                  Xls&nbsp;&nbsp;&nbsp;
                  <CloudDownloadSharp
                    color={"#00000"}
                    title="Download pdf"
                    height="15px"
                    width="15px"
                  />
                </IonButton>
              </IonItem>
            </IonRow>
            <IonRow>
              {/* <IonItem className="col">
                <IonSearchbar
                  placeholder="Search Measurement Type"
                  value={searchText}
                  onIonChange={(e) => onSearchTextChange(e.detail.value!)}
                  showCancelButton="always"
                ></IonSearchbar>
              </IonItem> */}
              <IonItem className="col-4">
                <IonSelect
                  multiple
                  placeholder="Select Measurement Type"
                  onIonChange={(e) =>
                    onMeasurementSelectionChange(e.detail.value)
                  }
                >
                  {Helpers.measurementTypes().map((a, index) => {
                    return (
                      <IonSelectOption value={a} key={index}>
                        <IonItem>{a}</IonItem>
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
              <IonItem className="col-4">
                <IonLabel slot="start"> Start Date:</IonLabel>
                <IonDatetime
                  slot="start"
                  pickerFormat={dateFormatString}
                  displayFormat={dateFormatString}
                  value={selectedStartDate}
                  onIonChange={(e) => setSelectedStartDate(e.detail.value!)}
                ></IonDatetime>
              </IonItem>
              <IonItem className="col-4">
                <IonLabel slot="start"> End Date:</IonLabel>
                <IonDatetime
                  slot="start"
                  pickerFormat={dateFormatString}
                  displayFormat={dateFormatString}
                  placeholder="Select Date"
                  value={selectedEndDate}
                  onIonChange={(e) => setSelectedEndDate(e.detail.value!)}
                ></IonDatetime>
              </IonItem>
            </IonRow>
          </IonGrid>
        </IonCard>

        <IonList>
          <IonListHeader color="primary">
            <IonLabel
              onClick={() => sortBy("measurementType")}
              className="col-2 link"
            >
              <u>Measurement Type</u> &nbsp;
              {sortKey == "measurementType" ? (
                sortDirection % 2 == 0 ? (
                  <IonIcon icon={arrowUp} />
                ) : (
                  <IonIcon icon={arrowDown} />
                )
              ) : (
                ""
              )}
            </IonLabel>
            <IonLabel className="col-2">Value</IonLabel>
            <IonLabel
              onClick={() => sortBy("readingOn")}
              className="col-2 link"
            >
              <u>Date</u> &nbsp;
              {sortKey == "readingOn" ? (
                sortDirection % 2 == 0 ? (
                  <IonIcon icon={arrowUp} />
                ) : (
                  <IonIcon icon={arrowDown} />
                )
              ) : (
                ""
              )}
            </IonLabel>
            <IonLabel
              onClick={() => sortBy("isManualEntry")}
              className="col-2 link d-none d-lg-block"
            >
              <u>Manual</u>&nbsp;
              {sortKey == "isManualEntry" ? (
                sortDirection % 2 == 0 ? (
                  <IonIcon icon={arrowUp} />
                ) : (
                  <IonIcon icon={arrowDown} />
                )
              ) : (
                ""
              )}
            </IonLabel>
            <IonLabel className="col-2 d-none d-lg-block">Device</IonLabel>
            <IonLabel className="col-2 d-none d-lg-block">Notes</IonLabel>
          </IonListHeader>
          {filteredReadings.map((a, index) => {
            const breachValues = a.breachValues;
            let isBreach = a.isBreach;
            let isAcknowledge = a.isAcknowledged;
            let measurementType: any[] = a.measurementType;
            let readingOn: any[] = a.readingOn;
            let reading = a.reading;
            let readingId = a.id;
            let utcTime = moment.utc(readingOn);
            let localTime = utcTime.local().format("MMM DD YYYY hh:mm A");
            return (
              <IonItem key={readingId} button onClick={() => {}} lines="full">
                <IonLabel
                  className="col-2"
                  style={{ color: isBreach ? "red" : "black" }}
                >
                  {Helpers.formatMeasurementType(a)}
                </IonLabel>
                <IonLabel
                  className="col-2"
                  style={{ color: isBreach ? "red" : "black" }}
                >
                  <i>{formatReading(a)}</i>
                </IonLabel>
                <IonLabel
                  className="col-2"
                  style={{ color: isBreach ? "red" : "black" }}
                >
                  <i>{localTime}</i>
                </IonLabel>
                <IonLabel
                  className="col-2 d-none d-lg-block"
                  style={{ color: isBreach ? "red" : "black" }}
                >
                  <i>{a.isManualEntry ? "True" : "False"}</i>
                </IonLabel>
                <IonLabel
                  className="col-2 d-none d-lg-block"
                  style={{ color: isBreach ? "red" : "black" }}
                >
                  <i>{a.deviceModel}</i>
                </IonLabel>
                {/* <IonButton href={readingId} color="primary" slot="end">View</IonButton> */}
                <Button
                  variant="outlined"
                  color="primary"
                  hidden={!isAcknowledge}
                  style={{ marginLeft: 25 }}
                  onClick={() => handleClickOpen(readingId, breachValues)}
                >
                  Notes
                </Button>
              </IonItem>
            );
          })}
        </IonList>
        <IonInfiniteScroll
          threshold="140px"
          disabled={disableInfiniteScroll}
          onIonInfinite={(e: CustomEvent<void>) => fetchReadings(e)}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>

        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
      </IonContent>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent style={{ height: 500, width: 500 }}>
          {_.map(note, (v) => {
            return (
              <List
                sx={{
                  width: "100%",
                  maxWidth: 500,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "18px" }}
                    secondaryTypographyProps={{ fontStyle: "italic" }}
                    primary={v.note}
                    secondary={
                      v.fullName != ""
                        ? "by:" +
                          " " +
                          v.fullName +
                          " " +
                          "on:" +
                          " " +
                          v.fullDate
                        : ""
                    }
                  />
                </ListItem>
              </List>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </IonPage>
  );
};

export default Readings;
function rediect() {
  throw new Error("Function not implemented.");
}
