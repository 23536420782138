import "./Header.css";
import React, { lazy, Suspense, useContext, useEffect, useRef, useState } from "react";
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonLabel,
  IonText,
  IonCol,
  IonImg,
  IonIcon,
  IonMenuButton,
  IonRouterLink,
} from "@ionic/react";
import { Auth } from "aws-amplify";
import { ApplicationContext, ProviderContext } from "../../ApplicationState";
import jwt_decode from "jwt-decode";
//import GroupsComponent from "../GroupsComponent";
import { getUserAttributes } from "../../services/Helpers";
import { exit, refresh } from "ionicons/icons";
import { useHistory } from "react-router";
import { Box, Button, Modal, Typography } from "@mui/material";
const LazyLoadGroupsComponent = lazy(() => import("../GroupsComponent"));

const getDecodedAccessToken = (token: string) => {
  try {
    return jwt_decode(token);
  } catch (Error) {
    return null;
  }
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Header: React.FC = () => {
  const history: any = useHistory();
  const timeoutRef = useRef<NodeJS.Timeout>();

  const { providerState } = useContext(ProviderContext);
  const { userState, setUserState } = useContext(ApplicationContext);
  const [userAttributes, setUserAttributes] = React.useState<any | undefined>(
    {}
  );
  const [userFullName, setUserName] = useState("");
  const [timeoutModal, setTimeoutModal] = useState(false)

  const item = { src: "assets/img/icon.png" };

  const logout = () => {
    setUserState({ authenticatedUser: undefined });
    Auth.signOut();
    history.go(0);
  };

  useEffect(() => {
    const timecheck = 5 * 60 * 1000
    const inactivityTimer = setInterval(refreshTokenIfNecessary, timecheck); // Check every 5 minute
    let user = userState.authenticatedUser;

    setUserName(
      user.attributes["given_name"] + " " + user.attributes["family_name"]
    );
    let attr = getUserAttributes(user.signInUserSession["idToken"].jwtToken);
    setUserAttributes(attr);

    return () => {
      clearTimeout(inactivityTimer);
    };
  }, []);

  const handleClose = () => setTimeoutModal(false);

  const closeAfter30Sec = () => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setTimeoutModal(false);
      Auth.signOut();
      history.go(0);
    }, 120000);

  }

  async function refreshTokenIfNecessary() {
    try {
      const session = await Auth.currentSession();
      const currentTime = Math.floor(new Date().getTime() / 1000);
      const tokenExpirationTime = session.getIdToken().getExpiration();
      const refreshTokenTime = session.getAccessToken().getExpiration()

      // Define a threshold time before expiration to refresh the token
      const refreshThreshold = 300; // 5 minutes before expiration

      // Check if access token is about to expire and renew access token
      //in cognito medlinetest access token expires in 10 min.
      if (tokenExpirationTime - currentTime < refreshThreshold) {
        ///Auth.signOut();
        //history.go(0);
        setTimeoutModal(true)
        closeAfter30Sec()

        //window.location.reload();
        // Refresh the token
      }

      // Check if refresh token is about to expire then signout
      //in cognito medlinetest refresh token expires in 60 min
      if (refreshTokenTime - currentTime < refreshThreshold) {
        // Auth.signOut();
        // history.go(0);

        // Refresh the token using the refresh token
        //await Auth.currentSession();
      }

    } catch (error) {
      console.error('Error refreshing token:', error);
      setTimeoutModal(true)
      closeAfter30Sec()
    }
  }

  function toggleMenu() {
    const splitPane = document.querySelector("ion-split-pane");
    const windowWidth = window.innerWidth;
    const splitPaneShownAt = 992;
    const when = `(min-width: ${splitPaneShownAt}px)`;
    if (windowWidth >= splitPaneShownAt) {
      // split pane view is visible
      const open = splitPane.when === when;
      splitPane.when = open ? false : when;
    } else {
      // split pane view is not visible
      // toggle menu open
      const menu = splitPane.querySelector("ion-menu");
      return menu.open();
    }
  }

  function editProfile(profile) {
    history.push("/updateprofile", { user: profile.attributes });
  }

  const refresh = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const { refreshToken } = cognitoUser.getSignInUserSession();
    cognitoUser.refreshSession(refreshToken, (err, session) => {
      window.location.reload()
    })
  }

  return (
    <IonHeader className="header">
      <IonToolbar>
        <IonCol slot="start">
          <IonImg src={item.src} className="logo"></IonImg>
          <IonButtons>
            <IonMenuButton />
          </IonButtons>
        </IonCol>

        <IonCol slot="end">
          <IonItem>
            <IonLabel>Welcome {userFullName} </IonLabel>
            <IonRouterLink onClick={() => logout()} className="link">
              &nbsp;Signout <IonIcon icon={exit} className="link" />
            </IonRouterLink>
          </IonItem>
          <IonItem>
            <IonLabel>Logged in as {userAttributes?.roles}</IonLabel>
            <IonRouterLink
              onClick={(e) => {
                editProfile(userState.authenticatedUser);
              }}
            >
              <IonText className="link">&nbsp;Edit</IonText>
            </IonRouterLink>
          </IonItem>
        </IonCol>
        <IonCol slot="start">
          <IonTitle>
            <h3>Medline Health</h3>
          </IonTitle>
          <Suspense fallback={<p>loading...</p>}>
            <LazyLoadGroupsComponent />
          </Suspense>
        </IonCol>
      </IonToolbar>
      <Modal
        open={timeoutModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography style={{ marginBottom: 5 }} id="modal-modal-title" variant="h5" component="h2">
            Uh-oh! Your session has timed out ⌛
          </Typography>
          <Typography style={{ marginBottom: 10 }} id="modal-modal-title">
            It seems you took a bit too long.
          </Typography>
          <Typography id="modal-modal-title">
            Refresh the page to continue working or you will be logged out in few seconds.
          </Typography>
          <div style={{ marginTop: 20 }}>
            <Button variant="contained" onClick={async () => {
              refresh()
            }}>Refresh</Button>
            {/* <Button style={{ marginLeft: 10 }} variant='text' onClick={async () => {
              setContinueWorking(false)
              logout()
            }}>Log out now</Button> */}
          </div>
        </Box>
      </Modal>
    </IonHeader>
  );
};

export default Header;
