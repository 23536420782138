import { Icon } from "@iconify/react";
import { IonCard, IonCardContent, IonCardHeader, IonTitle } from "@ionic/react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Link,
  Stack,
  TextareaAutosize,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as React from "react";
import { FC, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { ProviderContext } from "../../ApplicationState";
import { ReactComponent as OxygenLogo } from "./oxygensaturation.svg";
import { useBreachThreshold } from "./useBreachThreshold";

var _ = require("lodash");

export const FormDialog = (props) => {
  const { updateBreachNote, measurementData, row, deleteRow } =
    useBreachThreshold();
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [disable, setDisable] = React.useState(
    !_.isEmpty(props.cellValues.row.reviewStatuses)
  );

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleNoteSave = async () => {
    if (_.isEmpty(text)) {
      return alert("Please add note to save.");
    }
    setLoading(true);
    const breachData = _.filter(
      measurementData,
      (data) => data.id === props.cellValues.row.id
    );
    const noteToSend = { ...breachData[0], reviewStatuses: [{ note: text }] };
    const dataSent = await updateBreachNote(noteToSend);
    if (dataSent.status === 200) {
      setLoading(false);
      alert("Success. Note Added.");
      const rowToBedeleted = _.filter(
        row,
        (value) => value.id === props.cellValues.row.id
      );
      deleteRow(rowToBedeleted, row);
      setOpen(false);
    } else {
      setLoading(false);
      alert("Something went wrong. Please try again.");
    }
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  return (
    <div>
      <div style={{ marginLeft: 600 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          disabled={disable}
        >
          Acknowledge
        </Button>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>Note</DialogContentText>
          <TextareaAutosize
            minRows={3}
            style={{ width: 200 }}
            value={text}
            onChange={handleTextChange}
            onReset={undefined}
            onResetCapture={undefined} onResize={undefined} onResizeCapture={undefined} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleNoteSave}>Save</Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: "#3880ff",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const BreachColumn = (measurement) => {
  const columns: GridColDef[] = [
    {
      field: "userFullName",
      headerName: "Patient Name",
      width: 200,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <Link component="button" variant="body2">
            {cellValues.value}
          </Link>
        );
      },
    },
    {
      field: "email",
      headerName: "Username",
      width: 200,
      editable: false,
    },
    {
      field: "createdOn",
      headerName: "Date/Time",
      width: 200,
      editable: false,
    },
    {
      field: "vitals",
      headerName: "Vitals",
      width: 800,
      editable: false,
      renderCell: (cellValues) => {
        const isWeight = cellValues.row.longName === "Weight";
        const isBloodPressure =
          cellValues.row.longName === "Systolic" ||
          cellValues.row.longName === "Diastolic";
        const isSugar = cellValues.row.longName === "Sugar";
        const isTemp = cellValues.row.longName === "Temperature";
        const isPulse = cellValues.row.longName === "PulseRate";
        const isOxygen = cellValues.row.longName === "Oxygen Saturation";
        const isreviewStatusesEmpty = _.isEmpty(cellValues.row.reviewStatuses);
        const bloodPressure = _.filter(
          cellValues.formattedValue,
          (data) => data.MeasurementType === "Blood Pressure"
        );
        const temp = _.filter(
          cellValues.formattedValue,
          (data) => data.MeasurementType === "Temperature"
        );
        const sugar = _.filter(
          cellValues.formattedValue,
          (data) => data.MeasurementType === "Blood Glucose"
        );
        const weight = _.filter(
          cellValues.formattedValue,
          (data) => data.MeasurementType === "Weight"
        );
        const pulse = _.filter(
          cellValues.formattedValue,
          (data) => data.MeasurementType === "Heart Rate"
        );
        const oxygen = _.filter(
          cellValues.formattedValue,
          (data) => data.MeasurementType === "Oxygen Saturation"
        );
        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  marginTop: 20,
                  marginLeft: 100,
                  marginBottom: 10,
                }}
              >
                <Icon
                  icon="fa6-solid:weight-scale"
                  style={{
                    color: isWeight ? "red" : "#3880ff",
                    fontSize: "24px",
                  }}
                />
                <p style={{ color: isWeight ? "red" : "#3880ff" }}>
                  {" "}
                  {_.isEmpty(weight) ? "--" : weight[0].Value}{" "}
                </p>
              </div>

              <div
                style={{
                  position: "absolute",
                  marginTop: 20,
                  marginRight: 30,
                  marginLeft: 200,
                  marginBottom: 10,
                }}
              >
                <Icon
                  icon="healthicons:diabetes-measure-outline"
                  style={{
                    color: isSugar ? "red" : "#3880ff",
                    fontSize: "24px",
                  }}
                />
                <p style={{ color: isSugar ? "red" : "#3880ff" }}>
                  {" "}
                  {_.isEmpty(sugar) ? "--" : sugar[0].Value}{" "}
                </p>
              </div>
              <div
                style={{
                  position: "absolute",
                  marginTop: 20,
                  marginRight: 30,
                  marginLeft: 300,
                  marginBottom: 10,
                }}
              >
                {/* <Icon
                                    icon="simple-icons:oxygen"
                                    style={{
                                        color: isOxygen ? "red" : "#3880ff",
                                        fontSize: "20px",
                                    }}
                                /> */}
                <OxygenLogo fill={isOxygen ? "red" : "#4c7ff7"} />
                <p style={{ color: isOxygen ? "red" : "#3880ff" }}>
                  {" "}
                  {_.isEmpty(oxygen) ? "--" : oxygen[0].Value}{" "}
                </p>
              </div>
              <div
                style={{
                  position: "absolute",
                  marginTop: 20,
                  marginRight: 30,
                  marginLeft: 400,
                  marginBottom: 10,
                }}
              >
                <Icon
                  icon="bi:heart-pulse"
                  style={{
                    color: isPulse ? "red" : "#3880ff",
                    fontSize: "24px",
                  }}
                />
                <p style={{ color: isPulse ? "red" : "#3880ff" }}>
                  {" "}
                  {_.isEmpty(pulse) ? "--" : pulse[0].Value}{" "}
                </p>
              </div>
              <div
                style={{
                  position: "absolute",
                  marginTop: 20,
                  marginRight: 30,
                  marginLeft: 500,
                  marginBottom: 10,
                }}
              >
                <Icon
                  icon="carbon:temperature-fahrenheit"
                  style={{
                    color: isTemp ? "red" : "#3880ff",
                    fontSize: "24px",
                  }}
                />
                <p style={{ color: isTemp ? "red" : "#3880ff" }}>
                  {" "}
                  {_.isEmpty(temp) ? "--" : temp[0].Value}{" "}
                </p>
              </div>
              <div
                style={{
                  position: "absolute",
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                <Icon
                  icon="healthicons:blood-pressure-2-outline"
                  style={{
                    color: isBloodPressure ? "red" : "#3880ff",
                    fontSize: "24px",
                  }}
                />
                <p style={{ color: isBloodPressure ? "red" : "#3880ff" }}>
                  {" "}
                  {_.isEmpty(bloodPressure)
                    ? "--/--"
                    : bloodPressure[0].Value}{" "}
                </p>
              </div>
            </div>
            <FormDialog
              cellValues={cellValues}
              isreviewStatusesEmpty={isreviewStatusesEmpty}
            />
          </>
        );
      },
    },
  ];

  return columns;
};

export type BreachThresholdProps = {
  row: any;
  column: any;
  loading: any;
  render: any;
};

const BreachThreshold: FC<BreachThresholdProps> = ({
  row,
  column,
  loading,
  render,
}) => {
  const { providerState } = React.useContext(ProviderContext);

  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    window.addEventListener("load", render);
  }, [providerState]);

  const handleRoute = (e) => {
    history.push("/userprofile", { userId: e.row.userId });
  };

  return (
    <IonCard>
      <IonCardHeader>
        <IonTitle style={{ color: "black" }}>Threshold Breaches</IonTitle>
      </IonCardHeader>
      <IonCardContent>
        <div style={{ height: 400, width: "100%" }}>

          {(loading && column === null && row === null) ||
            location.pathname === "/configuration/1" ? (
            <Stack
              alignItems="center"
              display="flex"
              justifyContent="center"
              paddingTop="100px"
            >
              <CircularProgress
                sx={{
                  display:
                    location.pathname === "/configuration/1" ? "none" : "flex",
                  flexDirection: "column-reverse",
                }}
              />
            </Stack>
          ) : (
            <DataGrid
              sx={{
                display: "flex",
                flexDirection: "column-reverse",
                width: "100%",
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              // initialState={{
              //     sorting: {
              //         sortModel: [{ field: 'createdOn', sort: 'desc' }],
              //     },
              // }}
              rows={
                column === null || row === null ? [] : row
              }
              // autoHeight={true}
              loading={column === null || row === null}
              columns={column}
              pageSizeOptions={[5]}
              //rowsPerPageOptions={[5]}
              checkboxSelection={false}
              onRowClick={handleRoute}
              autoHeight={true}
            />
          )}

        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default BreachThreshold;
