import React, { useContext, useEffect, useState } from "react";
import {
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonLabel,
  IonInput,
  IonToast,
  IonText,
  IonPage,
  IonContent,
  IonCol,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonAvatar,
  IonCardHeader,
  IonList,
  IonSpinner,
  IonIcon,
  IonBackButton,
  IonListHeader,
  IonLoading,
} from "@ionic/react";
import { useHistory } from "react-router";
import ExploreContainer from "../../components/ExploreContainer";

import * as ApiService from "../../services/ApiService";
import "./BillingReport.css";
import UsersComponent from "../../components/UsersComponent";
import {
  ApplicationContext,
  ProviderContext,
  ProviderContextProvider,
} from "../../ApplicationState";
import Header from "../../components/Header/Header";
import { addCircle, arrowBackCircleSharp } from "ionicons/icons";
import * as Helpers from "../../services/Helpers";
import saveAs from "save-as";
import { CloudDownloadSharp } from "react-ionicons";

const BillingReport: React.FC = (props: any) => {
  const { userState } = useContext(ApplicationContext);
  const [filteredReport, setFilteredReport] = React.useState([]);
  const [report, setReport] = React.useState([]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect((): any => {
    if (userState.authenticatedUser !== undefined) {
      let isSubscribed = true;

      ApiService.getPatientCountReport(userState.authenticatedUser, "table")
        .then((data) => {
          setReport(data);
          setFilteredReport(data);
        })
        .catch((err) => {
          alert(err);
        });
      return () => (isSubscribed = false);
    }
  }, [userState]);

  function downloadXls() {
    setShowLoading(true)
    ApiService.getPatientCountReport(userState.authenticatedUser, "xls")
      .then((data) => {
        setShowLoading(false)
        const downloadedFile = Helpers.base64ToBlob(
          data.fileContents,
          data.contentType
        );
        saveAs(downloadedFile);
      })
      .catch((err) => {
        setShowLoading(false)
        alert(err);
      });
  }

  return (
    <IonPage>
      <Header />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text="Go Back"
              icon={arrowBackCircleSharp}
              defaultHref="/"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonRow>
          <IonItem className="col">
            <IonButton onClick={() => downloadXls()} slot="end">
              Xls&nbsp;&nbsp;&nbsp;
              <CloudDownloadSharp
                color={"#00000"}
                title="Download xls"
                height="15px"
                width="15px"
              />
            </IonButton>
          </IonItem>
        </IonRow>
        <IonList>
          <IonListHeader color="primary">
            <IonLabel className="col-3">Provider Name</IonLabel>
            <IonLabel className="col-2">Code</IonLabel>
            <IonLabel className="col-2">Patient Count</IonLabel>
            <IonLabel className="col-2">Unit Price</IonLabel>
            <IonLabel className="col-2">Total Cost</IonLabel>
          </IonListHeader>
          {filteredReport?.map((a) => {
            return (
              <IonItem
                key={a.providerCode}
                button
                onClick={() => { }}
                lines="full"
              >
                <IonLabel className="col-3">{a.providerName}</IonLabel>
                <IonLabel className="col-2">
                  <i>{a.providerCode}</i>
                </IonLabel>
                <IonLabel className="col-2">
                  <i>{a.count}</i>
                </IonLabel>
                <IonLabel className="col-2">
                  <i>{a.unitPrice}</i>
                </IonLabel>
                <IonLabel className="col-2">
                  <i>{a.totalCost}</i>
                </IonLabel>
              </IonItem>
            );
          })}
        </IonList>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
        {/* <IonItem button routerLink={"/register"} routerDirection="none">Register New User</IonItem> */}
        {/* <UsersComponent type="Users" /> */}
      </IonContent>
    </IonPage>
  );
};

export default BillingReport;
