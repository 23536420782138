import { Component, StateActions } from "../../types";
import { SET_COMPONENT } from "../ActionType";

export const componentReducer = (component: Component[], action: StateActions): any => {
    console.log("🚀 ~ file: componentReducer.tsx ~ line 12 ~ componentReducer -->", component)

    switch (action.type) {
        case SET_COMPONENT:
            return [{ ...component, ...action.payload }];
        default:
            return component;
    }
}
