import { IonButton, useIonLoading } from "@ionic/react"
import { Box, useMediaQuery } from "@mui/material"
import { type } from "os"
import { FC, useState } from "react"
import { MeasurementTypes } from "../../pages/Threshold/Constants"
import { DefaultState } from "../../pages/Threshold/ThresholdModel"
import { FormFields } from "../FormField/FormFields"
import * as ApiService from '../../services/ApiService'

var _ = require('lodash');

export type DefaultThresholdProps = {
    defaultValues: DefaultState
    fieldDisabled: boolean
    inputChange: boolean
    measurement: any[]
    userState: any
    providerData: any
    global: boolean
    handleDefaultChange: (prop: keyof DefaultState) => (event: React.ChangeEvent<HTMLInputElement>) => void
    handleDefaultEditFields: () => void
    setFieldDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

export const DefaultThreshold: FC<DefaultThresholdProps> = ({
    defaultValues,
    fieldDisabled,
    inputChange,
    measurement,
    handleDefaultChange,
    handleDefaultEditFields,
    setFieldDisabled,
    userState,
    providerData,
    global
}) => {
    const [showLoading, setShowLoading] = useState(true);
    const [present, dismiss] = useIonLoading();
    const desktop = useMediaQuery('(min-width:600px)');

    const handleDefaultSaveFields = () => {
        if (!inputChange) {
            return
        }

        if (emptyFields()) {
            alert('You have left one or more field empty. Please enter a value.')
            return
        }

        present({
            message: 'updating values...',
        })
        const data = { ...providerData, thresholds: measurement }
        if (global) {
            const response = ApiService.updateThresholds(userState.authenticatedUser, data).then((res) => {
                setFieldDisabled(true)
                dismiss()
                alert('Success. Thresholds values updated.')
            }).catch((error) => {
                dismiss()
                alert('Oops something went wrong. Please try again.')
                console.log('error updating threshold values', error)
            })
        } else {
            ApiService.updateSelectedUserThresholds(userState.authenticatedUser, data).then((res) => {
                setFieldDisabled(true)
                dismiss()
                alert('Success. Thresholds values updated.')
            }).catch((error) => {
                dismiss()
                alert('Oops something went wrong. Please try again.')
                console.log('error updating threshold values', error)
            })
        }

    }

    const emptyFields = () => {
        const empty = _.isEmpty(defaultValues.bloodPressureSystolicMin) && _.isEmpty(defaultValues.bloodPressureSystolicMin) &&
            _.isEmpty(defaultValues.bloodPressureDiastolicMin) && _.isEmpty(defaultValues.bloodPressureDiastolicMax) &&
            _.isEmpty(defaultValues.bloodGlucoseMin) && _.isEmpty(defaultValues.bloodGlucoseMax) &&
            _.isEmpty(defaultValues.pulseMin) && _.isEmpty(defaultValues.pulseMax) &&
            _.isEmpty(defaultValues.temperatureMin) && _.isEmpty(defaultValues.temperatureMax) &&
            _.isEmpty(defaultValues.weightMin) && _.isEmpty(defaultValues.weightMax) &&
            _.isEmpty(defaultValues.bloodSaturation)

        return empty
    }

    return (
        <>
            <div style={{ marginLeft: 10 }}>
                <IonButton size="default" color="primary" onClick={handleDefaultEditFields}>Edit</IonButton>
                <IonButton size="default" color="primary" onClick={handleDefaultSaveFields}>Save</IonButton>
            </div>
            <Box sx={{ boxShadow: 3, display: 'flex', flexDirection: 'column', m: 2 }} >
                <div style={{ display: 'flex', flexDirection: desktop ? 'row' : 'column', alignItems: 'baseline', margin: 10 }}>
                    <FormFields
                        style={{ m: 2, width: global ? '30ch' : '25ch' }}
                        label={'Blood Pressure Systolic'}
                        prompt={'min (<= value)'}
                        id={MeasurementTypes.BloodPressureSystolicMin}
                        value={defaultValues.bloodPressureSystolicMin}
                        adornment={'mmhg'}
                        handleFormValueChange={handleDefaultChange(MeasurementTypes.BloodPressureSystolicMin)}
                        disabled={fieldDisabled}
                    >
                    </FormFields>

                    <FormFields
                        style={{ m: 2, width: global ? '30ch' : '25ch' }}
                        label={''}
                        prompt={'max (>= value)'}
                        id={MeasurementTypes.BloodPressureSystolicMax}
                        value={defaultValues.bloodPressureSystolicMax}
                        adornment={'mmhg'}
                        handleFormValueChange={handleDefaultChange(MeasurementTypes.BloodPressureSystolicMax)}
                        disabled={fieldDisabled}
                    >
                    </FormFields>

                </div>

                <div style={{ display: 'flex', flexDirection: desktop ? 'row' : 'column', alignItems: 'baseline', margin: 10 }}>
                    <FormFields
                        style={{ m: 2, width: global ? '30ch' : '25ch' }}
                        label={'Blood Pressure Diastolic'}
                        prompt={'min (<= value)'}
                        id={MeasurementTypes.BloodPressureDiastolicMin}
                        value={defaultValues.bloodPressureDiastolicMin}
                        adornment={'mmhg'}
                        handleFormValueChange={handleDefaultChange(MeasurementTypes.BloodPressureDiastolicMin)}
                        disabled={fieldDisabled}
                    >
                    </FormFields>

                    <FormFields
                        style={{ m: 2, width: global ? '30ch' : '25ch' }}
                        label={''}
                        prompt={'max (>= value)'}
                        id={MeasurementTypes.BloodPressureDiastolicMax}
                        value={defaultValues.bloodPressureDiastolicMax}
                        adornment={'mmhg'}
                        handleFormValueChange={handleDefaultChange(MeasurementTypes.BloodPressureDiastolicMax)}
                        disabled={fieldDisabled}
                    >
                    </FormFields>
                </div>

                <div style={{ display: 'flex', flexDirection: desktop ? 'row' : 'column', alignItems: 'baseline', marginLeft: 10 }}>
                    <FormFields
                        style={{ ml: { md: 11, xs: 2 }, width: global ? '30ch' : '25ch' }}
                        label={'Blood Glucose'}
                        prompt={'min (<= value) '}
                        id={MeasurementTypes.BloodGlucoseMin}
                        value={defaultValues.bloodGlucoseMin}
                        adornment={'mg/dl'}
                        handleFormValueChange={handleDefaultChange(MeasurementTypes.BloodGlucoseMin)}
                        disabled={fieldDisabled}
                    >
                    </FormFields>

                    <FormFields
                        style={{ ml: { md: 4, xs: 2 }, width: global ? '30ch' : '25ch' }}
                        label={''}
                        prompt={'max (>= value)'}
                        id={MeasurementTypes.BloodGlucoseMax}
                        value={defaultValues.bloodGlucoseMax}
                        adornment={'mg/dl'}
                        handleFormValueChange={handleDefaultChange(MeasurementTypes.BloodGlucoseMax)}
                        disabled={fieldDisabled}
                    >
                    </FormFields>
                </div>

                <div style={{ display: 'flex', flexDirection: desktop ? 'row' : 'column', alignItems: 'baseline', marginTop: 30, marginLeft: 10 }}>

                    <FormFields
                        style={{ ml: { md: 19, xs: 2 }, width: global ? '30ch' : '25ch' }}
                        label={'Pulse'}
                        prompt={'min (<= value)'}
                        id={MeasurementTypes.PulseMin}
                        value={defaultValues.pulseMin}
                        adornment={'bpm'}
                        handleFormValueChange={handleDefaultChange(MeasurementTypes.PulseMin)}
                        disabled={fieldDisabled}
                    >
                    </FormFields>

                    <FormFields
                        style={{ ml: { md: 4, xs: 1 }, width: global ? '30ch' : '25ch' }}
                        label={''}
                        prompt={'max (>= value)'}
                        id={MeasurementTypes.PulseMax}
                        value={defaultValues.pulseMax}
                        adornment={'bpm'}
                        handleFormValueChange={handleDefaultChange(MeasurementTypes.PulseMax)}
                        disabled={fieldDisabled}
                    >
                    </FormFields>
                </div>


                <div style={{ display: 'flex', flexDirection: desktop ? 'row' : 'column', alignItems: 'baseline', marginLeft: 10, marginTop: 30 }}>
                    <FormFields
                        style={{ ml: { md: 12.5, xs: 2 }, width: global ? '30ch' : '25ch' }}
                        label={'Temperature'}
                        prompt={'min (<= value)'}
                        id={MeasurementTypes.TemperatureMin}
                        value={defaultValues.temperatureMin}
                        adornment={'F'}
                        handleFormValueChange={handleDefaultChange(MeasurementTypes.TemperatureMin)}
                        disabled={fieldDisabled}
                    >
                    </FormFields>

                    <FormFields
                        style={{ ml: 4, width: global ? '30ch' : '25ch' }}
                        label={''}
                        prompt={'max (>= value)'}
                        id={MeasurementTypes.TemperatureMax}
                        value={defaultValues.temperatureMax}
                        adornment={'F'}
                        handleFormValueChange={handleDefaultChange(MeasurementTypes.TemperatureMax)}
                        disabled={fieldDisabled}
                    >
                    </FormFields>
                </div>

                <div style={{ display: 'flex', flexDirection: desktop ? 'row' : 'column', alignItems: 'baseline', marginLeft: 10, marginTop: 30 }}>

                    <FormFields
                        style={{ ml: { md: 18, xs: 2 }, width: global ? '30ch' : '25ch' }}
                        label={'Weight'}
                        prompt={'min (<= value)'}
                        id={MeasurementTypes.WeightMin}
                        value={defaultValues.weightMin}
                        adornment={'lbs'}
                        handleFormValueChange={handleDefaultChange(MeasurementTypes.WeightMin)}
                        disabled={fieldDisabled}
                    >
                    </FormFields>

                    <FormFields
                        style={{ ml: 4, width: global ? '30ch' : '25ch' }}
                        label={''}
                        prompt={'max (>= value)'}
                        id={MeasurementTypes.WeightMax}
                        value={defaultValues.weightMax}
                        adornment={'lbs'}
                        handleFormValueChange={handleDefaultChange(MeasurementTypes.WeightMax)}
                        disabled={fieldDisabled}
                    >
                    </FormFields>
                </div>

                <div style={{ display: 'flex', flexDirection: desktop ? 'row' : 'column', alignItems: 'baseline', marginLeft: 10, marginTop: 30, marginBottom: 5 }}>
                    <FormFields
                        style={{ ml: { md: 9, xs: 2 }, width: global ? '30ch' : '25ch' }}
                        label={'Oxygen Saturation'}
                        prompt={'min (<= value)'}
                        id={MeasurementTypes.BloodSaturation}
                        value={defaultValues.bloodSaturation}
                        adornment={'%'}
                        handleFormValueChange={handleDefaultChange(MeasurementTypes.BloodSaturation)}
                        disabled={fieldDisabled}
                    >
                    </FormFields>
                </div>

            </Box>
        </>
    )
}