import React, { useContext, useEffect, useState } from "react";
import {
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonLabel,
  IonInput,
  IonToast,
  IonText,
  IonPage,
  IonContent,
  IonCol,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonAvatar,
  IonCardHeader,
  IonList,
  IonSpinner,
  IonBackButton,
} from "@ionic/react";
import { useHistory } from "react-router";
import ExploreContainer from "../../components/ExploreContainer";

import * as ApiService from "../../services/ApiService";
import "./patients.css";
import UsersComponent from "../../components/UsersComponent";
import {
  ApplicationContext,
  ProviderContext,
  ProviderContextProvider,
} from "../../ApplicationState";
import Header from "../../components/Header/Header";
import { arrowBackCircleSharp } from "ionicons/icons";

const Patients: React.FC = (props: any) => {
  return (
    <IonPage>
      <Header />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text="Go Back"
              icon={arrowBackCircleSharp}
              defaultHref="/"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <UsersComponent type="Patients" />
      </IonContent>
    </IonPage>
  );
};

export default Patients;
