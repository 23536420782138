import React, { useContext, useEffect } from "react";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonAvatar,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonSpinner,
  IonLoading,
  IonHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonTitle,
  IonIcon,
  IonRouterLink,
  IonSegment,
  IonContent,
  IonListHeader,
} from "@ionic/react";
import {
  ApplicationContext,
  ProviderContext,
  ProviderContextProvider,
} from "../ApplicationState";
import * as ApiService from "../services/ApiService";
import * as Helpers from "../services/Helpers";
import { useHistory, useLocation } from "react-router";
import { IUserProps } from "../models/user";
import {
  ArrowForwardCircleSharp,
  BanSharp,
  CheckmarkCircleSharp,
  CheckmarkDoneSharp,
  CloseCircleSharp,
  CreateSharp,
  PeopleSharp,
} from "react-ionicons";

import {
  addCircle,
  arrowBackSharp,
  arrowForward,
  arrowForwardCircle,
  arrowForwardSharp,
  checkmarkDoneCircleOutline,
  closeCircleOutline,
  expandSharp,
  happyOutline,
  key,
  sadOutline,
} from "ionicons/icons";
import "./Components.css";
import { convertArrayToObject } from "../services/Helpers";

const ProvidersComponent: React.FC<any> = (props: any) => {
  const providerContext = useContext(ProviderContext);
  const history = useHistory();
  const location: any = useLocation();
  const local = useLocation();
  const { userState } = useContext(ApplicationContext);
  const { providerState } = useContext(ProviderContext);
  const [searchText, setSearchText] = React.useState("");
  const [providers, setProviders] = React.useState([]);
  const [filteredProviders, setFilteredProviders] = React.useState([]);
  const [showLoading, setShowLoading] = React.useState(true);
  const [type, setType] = React.useState("");

  useEffect((): any => {
    let isSubscribed = true;
    let user = userState.authenticatedUser;

    let userType: any = props.type;
    if (location.state?.type !== undefined) userType = location.state?.type;

    if (providerState !== undefined) {
      if (
        providerState.providerId !== undefined &&
        providerState.providerId !== ""
      ) {
        setShowLoading(true);
        ApiService.fetchProviderAndThreshold(userState.authenticatedUser).then((data) => {
          if (isSubscribed) {
            if (data) {
              setProviders(data);
              setFilteredProviders(data);
              setShowLoading(false);
              setType(userType);
            }
          }
        });
      }
    }

    return () => (isSubscribed = false);
  }, [providerState, location]);

  function onProviderSelectionChange($event, provider) {
    history.push("/provider", { provider: provider });
  }

  function addNewProvider() {
    history.push("/provider", { provider: {} });
  }

  function onSearchTextChange(searchTerm: string) {
    setSearchText(searchTerm);
    if (searchTerm === "") {
      setFilteredProviders(providers);
      return;
    }
    //
    setFilteredProviders(
      providers.filter((p) => {
        return (
          p.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          p.providerName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          p.providerCode?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
    );
  }

  return (
    <IonCard>
      <IonButton onClick={() => addNewProvider()}>
        Register New Provider <IonIcon icon={addCircle} />
      </IonButton>
      <IonCardHeader>
        <IonItem>
          <IonTitle>List of Providers</IonTitle>
        </IonItem>
      </IonCardHeader>
      <IonCardContent>
        <IonSearchbar
          value={searchText}
          onIonChange={(e) => onSearchTextChange(e.detail.value!)}
          showCancelButton="always"
        ></IonSearchbar>
        <IonListHeader color="primary">
          <IonLabel className="col-1"></IonLabel>
          <IonLabel className="col">Provider</IonLabel>
          <IonLabel className="col">Code</IonLabel>
          <IonLabel className="col">Contact Email</IonLabel>
          <IonLabel className="col">Type</IonLabel>
          <IonLabel className="col">Actions</IonLabel>
        </IonListHeader>
        <IonList color="primary" slot="start">
          {filteredProviders?.map((a) => {
            let isActive: boolean = a.isActive;
            let providerCode = a.providerCode;
            let providerName = a.providerName;
            let providerType = a.providerType?.name;
            return (
              <IonItem key={providerCode}>
                <IonItem
                  onClick={(e) => onProviderSelectionChange(e, a)}
                  className="link"
                >
                  {isActive ? (
                    <CheckmarkCircleSharp
                      cssClasses="icons"
                      color={"#00cc44"}
                      title="Active"
                      height="16px"
                      width="16px"
                      onClick={(e) => onProviderSelectionChange(e, a)}
                    />
                  ) : (
                    <CloseCircleSharp
                      cssClasses="icons"
                      color={"#ff1a1a"}
                      title="Inactive"
                      height="16px"
                      onClick={(e) => onProviderSelectionChange(e, a)}
                      width="16px"
                    />
                  )}
                </IonItem>

                <IonLabel
                  className="col link"
                  onClick={(e) => onProviderSelectionChange(e, a)}
                >
                  {providerName}
                </IonLabel>
                <IonLabel
                  className="col link"
                  onClick={(e) => onProviderSelectionChange(e, a)}
                >
                  <i>{providerCode}</i>
                </IonLabel>
                <IonLabel
                  className="col link"
                  onClick={(e) => onProviderSelectionChange(e, a)}
                >
                  <i>{a.email}</i>
                </IonLabel>
                <IonLabel
                  className="col link"
                  onClick={(e) => onProviderSelectionChange(e, a)}
                >
                  <i>{providerType}</i>
                </IonLabel>
                <IonItem className="col ">
                  <CreateSharp
                    cssClasses="icons"
                    color={"#ff751a"}
                    title="Edit Provider"
                    height="28px"
                    width="28px"
                    onClick={(e) => onProviderSelectionChange(e, a)}
                  />
                </IonItem>
              </IonItem>
            );
          })}
        </IonList>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
          duration={5000}
        />
      </IonCardContent>
    </IonCard>
  );
};

export default ProvidersComponent;
