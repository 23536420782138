import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import React, { useContext, useEffect } from "react";

import { Line } from "react-chartjs-2";
import { Controller } from "react-hook-form";
import { ApplicationContext } from "../../ApplicationState";
import { formatReadingForChart } from "../../services/Helpers";
import * as ApiService from "../../services/ApiService";
import * as Helpers from "../../services/Helpers";
import moment from "moment";

const MeasurementChart: React.FC<any> = (props: any) => {
  const { userState } = useContext(ApplicationContext);
  const [defaultMeasurementValue, setdefaultMeasurementValue] =
    React.useState("Blood Pressure");
  const [groupedData, setGroupedData] = React.useState([]);
  const mLabels = [
    "Blood Pressure",
    "Heart Rate",
    "Oxygen Saturation",
    "Temperature",
    "Weight",
    "Blood Glucose",
  ];

  const [plotLabels, setPlotLabels] = React.useState([]);
  const [plotData, setPlotData] = React.useState([]);

  const data: any = {
    labels: plotLabels,
    datasets: plotData,
  };

  const dateFormatString = "YYYY-MM-DD";
  let startDate = moment(new Date());
  let endDate = moment(new Date());
  const [selectedStartDate, setSelectedStartDate] = React.useState<string>(
    startDate?.add(-12, "months").format(dateFormatString)
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState<string>(
    endDate?.format(dateFormatString)
  );

  useEffect((): any => {
    let isChartSubscribed = true;

    if (props.type === "User") {
      if (
        props.userId !== undefined &&
        userState.authenticatedUser !== undefined
      ) {
        ApiService.fetchUserReadings(
          userState.authenticatedUser,
          props.userId,
          200,
          1,
          moment(selectedStartDate).format(dateFormatString),
          moment(selectedEndDate).format(dateFormatString),
          null,
          null,
          null,
          null
        )
          .then((d) => {
            if (d && d.length > 0) {
              if (isChartSubscribed) {
                var sorted = formatReadingForChart(d);
                setGroupedData(sorted);

                if (defaultMeasurementValue !== "Blood Pressure") {
                  var otherReadings = sorted[defaultMeasurementValue];
                  let labels = [];
                  let d1 = {
                    label: defaultMeasurementValue,
                    backgroundColor: "rgba(37, 190, 134, 0.1)",
                    borderColor: "rgba(37, 190, 134, 1)",
                    borderWidth: 1,
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: [],
                  };
                  if (sorted[defaultMeasurementValue] !== undefined) {
                    if (sorted[defaultMeasurementValue].length > 10) {
                      sorted[defaultMeasurementValue]
                        .slice(0, 10)
                        .reverse()
                        .map((r) => {
                          d1.data.push(r.value);
                          labels.push(r.date);
                        });
                    } else {
                      sorted[defaultMeasurementValue].reverse().map((r) => {
                        d1.data.push(r.value);
                        labels.push(r.date);
                      });
                    }
                  }
                  setPlotLabels(labels);
                  var series: any = [];
                  series.push(d1);
                  setPlotData(series);
                } else {
                  let labels = [];
                  let d1 = {
                    label: "Systolic",
                    backgroundColor: "rgba(37, 150, 190, 0.1)",
                    borderColor: "rgba(37, 150, 190, 1)",
                    borderWidth: 1,
                    hoverBackgroundColor: "rgba(37, 150, 190,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: [],
                  };
                  let d2 = {
                    label: "Diastolic",
                    backgroundColor: "rgba(190, 112, 37, 0.1)",
                    borderColor: "rgba(190, 112, 37, 1)",
                    borderWidth: 1,
                    hoverBackgroundColor: "rgba(190, 112, 37,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: [],
                  };

                  if (sorted["Systolic"] !== undefined) {
                    if (sorted["Systolic"].length > 10) {
                      sorted["Systolic"]
                        .slice(0, 10)
                        .reverse()
                        .map((r) => d1.data.push(r.value));
                      sorted["Diastolic"]
                        .slice(0, 10)
                        .reverse()
                        .map((r) => {
                          d2.data.push(r.value);
                          labels.push(r.date);
                        });
                    } else {
                      sorted["Systolic"]
                        .reverse()
                        .map((r) => d1.data.push(r.value));
                      sorted["Diastolic"].reverse().map((r) => {
                        d2.data.push(r.value);
                        labels.push(r.date);
                      });
                    }
                  }

                  setPlotLabels(labels);
                  var series: any = [];
                  series.push(d1);
                  series.push(d2);
                  setPlotData(series);
                }
              }
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    }
    return () => (isChartSubscribed = false);
  }, [defaultMeasurementValue, props]);

  function onMeasurementChange(e) {
    setdefaultMeasurementValue(e);
  }

  const CompareWithGroup = (o1: any, o2: any) => {
    return o1 && o2 ? o1 === o2 : o1 === o2;
  };

  return (
    <div>
      <IonSelect
        onIonChange={(e) => onMeasurementChange(e.detail.value)}
        compareWith={CompareWithGroup}
        value={defaultMeasurementValue}
      >
        {Helpers.measurementTypes().map((a, index) => {
          return (
            <IonSelectOption value={a} key={index}>
              <IonItem>{a}</IonItem>
            </IonSelectOption>
          );
        })}
      </IonSelect>
      <Line data={data} />
    </div>
  );
};

export default MeasurementChart;
