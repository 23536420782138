import React, { useContext, useEffect } from "react";
import {
  IonHeader,
  IonTitle,
  IonItem,
  IonText,
  IonCol,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCardHeader,
} from "@ionic/react";

import * as ApiService from "../../services/ApiService";
import "./LatestReadings.css";
import { ApplicationContext } from "../../ApplicationState";
import moment from "moment";
import { formatMeasurementType, formatReading } from "../../services/Helpers";

const LatestReadings: React.FC<any> = (props) => {
  //const history = useHistory();
  const { userState } = useContext(ApplicationContext);
  let user = userState.authenticatedUser;
  const [latestReadings, setLatestReadings] = React.useState<any[]>([]);

  useEffect(() => {
    if (props.type === "User") fetchLatestReadings(props.selectedUser);
  }, []);

  async function fetchLatestReadings(selectedUser) {
    if (user !== undefined) {
      await ApiService.fetchLatestUserReadings(user, selectedUser.username)
        .then((data) => {
          if (data && data.length > 0) {
            setLatestReadings(data);
          }
        })
        .catch((e) => {});
    }
  }

  return (
    <IonCard>
      <IonCardHeader>
        <IonItem>
          <h3>Latest Readings</h3>
        </IonItem>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid className="container-fluid">
          <IonRow class="scroll-items">
            {latestReadings.map((a, index) => {
              let measurementType = a["measurementType"];
              let readingOn: any[] = a["readingOn"];
              let reading = a["reading"];
              let readingId = a["id"];
              let utcTime = moment.utc(readingOn);
              let localTime = utcTime.local().format("MMM DD YYYY hh:mm A");
              let isBreached = a["isBreach"];
              let className = "normal";
              if (isBreached) className = "breach";
              return (
                <IonCol
                  key={measurementType}
                  onClick={() => {}}
                  class="latest-reading "
                >
                  <IonCard className={className}>
                    <IonCardContent className="latestreading-card-content">
                      <IonItem>
                        <IonHeader>{formatMeasurementType(a)}</IonHeader>
                      </IonItem>
                      <IonItem>
                        <IonTitle>
                          <i>{formatReading(a)}</i>
                        </IonTitle>
                      </IonItem>
                      <IonItem>
                        <IonText>
                          <i>{localTime}</i>
                        </IonText>
                      </IonItem>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              );
            })}
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default LatestReadings;
