
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { FileManagerComponent, Inject, NavigationPane, DetailsView, Toolbar, Virtualization } from '@syncfusion/ej2-react-filemanager';
import { registerLicense } from '@syncfusion/ej2-base';

import { arrowBackCircleSharp } from 'ionicons/icons';
import Header from '../../components/Header/Header';
import { useContext, useEffect, useRef, useState } from 'react';
import { ApplicationContext, ProviderContext } from '../../ApplicationState';
import axios from "axios";
import * as ApiService from '../../services/ApiService';
import { Button } from '@mui/material';

registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF1cWWhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZiWH1ecXVRQWVVVkBzWQ==');

const fileManagerToolBar = ['NewFolder', 'SortBy', 'Cut', 'Copy', 'Paste', 'Delete', 'Refresh', 'Rename', 'Selection', 'View', 'Details', 'Upload']
const fileManagerLayout = ['SortBy', 'View', 'Refresh', '|', 'Paste', '|', 'NewFolder', '|', 'Details', '|', 'SelectAll']
const fileManagerFiles = ['Open', '|', 'Delete', 'Rename', '|', 'Details', 'Copy Link']
const fileManagerFolder = ['Open', '|', 'Delete', 'Rename', '|', 'Details']

type FileManagerProps = {
    isModal?: boolean;
    chooseFile?: (file: string, fileDetails: any) => void
    closeDialog?: () => void
}
/**
 * File Manager full functionalities sample
 */
const FileManager: React.FC<FileManagerProps> = ({
    isModal = false,
    chooseFile,
    closeDialog,
}: FileManagerProps) => {
    let fileObj: FileManagerComponent

    const { userState } = useContext(ApplicationContext);
    const { providerState } = useContext(ProviderContext);

    const [filedetails, setFileDetails] = useState()

    useEffect(() => {
        fileObj.refreshLayout()
    }, [providerState])

    function onSuccess(args) {
        console.log("Ajax request successful");
        { isModal && fileObj.refreshLayout() }
    }
    function onFailure(args) {
        { isModal && fileObj.refreshLayout() }
        console.log("Ajax request has failed");
    }
    function onCreated(args) {
        { isModal && fileObj.refreshLayout() }
        console.log("File Manager has been created successfully");
    }
    function onBeforeSend(args) {
        args.ajaxSettings.beforeSend = function (args) {
            args.httpRequest.setRequestHeader('Authorization', `bearer ${userState.authenticatedUser.signInUserSession.accessToken.jwtToken}`);
            args.httpRequest.setRequestHeader('Access-Control-Allow-Origin', "*");
            args.httpRequest.setRequestHeader('Accept', "*/*");
        };
    }

    function beforeImageLoad(args) {
        args.imageUrl = args.imageUrl + '&rootName=' + 'FileBrowser';
    }

    function beforeDownload(args) {
        args.data.rootFolderName = 'FileBrowser';
    }

    function onFileSelect(args: any): void {
        console.log(args.fileDetails.name + " has been " + args.action + "ed");
        console.log('<------------ ON FILE SELECT ---------->', args.fileDetails);
        setFileDetails(args.fileDetails)
        localStorage.setItem('selectedFileName', args.fileDetails.name)
    }

    const handleSelectedFile = () => {
        const fileName = localStorage.getItem('selectedFileName')
        chooseFile(fileName, filedetails)
    }

    function menuClick(args) {
        // if (args.item.text === 'Copy Link') {
        console.log(args.fileDetails.filterPath + args.fileDetails.name);
        let fileName = `${args.fileDetails.filterPath}` + `${args.fileDetails.name}`;
        localStorage.setItem('selectedFileName', args.fileDetails.name)
        ApiService.getfileManager(userState.authenticatedUser, fileName).then((res) => {
            navigator.clipboard.writeText(res.data).then(() => {
                setFileDetails(res.data)
            })
        })
        // }
    }

    function menuOpen(args: any): void {
        //console.log("🚀 ~ file: Filemanager.tsx:70 ~ menuOpen ~ args:", args)
        for (const i in args.items) {
            if (args.items[i].id === fileObj.element.id + '_cm_copy link') {

                args.items[i].iconCss = 'e-icons e-fe-copy';
            }
        }
    }

    return (
        <IonPage>
            {!isModal && (
                <><Header />
                    <IonHeader>
                        <IonToolbar color="light">
                            <IonButtons slot="start">
                                <IonBackButton
                                    text="Go Back"
                                    icon={arrowBackCircleSharp}
                                    defaultHref="/" />
                            </IonButtons>
                            <IonTitle>File Manager</IonTitle>
                        </IonToolbar>
                    </IonHeader></>
            )}
            {/* //s => (fileObj = s as FileManagerComponent) */}
            <IonContent className="ion-padding" >

                <div className="control-section">
                    <FileManagerComponent id="overview_file" allowMultiSelection={false} showItemCheckBoxes={false} ref={s => (fileObj = s as FileManagerComponent)}
                        ajaxSettings={{
                            url: ApiService.FileManagerURL.AzureFileOperations,
                            getImageUrl: ApiService.FileManagerURL.AzureGetImage,
                            uploadUrl: ApiService.FileManagerURL.AzureUpload,
                            downloadUrl: ApiService.FileManagerURL.AzureDownload,
                        }}

                        fileSelect={menuClick}
                        toolbarSettings={{ items: fileManagerToolBar }}
                        contextMenuSettings={{
                            layout: fileManagerLayout,
                            file: fileManagerFiles,
                            folder: fileManagerFolder
                        }}
                        uploadSettings={{ minFileSize: 1, maxFileSize: 500000000 }}
                        menuClick={menuClick}
                        menuOpen={menuOpen}
                        view={"Details"}
                        created={onCreated.bind(this)}
                        success={onSuccess.bind(this)}
                        failure={onFailure.bind(this)}
                        enableVirtualization={true}
                        beforeSend={onBeforeSend.bind(this)}
                        beforeImageLoad={beforeImageLoad.bind(this)}
                        beforeDownload={beforeDownload.bind(this)}>
                        <Inject services={[NavigationPane, DetailsView, Toolbar, Virtualization]} />
                    </FileManagerComponent>
                    {
                        isModal && (
                            <Button variant="contained" style={{ margin: 5 }} onClick={handleSelectedFile} autoFocus>
                                Choose
                            </Button>
                        )
                    }
                    {
                        isModal && (
                            < Button variant="contained" onClick={closeDialog} autoFocus>
                                Cancel
                            </Button>
                        )
                    }

                </div>
            </IonContent>
        </IonPage >

    );
}
export default FileManager;