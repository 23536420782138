import React, { Props } from "react";
import Amplify, { Auth } from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import HomeProps from "./../pages/Home";
import { attachProps } from "@ionic/react/dist/types/components/utils";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { Component } from "ionicons/dist/types/stencil-public-runtime";
import Cors from "cors";
import { getDecodedToken } from "./Helpers";
import { IUserProps } from "../models/user";
import * as ApiService from "../services/ApiService";
import { IProvider, IProviderType } from "../models/provider";
import { ENV } from "../environments/env";
//DEBUG settings
//const URL_LOCATION: string = "http://localhost:5000";
//
const URL_LOCATION: string = `${ENV.ENDPOINT}`; //"https://health-api-tst.medline.com";

export const fetchUser = (user: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
    "Accept": '*/*',
  };
  const URL = `${URL_LOCATION}/api/user/${user}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

export const addUser = (user: any, data: IUserProps) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  const URL = `${URL_LOCATION}/api/CognitoUser`;
  return axios({
    url: URL,
    method: "post",
    data: data,
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const updateUserAttributes = (user: any, data: IUserProps) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  const URL = `${URL_LOCATION}/api/CognitoUser`;
  return axios({
    url: URL,
    method: "put",
    data: data,
  }).then((response) => {
    return response;
  });
};

export const activateUser = (user: any, data: IUserProps) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  const URL = `${URL_LOCATION}/api/CognitoUser/activateuser/${data.username}`;
  return axios({
    url: URL,
    method: "put",
    data: data,
  }).then((response) => {
    return response;
  });
};

export const deactivateUser = (user: any, data: IUserProps) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  //const URL = `${URL_LOCATION}/api/CognitoUser/deactivateuser/${data.username}`;
  const URL = `${URL_LOCATION}/api/CognitoUser/AdminRemoveUserFromGroup`;
  return axios({
    url: URL,
    method: "delete",
    data: data,
  }).then((response) => {
    return response;
  });
};

export const getUserCount = (user: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  const URL = `${URL_LOCATION}/api/user/userscount`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

export const getPatientCountReport = (user: any, type: string) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  const URL = `${URL_LOCATION}/api/report/patientcount?type=` + type;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

export const fetchUsers = (
  user: any,
  groupId: string,
  pageSize: number,
  pageNum: number,
  startDate: string | null,
  endDate: string | null,
  sortKey: string | null,
  sortDirection: number | null,
  searchString: string | null,
  searchType: string,
  searchStringType?: string,
) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };

  //const jwtDecoded :any = getDecodedAccessToken(`${props.signInUserSession.accessToken.jwtToken}`) ;
  //let groupId = jwtDecoded["cognito:groups"][0];
  let URL = `${URL_LOCATION}/api/CognitoUser/ListUsers?groupcode=${groupId}&type=${searchType}&pageNumber=${pageNum}&pageSize=${pageSize}`;
  if (endDate !== null) URL += `&startDate=${startDate}&endDate=${endDate}`;
  if (searchString !== null) URL += `&searchString=${searchString}&searchStringType=${searchStringType}`;
  if (sortKey !== null)
    URL += `&sortKey=${sortKey}&sortDirection=${sortDirection}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

export const fetchPatients = (
  user: any,
  groupId: string,
  pageSize: number,
  pageNum: number,
  startDate: string | null,
  endDate: string | null,
  sortKey: string | null,
  sortDirection: number | null,
  searchString: string | null,
  searchType: string
) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  //const jwtDecoded :any = getDecodedAccessToken(`${props.signInUserSession.accessToken.jwtToken}`) ;
  //let groupId = jwtDecoded["cognito:groups"][0];
  let URL = `${URL_LOCATION}/api/CognitoUser/ListUsers?groupcode=${groupId}&type=${searchType}&pageNumber=${pageNum}&pageSize=${pageSize}`;
  if (endDate !== null) URL += `&startDate=${startDate}&endDate=${endDate}`;
  if (searchString !== null) URL += `&searchString=${searchString}`;
  if (sortKey !== null)
    URL += `&sortKey=${sortKey}&sortDirection=${sortDirection}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

export const fetchUserXls = (
  user: any,
  groupId: string,
  pageSize: number,
  pageNum: number,
  startDate: string | null,
  endDate: string | null,
  sortKey: string | null,
  sortDirection: number | null,
  searchString: string | null,
  searchType: string
) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  //const jwtDecoded :any = getDecodedAccessToken(`${props.signInUserSession.accessToken.jwtToken}`) ;
  //let groupId = jwtDecoded["cognito:groups"][0];
  let URL = `${URL_LOCATION}/api/report/users/xls?groupcode=${groupId}&type=${searchType}`;
  if (pageSize !== null) URL += `&pageNumber=${pageNum}&pageSize=${pageSize}`;
  if (endDate !== null) URL += `&startDate=${startDate}&endDate=${endDate}`;
  if (searchString !== null) URL += `&searchString=${searchString}`;
  if (sortKey !== null)
    URL += `&sortKey=${sortKey}&sortDirection=${sortDirection}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

export const fetchReadings = (
  loggedInUser: any,
  groupId: string,
  pageSize: number,
  pageNum: number,
  startDate: string | null,
  endDate: string | null,
  sortKey: string | null,
  sortDirection: number | null,
  searchString: string | null,
  measurementTypes: string | null,
  readingType: string | null
) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${loggedInUser.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  let URL = `${URL_LOCATION}/api/Measurement/ListGroupReadings/${groupId}?pageNumber=${pageNum}&pageSize=${pageSize}`;
  if (endDate !== null) URL += `&startDate=${startDate}&endDate=${endDate}`;
  if (searchString !== null) URL += `&searchString=${searchString}`;
  if (sortKey !== null)
    URL += `&sortKey=${sortKey}&sortDirection=${sortDirection}`;
  if (measurementTypes !== null && measurementTypes !== "")
    URL += `&measurementTypes=${measurementTypes}`;
  if (readingType !== null && readingType !== "")
    URL += `&readingType=${readingType}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    //
    return response.data;
  });
};

export const fetchGroupReadingsCount = (
  loggedInUser: any,
  groupId: string,
  startDate: string | null,
  endDate: string | null
) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${loggedInUser.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  let URL = `${URL_LOCATION}/api/Measurement/ListGroupReadingsCount/${groupId}?`;
  if (endDate !== null) URL += `&startDate=${startDate}&endDate=${endDate}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    //
    return response.data;
  });
};

export const fetchUserReadings = (
  loggedInUser: any,
  userId: string,
  pageSize: number,
  pageNum: number,
  startDate: string | null,
  endDate: string | null,
  sortKey: string | null,
  sortDirection: number | null,
  searchString: string | null,
  measurementTypes: string | null
) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${loggedInUser.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  //const jwtDecoded :any = getDecodedToken(`${loggedInUser.signInUserSession.accessToken.jwtToken}`) ;
  let URL = `${URL_LOCATION}/api/Measurement/ListUserReadings/${userId}?pageNumber=${pageNum}&pageSize=${pageSize}`;
  if (endDate !== null) URL += `&startDate=${startDate}&endDate=${endDate}`;
  if (searchString !== null) URL += `&searchString=${searchString}`;
  if (sortKey !== null)
    URL += `&sortKey=${sortKey}&sortDirection=${sortDirection}`;
  if (measurementTypes !== null && measurementTypes !== "")
    URL += `&measurementTypes=${measurementTypes}`;
  return axios({
    url: URL,
    method: "get",
  })
    .then((response) => {
      //
      return response.data;
    })
    .catch((err) => {
      throw new Error(err.response.data.Message);
    });
};

export const fetchUserReadingsCount = (
  loggedInUser: any,
  groupId: string,
  startDate: string | null,
  endDate: string | null
) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${loggedInUser.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  let URL = `${URL_LOCATION}/api/Measurement/ListUserReadingsCount/${groupId}?`;
  if (endDate !== null) URL += `&startDate=${startDate}&endDate=${endDate}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    //
    return response.data;
  });
};

export const fetchLatestUserReadings = (loggedInUser: any, userId: string) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${loggedInUser.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  //const jwtDecoded :any = getDecodedToken(`${loggedInUser.signInUserSession.accessToken.jwtToken}`) ;
  const URL = `${URL_LOCATION}/api/Measurement/LatestUserReadings/` + userId;
  return axios({
    url: URL,
    method: "get",
  })
    .then((response) => {
      //
      return response.data;
    })
    .catch((err) => {
      throw new Error(err.response.data.Message);
    });
};

export const fetchReadingCountWithMethod = (
  loggedInUser: any,
  groupId: string,
  startDate: string | null,
  endDate: string | null
) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${loggedInUser.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  let URL = `${URL_LOCATION}/api/Measurement/readingscountwithmethod/${groupId}?`;
  if (endDate !== null) URL += `&startDate=${startDate}&endDate=${endDate}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    //
    return response.data;
  });
};

export const fetchProviderTypes = (props: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "",
  };
  const jwtDecoded: any = getDecodedToken(
    `${props.signInUserSession.accessToken.jwtToken}`
  );
  const URL = `${URL_LOCATION}/api/CognitoUser/grouptypes`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

export const fetchUserMeasurement = (props: any, userID: string) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "",
  };
  const jwtDecoded: any = getDecodedToken(
    `${props.signInUserSession.accessToken.jwtToken}`
  );
  const URL = `${URL_LOCATION}/api/cognitouser/${userID}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

export const fetchCycle = (props: any, provider: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "",
  };
  const jwtDecoded: any = getDecodedToken(
    `${props.signInUserSession.accessToken.jwtToken}`
  );
  const CancelToken = axios.CancelToken;
  let cancel: () => void;
  const URL = `${URL_LOCATION}/api/measurement/cycle/${provider}`;
  return axios({
    url: URL,
    method: "get",
    cancelToken: new CancelToken((c) => (cancel = c)),
  }).then((response) => {
    return response.data;
  });
};

export const fetchDashboardView = (props: any, provider: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "",
  };
  const jwtDecoded: any = getDecodedToken(
    `${props.signInUserSession.accessToken.jwtToken}`
  );
  const CancelToken = axios.CancelToken;
  let cancel: () => void;
  const URL = `${URL_LOCATION}/api/provider/dashboardview/${provider}`;
  return axios({
    url: URL,
    method: "get",
    cancelToken: new CancelToken((c) => (cancel = c)),
  }).then((response) => {
    return response.data;
  });
};

export const fetchBreachMeasurement = (props: any, provider: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "",
  };
  const jwtDecoded: any = getDecodedToken(
    `${props.signInUserSession.accessToken.jwtToken}`
  );
  const CancelToken = axios.CancelToken;
  let cancel: () => void;
  const URL = `${URL_LOCATION}/api/measurement/breaches/${provider}`;
  return axios({
    url: URL,
    method: "get",
    cancelToken: new CancelToken((c) => (cancel = c)),
  }).then((response) => {
    return response.data;
  });
};

export const updateBreachNote = (user: any, data: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };

  const URL = `${URL_LOCATION}/api/measurement/updatebreach`;

  return axios({
    url: URL,

    method: "put",

    data: data,
  }).then((response) => {
    return response;
  });
};

export const fetchProviders = (props: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "",
  };
  const jwtDecoded: any = getDecodedToken(
    `${props.signInUserSession.accessToken.jwtToken}`
  );

  const URL = `${URL_LOCATION}/api/CognitoUser/groups`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    return response.data;
  });
};


export const fetchProviderAndThreshold = (props: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "",
  };
  const jwtDecoded: any = getDecodedToken(
    `${props.signInUserSession.accessToken.jwtToken}`
  );

  const URL = `${URL_LOCATION}/api/CognitoUser/thresholdgroups`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    return response.data;
  });
};


export const updateThresholds = (user: any, data: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };

  const URL = `${URL_LOCATION}/api/provider/updatethreshold`;

  return axios({
    url: URL,
    method: "put",
    data: data,
  }).then((response) => {
    return response;
  });
};

export const updateUserThresholds = (user: any, data: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };

  const URL = `${URL_LOCATION}/api/cognitouser/updatethreshold`;

  return axios({
    url: URL,
    method: "put",
    data: data,
  }).then((response) => {
    return response;
  });
};

export const updateSelectedUserThresholds = (user: any, data: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,

    "Access-Control-Allow-Origin": "*",
  };

  const URL = `${URL_LOCATION}/api/cognitouser`;

  return axios({
    url: URL,

    method: "put",

    data: data,
  }).then((response) => {
    return response;
  });
};

export const addProvider = (user: any, data: IProviderType) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  const URL = `${URL_LOCATION}/api/CognitoUser/addgroup`;
  return axios({
    url: URL,
    method: "post",
    data: data,
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw new Error(err.response.data.Message);
    });
};

export const updateProvider = (user: any, data: IProvider) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  const URL = `${URL_LOCATION}/api/CognitoUser/updategroup`;
  return axios({
    url: URL,
    method: "put",
    data: data,
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const activateProvider = (user: any, data: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  const URL = `${URL_LOCATION}/api/CognitoUser/activategroup/${data}`;
  return axios({
    url: URL,
    method: "put",
    data: data,
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const deActivateProvider = (user: any, data: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  const URL = `${URL_LOCATION}/api/CognitoUser/deactivategroup/${data}`;
  return axios({
    url: URL,
    method: "delete",
    data: data,
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const fetchGroups = (props: any, pageSize: number, pageNum: number) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "",
  };
  const jwtDecoded: any = getDecodedToken(
    `${props.signInUserSession.accessToken.jwtToken}`
  );
  const URL =
    `${URL_LOCATION}/api/CognitoUser/ListGroupsAuthenticated?pageNumber=` +
    pageNum +
    `&pageSize=` +
    pageSize;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    //
    return response.data;
  });
};

export const downloadGroupPdf = (
  loggedInUser: any,
  groupId: string,
  startDate: string | null,
  endDate: string | null,
  sortKey: string | null,
  sortDirection: number | null,
  searchString: string | null,
  measurementTypes: string | null,
  readingType: string | null
) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${loggedInUser.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  let URL = `${URL_LOCATION}/api/Report/GroupReadings/${groupId}?`;
  if (endDate !== null) URL += `&startDate=${startDate}&endDate=${endDate}`;
  if (endDate !== null) URL += `&startDate=${startDate}&endDate=${endDate}`;
  if (searchString !== null) URL += `&searchString=${searchString}`;
  if (sortKey !== null)
    URL += `&sortKey=${sortKey}&sortDirection=${sortDirection}`;
  if (measurementTypes !== null && measurementTypes !== "")
    URL += `&measurementTypes=${measurementTypes}`;
  if (readingType !== null && readingType !== "")
    URL += `&readingType=${readingType}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    //
    return response.data;
  });
};

export const downloadUserPdf = (
  loggedInUser: any,
  userId: string,
  startDate: string | null,
  endDate: string | null,
  sortKey: string | null,
  sortDirection: number | null,
  searchString: string | null,
  measurementTypes: string | null
) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${loggedInUser.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  let URL = `${URL_LOCATION}/api/Report/UserReadings/${userId}?`;
  if (endDate !== null) URL += `&startDate=${startDate}&endDate=${endDate}`;
  if (searchString !== null) URL += `&searchString=${searchString}`;
  if (sortKey !== null)
    URL += `&sortKey=${sortKey}&sortDirection=${sortDirection}`;
  if (measurementTypes !== null && measurementTypes !== "")
    URL += `&measurementTypes=${measurementTypes}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    //
    return response.data;
  });
};

export const downloadGroupXls = (
  loggedInUser: any,
  groupId: string,
  startDate: string | null,
  endDate: string | null,
  sortKey: string | null,
  sortDirection: number | null,
  searchString: string | null,
  measurementTypes: string | null,
  readingType: string | null
) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${loggedInUser.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  let URL = `${URL_LOCATION}/api/Report/GroupReadingsXls/${groupId}?`;
  if (endDate !== null) URL += `&startDate=${startDate}&endDate=${endDate}`;
  if (endDate !== null) URL += `&startDate=${startDate}&endDate=${endDate}`;
  if (searchString !== null) URL += `&searchString=${searchString}`;
  if (sortKey !== null)
    URL += `&sortKey=${sortKey}&sortDirection=${sortDirection}`;
  if (measurementTypes !== null && measurementTypes !== "")
    URL += `&measurementTypes=${measurementTypes}`;
  if (readingType !== null && readingType !== "")
    URL += `&readingType=${readingType}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    //
    return response.data;
  });
};

export const downloadUserXls = (
  loggedInUser: any,
  userId: string,
  startDate: string | null,
  endDate: string | null,
  sortKey: string | null,
  sortDirection: number | null,
  searchString: string | null,
  measurementTypes: string | null
) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${loggedInUser.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  let URL = `${URL_LOCATION}/api/Report/UserReadingsXls/${userId}?`;
  if (endDate !== null) URL += `&startDate=${startDate}&endDate=${endDate}`;
  if (searchString !== null) URL += `&searchString=${searchString}`;
  if (sortKey !== null)
    URL += `&sortKey=${sortKey}&sortDirection=${sortDirection}`;
  if (measurementTypes !== null && measurementTypes !== "")
    URL += `&measurementTypes=${measurementTypes}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    //
    return response.data;
  });
};

export const fetchAllDashboardItems = (loggedInUser: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${loggedInUser.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  let URL = `${URL_LOCATION}/api/provider/dashboardcomponents`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    //
    return response.data;
  });
};

export const fetchProviderDashboardItems = (
  loggedInUser: any,
  groupId: string
) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${loggedInUser.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  //console.log(groupId);
  let URL = `${URL_LOCATION}/api/provider/dashboardcomponents/${groupId}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    //
    return response.data;
  });
};

export const fetchUserDashboardItems = (loggedInUser: any, userId: string) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${loggedInUser.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  let URL = `${URL_LOCATION}/api/provider/dashboardcomponents/${userId}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    //
    return response.data;
  });
};

export const fetchRewardsCount = (
  loggedInUser: any,
  groupId: string,
  count: any
) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${loggedInUser.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  let URL = `${URL_LOCATION}/api/provider/rewards/${groupId}?`;
  if (count !== null) URL += `&count=${count}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    //
    return response.data;
  });
};

export const sendNotification = (user: any, data: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data"
  };
  const URL = `${URL_LOCATION}/api/notification/sendNotification`;
  return axios({
    url: URL,
    method: "post",
    data: data,
  }).then((response) => {
    return response;
  });
};

export const deleteNotification = (props: any, data: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  const URL = `${URL_LOCATION}/api/notification/deleteSchedule`;
  return axios({
    url: URL,
    method: "delete",
    data: data
  }).then((response) => {
    return response;
  });
};

export const deleteSingleNotification = (props: any, data: any, jobID: string) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  const URL = `${URL_LOCATION}/api/notification/deleteSingleSchedule/${jobID}`;
  return axios({
    url: URL,
    method: "delete",
    data: data
  }).then((response) => {
    return response;
  });
};

export const getSchedules = (props: any, provider: any, data: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "",
  };
  const jwtDecoded: any = getDecodedToken(
    `${props.signInUserSession.accessToken.jwtToken}`
  );
  const URL = `${URL_LOCATION}/api/notification/getSchedules/${data?.data?.id}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    return response.data;
  });
}
export const getScheduledRuleNotifications = (props: any, provider: any, pageSize: number, pageNum: number) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "",
  };
  const jwtDecoded: any = getDecodedToken(
    `${props.signInUserSession.accessToken.jwtToken}`
  );
  const URL = `${URL_LOCATION}/api/notification/getScheduldRule/${provider}?page=` + pageNum + `&pageSize=` + pageSize;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    return response.data;
  });
}

export const getPendingNotifications = (props: any, provider: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "",
  };
  const jwtDecoded: any = getDecodedToken(
    `${props.signInUserSession.accessToken.jwtToken}`
  );
  const URL = `${URL_LOCATION}/api/notification/getPendingSchedules`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    return response.data;
  });
}

export const getCompletedNotifications = (props: any, provider: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  const jwtDecoded: any = getDecodedToken(
    `${props.signInUserSession.accessToken.jwtToken}`
  );
  const URL = `${URL_LOCATION}/api/notification/getCompletedSchedules/${props.username}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    return response.data;
  });
}

export const getfileManager = async (props: any, fileName: string) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
    "Accept": '*/*'
  };

  const URL = `${URL_LOCATION}/api/filemanager/sharefile/?fileName=${fileName}`;

  const response = await axios({
    url: URL,
    method: "get",
  });
  return response;
}

export const createGroups = (user: any, data: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data"
  };
  const URL = `${URL_LOCATION}/api/group/createGroups`;
  return axios({
    url: URL,
    method: "post",
    data: data,
  }).then((response) => {
    return response;
  });
};

export const addGroupusers = (user: any, data: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data"
  };
  const URL = `${URL_LOCATION}/api/group/addGroupUsers`;
  return axios({
    url: URL,
    method: "post",
    data: data,
  }).then((response) => {
    return response;
  });
};

export const getProviderGroups = (user: any, provider: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${user.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "",
  };
  const jwtDecoded: any = getDecodedToken(
    `${user.signInUserSession.accessToken.jwtToken}`
  );
  const URL = `${URL_LOCATION}/api/group/getProviderGroups/${provider}`;
  return axios({
    url: URL,
    method: "get",
  }).then((response) => {
    return response.data;
  });
}

export const deleteProviderGroup = (props: any, data: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  const URL = `${URL_LOCATION}/api/group/deleteGroups`
  return axios({
    url: URL,
    method: "delete",
    data: data
  }).then((response) => {
    return response;
  });
};

export const deleteGroupUser = (props: any, data: any, provider: any) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${props.signInUserSession.accessToken.jwtToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  const URL = `${URL_LOCATION}/api/group/deleteGroupUser/${provider}`
  return axios({
    url: URL,
    method: "delete",
    data: data
  }).then((response) => {
    return response;
  });
};

export const FileManagerURL = {
  AzureFileOperations: URL_LOCATION + "/api/FileManager/AzureFileOperations",
  AzureGetImage: URL_LOCATION + "/api/FileManager/AzureGetImage",
  AzureUpload: URL_LOCATION + "/api/FileManager/AzureUpload",
  AzureDownload: URL_LOCATION + "/api/FileManager/AzureDownload",
}


