import {
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonItem,
  IonCardContent,
} from "@ionic/react";
import { lazy, Suspense, useState } from "react";
import React, { useContext, useEffect } from "react";
import { ApplicationContext, ProviderContext } from "../../ApplicationState";
import { ComponentLayout } from "../../types";
//import BreachThreshold from "../BreachThreshold/BreachThreshold";
import { useBreachThreshold } from "../BreachThreshold/useBreachThreshold";
import GroupedReadingMethodChart, {
  GroupedReadingMethodChartProps,
} from "../Charts/GroupedReadingMethodChart";
import TotalReadingsPieChart from "../Charts/TotalReadingsPieChart";
import UsersCountChart from "../Charts/UsersCountChart";
//import GroupReadingsComponent from "../GroupReadingsComponent";
//import UsersComponent from "../UsersComponent";
import * as Helpers from "../../services/Helpers";
//import CycleDaysActivity from "../CycleDaysActivity/CycleDaysActivity";
//import RewardsProgram from "../RewardsProgram/RewardsProgram";
import moment from "moment";
import { Box } from "@mui/system";
import styled from "styled-components";

const LazyLoadBreachComponent = lazy(
  () => import("../BreachThreshold/BreachThreshold")
);
const LazyLoadCycleDaysActivityComponent = lazy(
  () => import("../CycleDaysActivity/CycleDaysActivity")
);

const LazyLoadUsersComponent = lazy(() => import("../UsersComponent"));

const LazyLoadGroupReadingsComponent = lazy(
  () => import("../GroupReadingsComponent")
);

const LazyLoadRewardsProgramComponent = lazy(
  () => import("../RewardsProgram/RewardsProgram")
);

const LazyLoadScheduleMessagesComponent = lazy(
  () => import("../ScheduleMessages/ScheduleMessages")
);

interface Props {
  componentLayout: any;
  render: any;
}

const LayoutItem: React.FC<Props> = ({ componentLayout, render }) => {
  const { userState } = useContext(ApplicationContext);
  const { providerState } = useContext(ProviderContext);
  const { row, columns, deleteRow } = useBreachThreshold();
  const dateFormatString = "YYYY-MM-DD";
  const groupedReadingMethodChart = {
    startDate: moment(new Date(), dateFormatString).add(-1, "months"),
    endDate: moment(new Date(), dateFormatString),
  };

  useEffect((): any => {
    let isSubscribed = false;
    if (!isSubscribed) {
    }
    return () => (isSubscribed = true);
  });

  return (
    <Box sx={{ flex: 1 }}>
      {componentLayout.component ? (
        componentLayout.component.name == "Breaches" ? (
          <Suspense fallback={<p>loading...</p>}>
            <LazyLoadBreachComponent
              row={row}
              column={columns}
              render={render}
              loading={true}
            />
          </Suspense>
        ) : componentLayout.component.name == "BillingCycle" ? (
          <Suspense fallback={<p>loading...</p>}>
            <LazyLoadCycleDaysActivityComponent />
          </Suspense>
        ) : componentLayout.component.name == "LatestTenReadings" ? (
          <Suspense fallback={<p>loading...</p>}>
            <LazyLoadGroupReadingsComponent type="Group" />
          </Suspense>
        ) : componentLayout.component.name == "RewardProgram" ? (
          <Suspense fallback={<p>loading...</p>}>
            <LazyLoadRewardsProgramComponent />
          </Suspense>
        ) : componentLayout.component.name == "ScheduleMessages" ? (
          <Suspense fallback={<p>loading...</p>}>
            <LazyLoadScheduleMessagesComponent />
          </Suspense>
        ) :
          componentLayout.component.name == "Charts" ? (
            <IonRow className="row align-top">
              <IonCol className="col-12">
                <IonCard>
                  <IonCardHeader>
                    <IonItem>
                      {Helpers.getDisplayLabel(providerState)} per provider
                    </IonItem>
                  </IonCardHeader>
                  <IonCardContent>
                    <UsersCountChart />
                  </IonCardContent>
                </IonCard>
              </IonCol>

              <IonCol className="col-12">
                <IonCard>
                  <IonCardHeader>
                    <IonItem>Readings count (last 30 days)</IonItem>
                  </IonCardHeader>
                  <IonCardContent>
                    <TotalReadingsPieChart
                      groupId={providerState.providerId}
                      type="Group"
                    />
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol className="col-12">
                <IonCard>
                  <IonCardHeader>
                    <IonItem>Manual vs Automated (last 30 days)</IonItem>
                  </IonCardHeader>
                  <IonCardContent>
                    <GroupedReadingMethodChart
                      startDate={groupedReadingMethodChart.startDate}
                      endDate={groupedReadingMethodChart.endDate}
                    />
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          ) : componentLayout.component.name == "PatientList" ? (
            <Suspense fallback={<p>loading...</p>}>
              <LazyLoadUsersComponent type="Patients" />
            </Suspense>
          ) : (
            componentLayout.component.name
          )
      ) : (
        componentLayout.i
      )}
    </Box>
  );
};

export default LayoutItem;
