import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonPage,
  IonRow,
  IonCol,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonGrid,
  IonSelect,
  IonSelectOption,
  IonBackButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  NavContext,
  IonIcon,
  IonCheckbox,
  IonLoading,
} from "@ionic/react";
import { ApplicationContext, ProviderContext } from "../../ApplicationState";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./provider.css";
import * as ApiService from "../../services/ApiService";
import * as Helper from "../../services/Helpers";
import { useForm, Controller } from "react-hook-form";
import { IUserProps } from "../../models/user";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { arrowBackCircleSharp } from "ionicons/icons";
import {
  IDashboardItems,
  IProvider,
  IProviderType,
} from "../../models/provider";
import { Redirect, useHistory, useLocation } from "react-router";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Checkbox, CircularProgress, Input, List, ListItem, ListItemText, MobileStepper, Modal, Paper, Popover, Step, StepLabel, Stepper, TextareaAutosize, TextField, Typography } from "@mui/material";
import { APP_STATE_NAME } from "../../context/layout-context";
import { getUserAttributes } from "../../services/Helpers";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DataGrid, GridColDef } from "@mui/x-data-grid";

var _ = require("lodash");

interface UserDataType {
  id: string;
  name: string;
  phone: number;
}

const dataArray: UserDataType = {
  name: '',
  phone: 0,
  id: "",
}

let initialValues: IProvider = {};

const dashboardItems = [
  "Breaches",
  "PatientList",
  "Charts",
  "Latest Ten Readings",
  "BillingCycle",
  "Reward Program",
  "Schedule Messages"
];
const readingEntry = ["Automatic", "Manual", "Automatic and Manual"];
const steps = ['Update Provider', 'Select Dashboard Items', 'Add Groups to Provider'];


const Provider: React.FC<any> = (props) => {
  const { userState, setUserState } = useContext(ApplicationContext);
  const [isProvider, setIsProvider] = React.useState(false);
  const { goBack } = useContext(NavContext);
  const location: any = useLocation();
  const history: any = useHistory();
  const [data, setData] = useState();
  const { providerState } = useContext(ProviderContext);
  const [phone, setPhoneValue] = useState();
  const [providerTypes, setTypes] = useState([]);
  const [providerId, setProviderId] = useState();
  const [medlineCustomerNumber, setMedlineCustomerNumber] = useState();
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = React.useState([dataArray]);
  const [userData, setUserData] = useState();
  const [pageSize, setPageSize] = useState<number>(200);

  const {
    register,
    control,
    handleSubmit,
    formState,
    reset,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: { ...initialValues },
    mode: "onChange",
  });

  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div style={{ color: "red", fontWeight: "bold" }}>
        {error.message || "Field Is Required"}
      </div>
    ) : null;
  };

  const [item, setCheckedItem] = useState(
    new Array(dashboardItems.length).fill({
      providerId: "",
      dashboardItemId: "",
    })
  );

  const [checkedState, setCheckedState] = useState(
    new Array(dashboardItems.length).fill(false)
  );

  const getProviderReadingMode = (readingEntry) => {
    if (readingEntry === 0) {
      return "Automatic";
    }

    if (readingEntry === 1) {
      return "Manual";
    }

    return "Automatic and Manual";
  };

  useEffect(() => {
    let user = userState.authenticatedUser;
    let provider = location.state.provider;
    var readingMode = getProviderReadingMode(
      provider?.configuration?.activeReadingMode
    )
    fetchProviderType();

    let attr = getUserAttributes(user.signInUserSession["idToken"].jwtToken);
    setIsProvider(attr?.roles[0] === "Provider");

    if (provider !== undefined) {
      setValue("id", provider.id, {});
      setValue("providerCode", provider.providerCode, { shouldValidate: true });
      setValue("providerName", provider.providerName, { shouldValidate: true });
      setValue("medlineCustomerNumber", provider.medlineCustomerNumber, {
        shouldValidate: true,
      });
      setValue("phone", provider.phone, { shouldValidate: false });
      setValue("email", provider.email, { shouldValidate: false });
      setValue("providerType", provider.providerType, {
        shouldValidate: false,
      });
      setValue(
        "configuration.daysInFirstPeriod",
        provider?.configuration?.daysInFirstPeriod,
        { shouldValidate: false }
      );
      setValue(
        "configuration.daysInSecondPeriod",
        provider?.configuration?.daysInSecondPeriod,
        { shouldValidate: false }
      );
      setValue(
        "configuration.daysInThirdPeriod",
        provider?.configuration?.daysInThirdPeriod,
        { shouldValidate: false }
      );
      setValue(
        "configuration.totalCycleDays",
        provider?.configuration?.totalCycleDays,
        { shouldValidate: false }
      );
      setValue("configuration.activeReadingMode", readingMode, {
        shouldValidate: false,
      });

      setProviderId(provider.id);
      setMedlineCustomerNumber(provider.medlineCustomerNumber);

      const filterItems = _.map(provider.dashboardItems, (d) => {
        checkedState[d.dashboardItemId - 1] = true;
        return checkedState;
      });

      setCheckedItem(provider.dashboardItems);
    }
  }, [props]);

  useEffect(() => {
    let pageNum = 1;

    ApiService.fetchUsers(
      userState.authenticatedUser,
      providerState.providerId,
      pageSize,
      pageNum,
      null,
      null,
      null,
      null,
      null,
      "all"
    ).then((data) => {

      if (data) {

        let rowId = 0
        setUserData(data)
        const userData = data.map((value) => {
          let attributes: any[] = value["attributes"];
          const { id, deviceId, deviceType } = value
          let fullName = (
            attributes.find((r) => r.name == "given_name").value +
            " " +
            attributes.find((r) => r.name == "family_name").value
          ).replace(/\b(\w)/g, (s) => s.toUpperCase());
          let email = attributes.find((r) => r.name == "email").value;
          let phone = attributes.find((r) => r.name == "phone_number").value;

          const thresholdObject: UserDataType = {
            name: fullName,
            phone: phone,
            id: id
          }
          rowId = rowId + 1
          return thresholdObject
        })
        setUsers(userData)

      }

    }).catch(error => {

      alert('OOps something went wrong. Please try again.')
    })

  }, [providerState])

  const fetchProviderType: any = () => {
    ApiService.fetchProviderTypes(userState.authenticatedUser).then((data) => {
      if (data) {
        setTypes(data);
      }
    });
  };

  const compareWith = (o1: IProviderType, o2: IProviderType) => {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  };

  const getActiveReadingMode = (readingEntry: any) => {
    if (readingEntry === "Automatic") {
      return 0;
    }

    if (readingEntry === "Manual") {
      return 1;
    }
    return 2;
  };

  const onSubmit = (data) => {
    let provider = location.state.provider;
    var activeReadingMode = getActiveReadingMode(
      data.configuration.activeReadingMode
    )
    try {
      if (data.id === undefined) {
        setLoading(true);
        const providerConfigdata = {
          ...data.configuration,
          activeReadingMode: activeReadingMode,
        };

        const dataToSend = {
          ...data,
          configuration: providerConfigdata,
          dashboardItems: item,
        };

        ApiService.addProvider(userState.authenticatedUser, dataToSend)
          .then((result) => {
            setLoading(false);
            if (result) {
              alert("Added Sucessfully!");
              history.push("/providers");
            }
          })
          .catch((error) => {
            setLoading(false);
            alert(error.message);
            goBack();
          });
      } else {
        setLoading(true);
        const providerConfigdata = {
          ...data.configuration,
          id:
            provider.configuration === null ? null : provider.configuration?.id,
          totalCycleDays: totalCycle,
          activeReadingMode: activeReadingMode,
        };

        const dataToSend = {
          ...data,
          configuration: providerConfigdata,
          dashboardItems: item,
        };

        ApiService.updateProvider(userState.authenticatedUser, dataToSend)
          .then((result) => {
            setLoading(false);
            if (result) {
              localStorage.removeItem(
                APP_STATE_NAME + "_" + userState.authenticatedUser.username
              );
              alert("Updated Sucessfully!");
              history.push("/providers");
            }
          })
          .catch((error) => {
            setLoading(false);
            alert(error.message);
            goBack();
          });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  function onDeactivateProvider(a: any): void {
    let user = userState.authenticatedUser;
    let toUpdate = location.state.provider.id;
    ApiService.deActivateProvider(user, toUpdate)
      .then((response) => {
        alert("provider deactivated successfully");
        goBack();
      })
      .catch((err) => {
        alert(err);
      });
  }

  function onActivateProvider(a: any): void {
    let user = userState.authenticatedUser;
    let toUpdate = location.state.provider.id;
    ApiService.activateProvider(user, toUpdate)
      .then((response) => {
        //
        alert("provider activated successfully");
        goBack();
      })
      .catch((err) => {
        alert(err);
      });
  }

  let totalCycle = 0;
  const firstDay: string = getValues("configuration.daysInFirstPeriod");
  const secondDay: string = getValues("configuration.daysInSecondPeriod");
  const thirdDay: string = getValues("configuration.daysInThirdPeriod");

  const watchFirst = watch("configuration.daysInFirstPeriod");
  const watchSecond = watch("configuration.daysInSecondPeriod");
  const watchThird = watch("configuration.daysInThirdPeriod");

  const calc = (firstDay, secondDay, thirdDay) => {
    totalCycle = parseInt(firstDay) + parseInt(secondDay) + parseInt(thirdDay);
    setValue("configuration.totalCycleDays", totalCycle);
  };

  if (firstDay && secondDay && thirdDay) {
    calc(firstDay, secondDay, thirdDay);
  }

  const validate = (value: string) => {
    const matches = value.match(
      /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
    );
    return matches?.length > 0 || "Not a Number";
  };

  const handleOnChange = (position, name) => {
    let provider = location.state.provider;

    const updatedCheckedState = checkedState.map((item, index) => {
      const isChecked = index === position ? !item : item;
      return isChecked;
    });

    var checkedItem = _.map(updatedCheckedState, (checked, index) => {
      return checked
        ? {
          providerId: provider.id,
          dashboardItemId: index + 1,
          provider: provider,
          dashboardItem: null,
        }
        : null;
    });

    var filterItem = _.filter(checkedItem, (d) => {
      return d !== null;
    });

    setCheckedState(updatedCheckedState);
    setCheckedItem(filterItem);
  };

  return (
    <IonPage>
      <Header />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text="Go Back"
              icon={arrowBackCircleSharp}
              defaultHref="/"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonItem>
                    <IonLabel>
                      {location?.state?.provider?.id === undefined
                        ? "Create New"
                        : "Update"}{" "}
                      Provider
                    </IonLabel>
                  </IonItem>
                  <IonItem hidden={location?.state?.provider?.id === undefined}>
                    {location.state?.provider?.isActive ? (
                      <IonButton
                        slot="end"
                        onClick={(a) => onDeactivateProvider(a)}
                      >
                        Deactivate
                      </IonButton>
                    ) : (
                      <IonButton
                        slot="end"
                        onClick={(a) => onActivateProvider(a)}
                      >
                        Activate
                      </IonButton>
                    )}
                  </IonItem>
                </IonCardHeader>
                <IonCardContent>


                  <IonRow>

                    <IonCol sizeXs="12" sizeSm="12" sizeLg="6" sizeMd="6">
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ padding: 18 }}
                      >
                        <Controller
                          render={({ onChange, onBlur, value }) => (
                            <IonInput
                              onIonChange={onChange}
                              slot="end"
                              hidden={true}
                              value={value}
                            />
                          )}
                          control={control}
                          name="id"
                          onChange={(e) => setValue("id", e.target.value)}
                          hidden={true}
                        />

                        <IonItem>
                          <IonLabel>Provider Name</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <IonInput
                                onIonChange={onChange}
                                slot="end"
                                value={value}
                              />
                            )}
                            control={control}
                            name="providerName"
                            onChange={(e) =>
                              setValue("providerName", e.target.value)
                            }
                            rules={{
                              required: true,
                            }}
                          />
                        </IonItem>
                        {showError("providerName")}
                        <IonItem>
                          <IonLabel>Provider Code</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <IonInput
                                onIonChange={onChange}
                                slot="end"
                                disabled={providerId !== undefined}
                                value={value}
                              />
                            )}
                            control={control}
                            name="providerCode"
                            onChange={(e) =>
                              setValue("providerCode", e.target.value)
                            }
                            rules={{
                              required: true,
                            }}
                          />
                        </IonItem>
                        {showError("providerCode")}

                        <IonItem>
                          <IonLabel>Medline Customer Number</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <IonInput
                                onIonChange={onChange}
                                slot="end"
                                value={value}
                                disabled={isProvider}
                              />
                            )}
                            control={control}
                            name="medlineCustomerNumber"
                            onChange={(e) =>
                              setValue("medlineCustomerNumber", e.target.value)
                            }
                            rules={{
                              required: true,
                            }}
                          />
                        </IonItem>
                        {showError("medlineCustomerNumber")}
                        <IonItem>
                          <IonLabel> Email </IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <IonInput
                                onIonChange={onChange}
                                slot="end"
                                value={value}
                              />
                            )}
                            control={control}
                            name="email"
                            onChange={(e) => setValue("email", e.target.value)}
                            rules={{
                              required: false,
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address",
                              },
                            }}
                          />
                        </IonItem>
                        {showError("email")}
                        <IonItem>
                          <IonLabel>Phone</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value, name }) => (
                              <PhoneInput
                                international
                                placeholder="Enter phone number"
                                value={value}
                                defaultCountry="US"
                                onChange={onChange}
                                name={name}
                                slot="end"
                              />
                            )}
                            control={control}
                            name="phone"
                            onChange={(e) => setValue("phone", e.target.value)}
                            rules={{
                              required: false,
                            }}
                          />
                        </IonItem>
                        {showError("phone")}

                        <IonItem>
                          <IonLabel>Days In First Period</IonLabel>

                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <IonInput
                                onIonChange={onChange}
                                slot="end"
                                value={value}
                              />
                            )}
                            control={control}
                            name="configuration.daysInFirstPeriod"
                            onChange={(e) =>
                              setValue(
                                "configuration.daysInFirstPeriod",
                                e.target.value
                              )
                            }
                            rules={{
                              required: firstDay === "" ? true : false,
                            }}
                          />
                        </IonItem>

                        <IonItem>
                          <IonLabel>Days In Second Period</IonLabel>

                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <IonInput
                                onIonChange={onChange}
                                slot="end"
                                value={value}
                              />
                            )}
                            control={control}
                            name="configuration.daysInSecondPeriod"
                            onChange={(e) =>
                              setValue(
                                "configuration.daysInSecondPeriod",
                                e.target.value
                              )
                            }
                            rules={{
                              required: secondDay === "" ? true : false,
                            }}
                          />
                        </IonItem>

                        <IonItem>
                          <IonLabel>Days In Third Period</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <IonInput
                                onIonChange={onChange}
                                slot="end"
                                value={value}
                              />
                            )}
                            control={control}
                            name="configuration.daysInThirdPeriod"
                            onChange={(e) =>
                              setValue(
                                "configuration.daysInThirdPeriod",
                                e.target.value
                              )
                            }
                            rules={{
                              required: thirdDay === "" ? true : false,
                            }}
                          />
                        </IonItem>

                        <IonItem>
                          <IonLabel style={{ color: "black" }}>
                            Total Cycle Days
                          </IonLabel>
                          <IonInput
                            name="configuration.totalCycleDays"
                            slot="end"
                            disabled={true}
                            value={totalCycle}
                          />
                        </IonItem>

                        <IonItem>
                          <IonLabel>Provider Types</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <IonSelect
                                compareWith={compareWith}
                                value={value}
                                placeholder="Select One"
                                onIonChange={onChange}
                                slot="end"
                                disabled={isProvider}
                              >
                                {providerTypes?.map((type) => (
                                  <IonSelectOption key={type.id} value={type}>
                                    {type.name}
                                  </IonSelectOption>
                                ))}
                              </IonSelect>
                            )}
                            control={control}
                            name="providerType"
                            onChange={(e) =>
                              setValue("providerType", e.target.value)
                            }
                          //rules={{ required: true }}
                          />
                        </IonItem>

                        {showError("providerType")}

                        <IonItem>
                          <IonLabel>Reading Entry Types</IonLabel>
                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <IonSelect
                                class="select-text"
                                value={value}
                                placeholder="Select One"
                                onIonChange={onChange}
                                slot="end"
                              >
                                {readingEntry?.map((type, index) => (
                                  <IonSelectOption key={index} value={type}>
                                    {type}
                                  </IonSelectOption>
                                ))}
                              </IonSelect>
                            )}
                            control={control}
                            name="configuration.activeReadingMode"
                            onChange={(e) =>
                              setValue(
                                "configuration.activeReadingMode",
                                e.target.value
                              )
                            }
                          //rules={{ required: true }}
                          />
                        </IonItem>

                        <IonButton
                          type="submit"
                          disabled={formState.isValid === false}
                        >
                          submit
                        </IonButton>

                      </form>
                    </IonCol>

                    <IonCardContent>
                      <h1 style={{ color: "black", fontWeight: "bold" }}>
                        Select Dashboard Items
                      </h1>
                      <IonCol>
                        {_.map(dashboardItems, (name, index) => {
                          return (
                            <IonItem>
                              <IonCheckbox
                                checked={checkedState[index]}
                                name={name}
                                slot="start"
                                onIonChange={() => handleOnChange(index, name)}
                              ></IonCheckbox>
                              <IonLabel key={index}>{name}</IonLabel>
                            </IonItem>
                          )
                        })}
                      </IonCol>
                    </IonCardContent>
                  </IonRow>

                  <IonLoading
                    cssClass="my-custom-class"
                    isOpen={loading}
                    onDidDismiss={() => setLoading(false)}
                    message={"Please wait..."}
                    duration={5000}
                  />
                </IonCardContent>

              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage >
  );
};

export default Provider