import React, { useContext, useEffect, useState } from "react";
import {
    IonButtons,
    IonHeader,
    IonToolbar,
    IonPage,
    IonContent,
    IonBackButton,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonLoading,
    useIonLoading
} from "@ionic/react";
import * as ApiService from '../../services/ApiService'

import Header from "../../components/Header/Header";
import { addCircle, arrowBackCircleSharp } from "ionicons/icons";
import { useThreshold } from "../../components/UsersThreshold/useThreshold";
import { DefaultState, UserDefaultState } from "./ThresholdModel";
import { DefaultThreshold } from "../../components/UsersThreshold/DefaultThreshold";
import { dataArray, UserDefaultThreshold } from "../../components/UsersThreshold/UserDefaultThreshold";
import { formValidation } from "../../components/FormField/FormValidation";
import { format } from "date-fns";
import { ProviderContext } from "../../ApplicationState";

var _ = require('lodash');

const Threshold: React.FC = (props: any) => {
    const { providers, load, userState, providerData } = useThreshold()

    const [selectedDefaults, setSelectedDefaults] = useState('Defaults')

    const [inputChange, setInputChange] = useState(false);
    const [present, dismiss] = useIonLoading();
    const [users, setUsers] = React.useState([dataArray]);
    const { providerState } = useContext(ProviderContext);
    const [pageSize, setPageSize] = useState<number>(200);
    const [usergroupData, setUsergroupData] = useState([]);

    const [fieldDisabled, setFieldDisabled] = useState(true)
    const [defaultValues, setDefaultValues] = React.useState<DefaultState>({
        bloodPressureSystolicMin: '',
        bloodPressureSystolicMax: '',
        bloodPressureDiastolicMin: '',
        bloodPressureDiastolicMax: '',
        weightMin: '',
        weightMax: '',
        temperatureMin: '',
        temperatureMax: '',
        oxygenMin: '',
        oxygenMax: '',
        bloodGlucoseMin: '',
        bloodGlucoseMax: '',
        bloodSaturation: '',
        pulseMin: '',
        pulseMax: ''
    });

    const [userdefaultValues, setUserDefaultValues] = React.useState<UserDefaultState>({
        bloodPressureSystolicMin: '',
        bloodPressureSystolicMax: '',
        bloodPressureDiastolicMin: '',
        bloodPressureDiastolicMax: '',
        weightMin: '',
        weightMax: '',
        temperatureMin: '',
        temperatureMax: '',
        oxygenMin: '',
        oxygenMax: '',
        bloodGlucoseMin: '',
        bloodGlucoseMax: '',
        bloodSaturation: '',
        pulseMin: '',
        pulseMax: ''
    });

    let measurement = []
    useEffect(() => {
        if (providers) {
            const providerLength = providers.length === 1
            setThresholdValue(providers, providerLength)
        }

    }, [providers, setDefaultValues])

    useEffect(() => {
        getPatients()
        getGroups()
    }, [providers])

    const getPatients = async () => {
        let pageNum = 1
        await ApiService.fetchUsers(
            userState.authenticatedUser,
            providerState.providerId,
            pageSize,
            pageNum,
            null,
            null,
            null,
            null,
            null,
            "patients"
        ).then((data) => {
            if (data) {
                let rowId = 0
                const userData = data.map((value) => {
                    let attributes: any[] = value["attributes"];
                    const { id } = value
                    let fullName = (
                        attributes.find((r) => r.name == "given_name").value +
                        " " +
                        attributes.find((r) => r.name == "family_name").value
                    ).replace(/\b(\w)/g, (s) => s.toUpperCase());
                    let email = attributes.find((r) => r.name == "email").value;
                    let phone = attributes.find((r) => r.name == "phone_number").value;

                    const thresholdObject = {
                        id: rowId,
                        userid: id,
                        given_name: fullName,
                        email: email,
                        phone_number: phone,
                    }
                    rowId = rowId + 1
                    return thresholdObject
                })
                setUsers(userData)
            }
        }).catch(error => {
            console.log("🚀 ~ file: Threshold.tsx .then ~ error", error)
            //alert('OOps something went wrong. Please try again.')
        })
    }

    const getGroups = () => {
        ApiService.getProviderGroups(userState.authenticatedUser, providerState.id).then(res => {
            if (res) {
                let rowId = 0
                const grp = _.map(res, data => {
                    const groupsObj = {
                        id: rowId,
                        groupid: data.id,
                        groupName: data.groupName,
                        groupDescription: data.groupDescription,
                        usersCount: data.usersCount,
                        groupUsers: data.groupUsers
                    }
                    rowId = rowId + 1
                    return groupsObj
                })
                const sortgroup = _.orderBy(grp, 'asc')
                setUsergroupData(sortgroup)
            }
        }).catch(error => {
            alert('Issues getting the groups. Please try again.')
        })
    }

    const setThresholdValue = (threshold, providerLength) => {
        const diastolicMin = threshold.find(v => {
            return v.longName === 'Diastolic' && v.thresholdType === 0
        })
        const diastolicMax = threshold.find(v => {
            return v.longName === 'Diastolic' && v.thresholdType === 1
        })
        const systolicMin = threshold.find(v => {
            return v.longName === 'Systolic' && v.thresholdType === 0
        })
        const systolicMax = threshold.find(v => {
            return v.longName === 'Systolic' && v.thresholdType === 1
        })
        const WeightMin = threshold.find(v => {
            return v.longName === 'Weight' && v.thresholdType === 0
        })
        const WeightMax = threshold.find(v => {
            return v.longName === 'Weight' && v.thresholdType === 1
        })
        const TempMin = threshold.find(v => {
            return v.longName === 'Temperature' && v.thresholdType === 0
        })
        const TempMax = threshold.find(v => {
            return v.longName === 'Temperature' && v.thresholdType === 1
        })
        const OxygenMin = threshold.find(v => {
            return v.longName === 'Oxygen Saturation' && v.thresholdType === 0
        })
        const SugarMin = threshold.find(v => {
            return v.longName === 'Sugar' && v.thresholdType === 0
        })
        const SugarMax = threshold.find(v => {
            return v.longName === 'Sugar' && v.thresholdType === 1
        })
        const PulseMin = threshold.find(v => {
            return v.longName === 'PulseRate' && v.thresholdType === 0
        })
        const PulseMax = threshold.find(v => {
            return v.longName === 'PulseRate' && v.thresholdType === 1
        })
        setDefaultValues({
            bloodPressureSystolicMin: systolicMin !== undefined ? systolicMin.thresholdValue : '',
            bloodPressureSystolicMax: systolicMax !== undefined ? systolicMax.thresholdValue : '',
            bloodPressureDiastolicMin: diastolicMin !== undefined ? diastolicMin.thresholdValue : '',
            bloodPressureDiastolicMax: diastolicMax !== undefined ? diastolicMax.thresholdValue : '',
            weightMin: WeightMin !== undefined ? WeightMin.thresholdValue : '',
            weightMax: WeightMax !== undefined ? WeightMax.thresholdValue : '',
            temperatureMin: TempMin !== undefined ? TempMin.thresholdValue : '',
            temperatureMax: TempMax !== undefined ? TempMax.thresholdValue : '',
            oxygenMin: '',
            oxygenMax: '',
            bloodGlucoseMin: SugarMin !== undefined ? SugarMin.thresholdValue : '',
            bloodGlucoseMax: SugarMax !== undefined ? SugarMax.thresholdValue : '',
            bloodSaturation: OxygenMin !== undefined ? OxygenMin.thresholdValue : '',
            pulseMin: PulseMin !== undefined ? PulseMin.thresholdValue : '',
            pulseMax: PulseMax !== undefined ? PulseMax.thresholdValue : '',
        });
    }

    const handleDefaultChange = (prop: keyof DefaultState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const isMatch = formValidation(event)
        if (!isMatch) {
            return event.preventDefault
        }
        setInputChange(true)
        setDefaultValues({ ...defaultValues, [prop]: event.target.value });

    };

    const handleUserDefaultChange = (prop: keyof UserDefaultState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const isMatch = formValidation(event) //preventing alphabets check, only numbers allowed
        if (!isMatch) {
            return event.preventDefault
        }
        setUserDefaultValues({ ...userdefaultValues, [prop]: event.target.value });
    };

    const handleDefaultEditFields = () => {
        setFieldDisabled(false)
    }

    if (defaultValues) {
        if (providers) {
            const providerLength = providers.length === 1
            let tempMin = {}
            if (!providerLength) {
                const { thresholds } = providers[0]
                measurement = thresholds.map((value) => {
                    const { measurementTypePart, thresholdAlerts } = value
                    const fullName = userState.authenticatedUser.attributes.given_name + ' ' + userState.authenticatedUser.attributes.family_name
                    const date = new Date()
                    const updatedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
                    switch (measurementTypePart.longName) {
                        case 'Systolic':
                            if (value.thresholdType === 0) {
                                const systolicMin = { ...value, thresholdValue: defaultValues.bloodPressureSystolicMin, updatedBy: fullName, updatedOn: updatedDate }
                                return systolicMin
                            } else {
                                const systolicMAX = { ...value, thresholdValue: defaultValues.bloodPressureSystolicMax, updatedBy: fullName, updatedOn: updatedDate }
                                return systolicMAX
                            }
                        case 'Diastolic':
                            if (value.thresholdType === 0) {
                                const diastolicMin = { ...value, thresholdValue: defaultValues.bloodPressureDiastolicMin, updatedBy: fullName, updatedOn: updatedDate }
                                return diastolicMin
                            } else {
                                const diastolicMax = { ...value, thresholdValue: defaultValues.bloodPressureDiastolicMax, updatedBy: fullName, updatedOn: updatedDate }
                                return diastolicMax
                            }

                        case 'Sugar':
                            if (value.thresholdType === 0) {
                                const sugarMin = { ...value, thresholdValue: defaultValues.bloodGlucoseMin, updatedBy: fullName, updatedOn: updatedDate }
                                return sugarMin
                            } else {
                                const sugarMAX = { ...value, thresholdValue: defaultValues.bloodGlucoseMax, updatedBy: fullName, updatedOn: updatedDate }
                                return sugarMAX
                            }

                        case 'PulseRate':
                            if (value.thresholdType === 0) {
                                const pulseMin = { ...value, thresholdValue: defaultValues.pulseMin, updatedBy: fullName, updatedOn: updatedDate }
                                return pulseMin
                            } else {
                                const pulseMAX = { ...value, thresholdValue: defaultValues.pulseMax, updatedBy: fullName, updatedOn: updatedDate }
                                return pulseMAX
                            }

                        case 'Temperature':
                            const thresholdID = { ...thresholdAlerts[0], id: 0, providerThresholdId: 0, providerThresholdAlertId: 0 }
                            if (value.thresholdType === 0) {
                                const temperatureMin = { ...value, thresholdType: 0, thresholdValue: defaultValues.temperatureMin, updatedBy: fullName, updatedOn: updatedDate }
                                return temperatureMin
                            } else {
                                const temperatureMax = { ...value, thresholdType: 1, thresholdValue: defaultValues.temperatureMax, updatedBy: fullName, updatedOn: updatedDate }
                                tempMin = { ...value, id: 0, thresholdType: 0, thresholdAlerts: [thresholdID], thresholdValue: defaultValues.temperatureMin, updatedBy: fullName, updatedOn: updatedDate }
                                return temperatureMax
                            }

                        case 'Weight':
                            if (value.thresholdType === 0) {
                                const weightMin = { ...value, thresholdValue: defaultValues.weightMin, updatedBy: fullName, updatedOn: updatedDate }
                                return weightMin
                            } else {
                                const weightMAX = { ...value, thresholdValue: defaultValues.weightMax, updatedBy: fullName, updatedOn: updatedDate }
                                return weightMAX
                            }

                        case 'Oxygen Saturation':
                            if (value.thresholdType === 0) {
                                const oxygen = { ...value, thresholdValue: defaultValues.bloodSaturation, updatedBy: fullName, updatedOn: updatedDate }
                                return oxygen
                            }
                            break;
                        default:
                            break;
                    }
                })

                if (measurement.length === 12) {
                    measurement.push(tempMin)
                }
            }
        }
    }

    return (
        <IonPage>
            <Header />
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            text="Go Back"
                            icon={arrowBackCircleSharp}
                            defaultHref="/"
                        />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSegment value={selectedDefaults} mode="ios" style={{ height: 50, width: 500, margin: 10 }} onIonChange={e => {
                    setSelectedDefaults(e.detail.value)
                }
                }>
                    <IonSegmentButton value='Defaults'>
                        <IonLabel> Global Defaults</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value='User Defaults'>
                        <IonLabel>User Defaults</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                {selectedDefaults === 'Defaults' &&
                    <DefaultThreshold
                        defaultValues={defaultValues}
                        fieldDisabled={fieldDisabled}
                        handleDefaultChange={handleDefaultChange}
                        handleDefaultEditFields={handleDefaultEditFields}
                        setFieldDisabled={setFieldDisabled}
                        measurement={measurement}
                        userState={userState}
                        providerData={providerData}
                        inputChange={inputChange}
                        global={true}
                    >
                    </DefaultThreshold>
                }

                {
                    selectedDefaults === 'User Defaults' &&
                    <UserDefaultThreshold
                        userdefaultValues={userdefaultValues}
                        setUserDefaultValues={setUserDefaultValues}
                        handleUserDefaultChange={handleUserDefaultChange}
                        patients={users}
                        usergroupData={usergroupData}
                    ></UserDefaultThreshold>
                }
            </IonContent >
        </IonPage >
    );
};

export default Threshold;
