import React, { useContext, useEffect, useState } from "react";
import {
  IonPage,
  IonRow,
  IonCol,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonGrid,
  IonItem,
} from "@ionic/react";
import { Auth } from "aws-amplify";
import { ApplicationContext, ProviderContext } from "../ApplicationState";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "./Home.css";
import jwt_decode from "jwt-decode";
import UsersComponent from "../components/UsersComponent";
import TotalReadingsChart from "../components/Charts/TotalReadingsChart";
import DrawPieChart from "../components/Charts/UsersCountChart";
import GroupReadingsComponent from "../components/GroupReadingsComponent";
import DrawLineChart from "../components/Charts/MeasurementChart";
import MeasurementChart from "../components/Charts/MeasurementChart";
import UsersCountChart from "../components/Charts/UsersCountChart";
import * as Helpers from "../services/Helpers";
import TotalReadingsPieChart from "../components/Charts/TotalReadingsPieChart";
import GroupedReadingMethodChart from "../components/Charts/GroupedReadingMethodChart";
import { useBreachThreshold } from "../components/BreachThreshold/useBreachThreshold";
import CircularProgress from "@mui/material/CircularProgress";
import LayoutProvider from "../context/layout-context";
import { Route, Switch } from "react-router-dom";
import ConfigurationPage from "../components/Layout/ConfigurationPage";
import LayoutsPage from "../components/Layout/LayoutsPage";
import { fetchDashboardItems } from "../context/reducer/Layoutreducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../context/store";
import { State } from "ionicons/dist/types/stencil-public-runtime";
import { LoadingState } from "../types";
import { Stack } from "@mui/material";

const logout = () => {
  Auth.signOut();
};

const API_KEY = "<YOUR_API_KEY_HERE>";

const getDecodedAccessToken = (token: string) => {
  try {
    return jwt_decode(token);
  } catch (Error) {
    return null;
  }
};

const Home: React.FC<any> = (props) => {
  const { userState } = useContext(ApplicationContext);
  const { providerState } = useContext(ProviderContext);
  // const { row, columns, deleteRow } = useBreachThreshold();
  const status = useSelector((state: RootState) => state.layoutViewComponent);
  const dispatch: AppDispatch = useDispatch()

  useEffect(() => {
    const data = { userState, providerState }

    dispatch(fetchDashboardItems(data))
  }, [providerState])


  return (
    <IonPage>
      <Header />
      <IonContent>
        {/* <LayoutProvider> */}
        <div className="pt-5 container">
          <Switch>
            <Route path="/configuration/:id" component={ConfigurationPage} />
            <Route path="/" component={LayoutsPage} />
          </Switch>
        </div>
        {/* </LayoutProvider> */}
      </IonContent>

      {/* <Footer /> */}
    </IonPage>
  );
};

export default Home;


