import React, { useContext, useEffect } from "react";
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonPage,
  IonContent,
  IonBackButton,
  IonButton,
  IonIcon,
} from "@ionic/react";

import "./providers.css";
import Header from "../../components/Header/Header";
import { addCircle, arrowBackCircleSharp } from "ionicons/icons";
import ProvidersComponent from "../../components/ProvidersComponent";
import * as Helper from "../../services/Helpers";
import { ApplicationContext } from "../../ApplicationState";

const Providers: React.FC = (props: any) => {
  const { userState, setUserState } = useContext(ApplicationContext);
  const [userAttributes, setUserAttributes] = React.useState<any | undefined>(
    {}
  );
  useEffect(() => {
    let user = userState.authenticatedUser;
    let attr = Helper.getUserAttributes(
      user.signInUserSession["idToken"].jwtToken
    );
    setUserAttributes(attr);
  }, [userState]);

  return (
    <IonPage>
      <Header />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text="Go Back"
              icon={arrowBackCircleSharp}
              defaultHref="/"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <ProvidersComponent type="" />
      </IonContent>
    </IonPage>
  );
};

export default Providers;
