import { IonCard, IonCardContent, IonContent, IonLoading, IonPage, useIonLoading } from "@ionic/react"
import { Box, Button, createTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, TextField, ThemeProvider, Typography } from "@mui/material"
import MaterialTable from "material-table";
import { createRef, useContext, useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header"
import tableIcons from '../../components/Notifications/MaterialTableIcons'
import GroupsFields from "./GroupsFields";
import { GroupsUsers } from "./GroupsUsers";
import * as ApiService from "../../services/ApiService";
import { ApplicationContext, ProviderContext } from "../../ApplicationState";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Icon } from "@iconify/react";

var _ = require("lodash");

type Groups = {
    id: number;
    groupName: string;
    groupDescription: string;
    usersCount: number;
    groupUsers: UserDataType[];
}

interface UserDataType {
    id: string;
    name: string;
    phone: number;
    groupId?: string;
}

export const dataArray: UserDataType = {
    name: '',
    phone: 0,
    id: "",
    groupId: ''

}

let groupCount = 0

export const Groups = () => {

    const defaultMaterialTheme = createTheme();
    // const [groupArray, setGroupArray] = useState<Groups[]>([{ id: 0, name: '', desc: '', usersCount: 0, groupUsers: [] }])
    const [groupArray, setGroupArray] = useState([])
    const [openGroupModal, setopenGroupModal] = useState(false);
    const { userState } = useContext(ApplicationContext);
    const { providerState } = useContext(ProviderContext);
    const [pageSize, setPageSize] = useState<number>(200);
    const [users, setUsers] = useState([dataArray]); //Left side users in Add Users Modal
    const [originalUsers, setOriginalUsers] = useState([dataArray]);
    const [userData, setUserData] = useState();
    const [rowData, setRowData] = useState<Groups>()
    const [loading, setLoading] = useState(false);
    const [usergroupData, setUsergroupData] = useState([]);
    const [deletedGroupRow, setDeletedGroupRow] = useState([]);
    const [present, dismiss] = useIonLoading();
    const [open, setOpen] = useState(false);
    const [showLoading, setShowLoading] = useState({ value: false, count: 0 });
    const [right, setRight] = useState([]);
    const [addUsersError, setAddUsersError] = useState(false);

    const tableRef = useRef(null);

    useEffect(() => {
        showLoading.count === 0 && setShowLoading({ value: true, count: 1 })
        ApiService.getProviderGroups(userState.authenticatedUser, providerState.id).then(res => {
            setShowLoading({ value: false, count: 1 })
            if (res) {
                const grp = _.map(res, data => {
                    setUsergroupData(data.groupUsers)
                    return {
                        id: data.id,
                        groupName: data.groupName,
                        groupDescription: data.groupDescription,
                        usersCount: data.usersCount,
                        groupUsers: data.groupUsers
                    }
                })
                setGroupArray(grp)
            }
        }).catch(error => {
            setShowLoading({ value: false, count: 1 })
            alert('Issues getting the groups. Please try again.')
        })
    }, [providerState])

    useEffect(() => {
        let pageNum = 1;
        setLoading(true)
        ApiService.fetchUsers(
            userState.authenticatedUser,
            providerState.providerId,
            pageSize,
            pageNum,
            null,
            null,
            null,
            null,
            null,
            "patients"
        ).then((data) => {
            if (data) {
                let rowId = 0
                setUserData(data)
                const userData = data.map((value) => {
                    let attributes: any[] = value["attributes"];
                    const { id, deviceId, deviceType } = value
                    let fullName = (
                        attributes.find((r) => r.name == "given_name").value +
                        " " +
                        attributes.find((r) => r.name == "family_name").value
                    ).replace(/\b(\w)/g, (s) => s.toUpperCase());
                    let email = attributes.find((r) => r.name == "email").value;
                    let phone = attributes.find((r) => r.name == "phone_number").value;
                    const thresholdObject: UserDataType = {
                        name: fullName,
                        phone: phone,
                        id: id,
                    }
                    rowId = rowId + 1
                    return thresholdObject
                })
                setUsers(userData)
                setOriginalUsers(userData)
                setLoading(false)
            }
        }).catch(error => {
            setLoading(false)
            alert('OOps something went wrong. Please try again.')
        })

    }, [providerState])

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            width: 500,
            editable: false,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            width: 500,
            editable: false,
        },
        {
            field: "delete",
            headerName: "  ",
            width: 200,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div>
                        <Icon onClick={() => handleUserDelete(cellValues.row, false, 'parentView')} icon="ic:round-cancel" color="red" width="30" />
                    </div>
                );
            }
        },
    ]

    const groupsColumns = [
        {
            field: "groupName",
            title: "Group Name",
            width: "100%",
        },
        {
            field: "groupDescription",
            title: "Group Description",
            width: 0
        },
        {
            field: "usersCount",
            title: "Users",
        },
        {
            field: "details",
            title: "",
            render: rowData => {
                // if (_.isEmpty(rowData.name) && _.isEmpty(rowData.name)) {
                //     return
                // }
                return (
                    <Button onClick={() => {
                        setRowData(rowData)
                        if (!_.isEmpty(rowData.groupUsers)) {
                            setUsersInright(rowData)
                        } else {
                            setUsers(originalUsers)  // set original users from API call to leftusers in Modal 
                            setRight(rowData.groupUsers) // set empty in right side of Modal
                        }
                        setopenGroupModal(true)
                    }} variant="contained">Add Users</Button>
                )
            }
        },
        {
            field: "delete",
            title: "  ",
            width: 200,
            render: rowData => <Button variant="contained" onClick={() => handleGroupDelete(rowData)}>Delete</Button>
        },
    ];

    const setUsersInright = (rowData) => {
        const userInLeft = _.filter(originalUsers, u => {
            return !_.find(rowData.groupUsers, r => {
                return r.id === u.id
            })
        })
        setRight(rowData.groupUsers)
        setUsers(userInLeft)
    }

    const deleteUsers = (row) => {

    }

    const handleUserDelete = (cellValues, fromGroup, name) => {
        setLoading(true)
        ApiService.deleteGroupUser(userState.authenticatedUser, cellValues, providerState.id).then((res) => {
            // console.log('response data after delete ID =====>', res.data.groupID)
            //console.log("🚀 ~ totaldata ~ groupArray:----->", groupArray)

            setLoading(false)
            if (res.status === 200) {
                // const grp = _.map(res, data => {
                //     //setUsergroupData(data.groupUsers)
                //     return {
                //         id: data.id,
                //         groupName: data.groupName,
                //         groupDescription: data.groupDescription,
                //         usersCount: data.usersCount,
                //         groupUsers: data.groupUsers
                //     }
                // })
                setGroupArray(res.data)

                // if (res.status === 200) {
                //     dismiss()

                //     //const sameId = _.filter(res , val=> val.id)

                //     const totaldata = _.map(groupArray, val => {
                //         //console.log('val =====>', val)
                //         //console.log('!_.isEmpty(res.data) =====> ', !_.isEmpty(res.data.users))



                //         if (_.isEmpty(res.data.users) && val.id === res.data.groupID) {
                //             console.log('response data empty')
                //             return ({ ...val, usersCount: 0, groupUsers: [] })
                //         }

                //         //console.log('val.id === res.groupId =====>', val.id === res.data.groupID)

                //         if (val.id === res.data.groupID) {
                //             const removesame = _.filter(val.groupUsers, u => {
                //                 return _.filter(res.data.users, data => {
                //                     return u.id === data.id
                //                 })
                //             })
                //             console.log("🚀 ~ removesame ~ removesame:----> ", removesame)

                //             const equalID = ({ ...val, usersCount: res.data.users.length, groupUsers: res.data.users })
                //             //console.log("🚀 ~ totaldata ~ equalID:---->", equalID)
                //             return equalID
                //         }
                //         //console.log('not equal ID and empty res.data val=====>', val)

                //         return val
                //     })
                //     console.log("🚀 ~ ApiService.deleteGroupUser ~ totaldata:", totaldata)

                //     setGroupArray(totaldata)

                //if (fromGroup && name === 'modal') alert('Removed..')
                if (!fromGroup && name === 'parentView') alert('Deleted Successfully.')
                setLoading(false)
            }

        }).catch(error => {
            setLoading(false)
            console.log("ðŸš€ ~ handleGroupDelete Error:")
            alert('OOps something went wrong. Please try again.')
        })
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleYes = () => {
        if (deletedGroupRow[0].usersCount !== 0) {
            setOpen(false)
            alert('Delete all patients to delete this group.')
            return
        }

        present({
            message: "Deleting...."
        })

        ApiService.deleteProviderGroup(userState.authenticatedUser, deletedGroupRow[0]).then((res) => {

            if (res.status === 200) {
                console.log("🚀 ~ Groups ~ rowData:", rowData)
                dismiss()
                setGroupArray(
                    groupArray.filter((r) => deletedGroupRow.filter((sr) => sr.id === r.id).length < 1)
                );
                alert('Deleted Successfully.')
            }

        }).catch(error => {
            dismiss()
            console.log("ðŸš€ ~ handleGroupDelete Error:")
            alert('OOps something went wrong. Please try again.')
        })

        setOpen(false)
    };

    const handleGroupDelete = (cellValues) => {
        setOpen(true)
        setDeletedGroupRow([cellValues])
    }

    const handleAddGroups = (groupName: string, groupDesc: string) => {
        groupCount = groupCount + 1
        if (_.isEmpty(groupName)) {
            alert('Group Name cannot be empty.')
            return
        }

        const data = {
            providerId: providerState.id,
            groupName: groupName,
            groupDescription: groupDesc,
            isActive: true,
            createdOn: new Date(),
            createdBy: userState.authenticatedUser.attributes.email
        }

        setLoading(true)
        ApiService.createGroups(userState.authenticatedUser, data).then((res) => {
            if (res.status === 200) {
                setLoading(false)

                setGroupArray([
                    ...groupArray,
                    {
                        id: res.data.id, groupName: groupName, groupDescription: groupDesc, usersCount: 0, groupUsers: []
                    }
                ])
            }
        }).catch(err => {
            setLoading(false)

            console.log("🚀 ~ ApiService.createGroups ~ error : ", err)
            alert('Something went wrong. Please try again.')
        })
    }


    const checkedUserAlreadyAdded = (rowUsers, value) => {
        //const already = _.filter(row.groupUsers, val => val.id === value.id)
        const present = _.some(rowUsers, ['id', value.id])
        return present
    }

    /* 
    @params:: userCount: number of users selected in right side.
            groupUsers: right side users.
            leftChecked: checked users in left side.
    */
    const addUsersToGroup = (userCount: number, groupUsers: any, leftChecked: any) => {

        const usersToSend = _.filter(leftChecked, val => {
            const alreadyAdded = checkedUserAlreadyAdded(groupUsers, val)
            return !alreadyAdded
        })

        const addGroupuser = _.map(usersToSend, val => ({ ...val, groupId: rowData.id })) //adding here groupID when new user added to left checked users.
        //console.log("🚀 ~ addUsersToGroup ~ addGroupuser:", addGroupuser)

        const add = groupArray.map(val => {

            const addedusers = _.uniqBy(val.groupUsers.concat(addGroupuser), 'id') // concat left cehcked users to already added users in group.

            if (val.groupName === rowData?.groupName) {
                const totalUsersData = { ...val, usersCount: addedusers.length, groupUsers: addedusers }
                return totalUsersData
            }
            return val
        })

        const dataToSend = {
            providerGroupId: rowData.id,
            addedUsers: addGroupuser,
        }

        setLoading(true)
        ApiService.addGroupusers(userState.authenticatedUser, dataToSend).then(res => {
            setLoading(false)
            if (res.status === 200) {
                setGroupArray(add)
                setAddUsersError(false)
                setUsers([])
                setRight([])
                alert('Group updated successfully.')
            }
        }).catch(err => {
            setLoading(false)
            setAddUsersError(true)
            console.log("🚀 ~ ApiService.addGroupusers ~ err:------>", err)
            //alert('Error! Adding patients to group. Check if you have already added selected patients.')
        })
    }

    return (
        <IonPage>
            <Header />
            <IonContent>
                <IonCard style={{ width: "100%", backgroundColor: "white", flex: 1 }}>

                    <div style={{ display: "flex", justifyContent: "center", marginBottom: 30, marginTop: 20 }}>
                        <h1 style={{ color: "black" }}>Groups</h1>
                    </div>

                    <GroupsFields handleAddGroups={handleAddGroups} />

                    <IonCardContent>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable
                                tableRef={tableRef}
                                localization={{
                                    body: {
                                        emptyDataSourceMessage: (
                                            <Typography>
                                                No Groups to display
                                            </Typography>
                                        ),
                                    },
                                }}
                                style={{ width: "100%" }}
                                title=""
                                icons={tableIcons}
                                columns={groupsColumns}
                                options={{
                                    pageSizeOptions: [5, 10],
                                    defaultExpanded: true
                                }}

                                components={{
                                    OverlayLoading: props => (<div style={{ justifyContent: "center", alignItems: 'center' }}> Loading... </div>)
                                }}
                                // isLoading={groupArray.length === 0 || groupArray === null}
                                page={5}
                                data={groupArray === null ? [] : groupArray}
                                detailPanel={[
                                    {
                                        tooltip: 'Click to show details',
                                        // icon: () => null,

                                        render: rowData => {

                                            if (_.isEmpty(rowData.groupName) && _.isEmpty(rowData.groupDescription)) {
                                                return
                                            }
                                            const sortUsers = _.sortBy(rowData.groupUsers, 'name')

                                            return (
                                                <div style={{ width: "100%" }}>
                                                    <DataGrid
                                                        sx={{
                                                            boxShadow: 2,
                                                            border: 2,
                                                            borderColor: 'primary.light',
                                                            '& .MuiDataGrid-cell:hover': {
                                                                color: 'primary.main',
                                                            },
                                                        }}
                                                        columns={columns}
                                                        rows={sortUsers}
                                                        initialState={{
                                                            pagination: {
                                                                paginationModel: {
                                                                    pageSize: 5,
                                                                },
                                                            },
                                                        }}
                                                        pageSizeOptions={[5]}
                                                        checkboxSelection={false}
                                                        autoHeight={true}
                                                        localeText={{
                                                            noRowsLabel: "Users not added to this group"
                                                        }} />
                                                </div>
                                            )
                                        },
                                    },
                                ]}
                                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                            />
                        </ThemeProvider>
                        <IonLoading
                            cssClass="my-custom-class"
                            isOpen={loading}
                            onDidDismiss={() => setLoading(false)}
                            message={"Please wait..."}
                            duration={5000}
                        />
                    </IonCardContent>
                    <GroupsUsers
                        isOpen={openGroupModal}
                        setIsOpen={setopenGroupModal}
                        users={users}
                        setUsers={setUsers}
                        addUsersToGroup={addUsersToGroup}
                        row={rowData}
                        right={right}
                        setRight={setRight}
                        handleUserDelete={handleUserDelete}
                    />
                </IonCard>
                <IonLoading
                    cssClass="my-custom-class"
                    isOpen={showLoading.value}
                    onDidDismiss={() => setShowLoading({ value: false, count: 1 })}
                    message={"Please wait..."}
                />
            </IonContent>

            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Deleting Group"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this group?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>No</Button>
                        <Button onClick={handleYes} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </IonPage>
    )
}