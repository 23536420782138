import { IonButton, IonLabel, IonSegment, IonSegmentButton, useIonLoading } from "@ionic/react"
import { Box, useMediaQuery } from "@mui/material"
import React, { useContext, useState } from "react"
import { FC } from "react"
import { MeasurementTypes } from "../../pages/Threshold/Constants"
import { UserDefaultState } from "../../pages/Threshold/ThresholdModel"
import { FormFields } from "../FormField/FormFields"
import { UsersThresholdComponent } from "./UsersThresholdComponent"
import * as ApiService from '../../../src/services/ApiService'
import { ApplicationContext, ProviderContext } from "../../ApplicationState"
import { GridRowSelectionModel } from "@mui/x-data-grid"
import { GroupsThreshold } from "./GroupsThreshold"
var _ = require('lodash');

export type UserDefaultThresholdProps = {
    userdefaultValues: UserDefaultState
    setUserDefaultValues: React.Dispatch<React.SetStateAction<UserDefaultState>>
    handleUserDefaultChange: (prop: keyof UserDefaultState) => (event: React.ChangeEvent<HTMLInputElement>) => void,
    patients: any;
    usergroupData: any;
}

export interface ThresholdValue {
    thresholdType: number
    thresholdValue: string
    thresholdName: string
}

export const dataArray = {
    id: 0,
    userid: '',
    given_name: '',
    email: '',
    phone_number: '',
}

const thresholds = {
    thresholdType: 0,
    thresholdValue: '',
    thresholdName: ''
}

export const UserDefaultThreshold: FC<UserDefaultThresholdProps> = ({
    userdefaultValues,
    setUserDefaultValues,
    handleUserDefaultChange,
    patients,
    usergroupData
}) => {
    const desktop = useMediaQuery('(min-width:600px)');
    const [present, dismiss] = useIonLoading();
    const { userState } = useContext(ApplicationContext);
    const { providerState } = useContext(ProviderContext);
    const [pageSize, setPageSize] = useState<number>(200);
    const [showUsers, setShowUsers] = useState(false)
    const [selectedDefaults, setSelectedDefaults] = useState('Patients')
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const [grpRowSelectionModel, setgrpRowSelectionModel] = React.useState<GridRowSelectionModel>([]);


    let thresholdsData = []

    let userArray = []

    const handleSelection = (value: GridRowSelectionModel) => {
        setgrpRowSelectionModel([])

        if (_.isEmpty(value)) {
            userArray = []
        }
        let temp = []

        if (Array.isArray(value)) {
            // Iterate the selection indexes:
            value.forEach(
                // Get the row data:
                (selection_index) => {
                    const d = patients[selection_index]
                    const finalValue = { ...d, thresholdsData }
                    temp.push(finalValue)
                }
            );
        }
        userArray = _.uniqBy(temp, 'id')
    }

    const handleGroupsSelection = (value: GridRowSelectionModel) => {
        setRowSelectionModel([])
        if (_.isEmpty(value)) {
            userArray = []
        }
        let temp = []

        if (Array.isArray(value)) {
            // Iterate the selection indexes:
            value.forEach(
                // Get the row data:
                (selection_index) => {
                    const d = !_.isEmpty(usergroupData[selection_index]?.groupUsers) ? usergroupData[selection_index]?.groupUsers : []
                    _.map(d, user => {
                        const finalValue = {
                            userid: user.id,
                            given_name: user.name,
                            phone_number: user.phone,
                            thresholdsData: thresholdsData
                        }
                        temp.push(finalValue)
                    })
                }
            );
        }

        userArray = _.uniqBy(temp, 'userid')
    }

    const isThresholdValuesEmpty = () => {
        const systolicMin = _.isEmpty(userdefaultValues.bloodPressureSystolicMin)
        const SystolicMax = _.isEmpty(userdefaultValues.bloodPressureSystolicMax)
        const diastolicMin = _.isEmpty(userdefaultValues.bloodPressureDiastolicMin)
        const diastolicMax = _.isEmpty(userdefaultValues.bloodPressureDiastolicMax)
        const glucoseMin = _.isEmpty(userdefaultValues.bloodGlucoseMin)
        const glucoseMax = _.isEmpty(userdefaultValues.bloodGlucoseMax)
        const pulseMin = _.isEmpty(userdefaultValues.pulseMin)
        const pulseMax = _.isEmpty(userdefaultValues.pulseMax)
        const temperatureMin = _.isEmpty(userdefaultValues.temperatureMin)
        const temperatureMax = _.isEmpty(userdefaultValues.temperatureMax)
        const weightMin = _.isEmpty(userdefaultValues.weightMin)
        const weightMax = _.isEmpty(userdefaultValues.weightMax)
        const bloodSaturation = _.isEmpty(userdefaultValues.bloodSaturation)
        const isEmptyValues = systolicMin && SystolicMax && diastolicMin && diastolicMax && glucoseMin && glucoseMax && pulseMin && pulseMax && temperatureMin && temperatureMax && weightMin && weightMax && bloodSaturation
        return isEmptyValues
    }

    const handleSaveSelectedUsers = () => {

        if (!_.isEmpty(rowSelectionModel) && !isThresholdValuesEmpty()) {
            handleSelection(rowSelectionModel)
        } else if (!_.isEmpty(grpRowSelectionModel) && !isThresholdValuesEmpty()) {
            handleGroupsSelection(grpRowSelectionModel)
        } else {
            alert('Please add your measurements.')
            dismiss()
            return
        }

        present({
            message: 'updating values...',
        })
        const response = ApiService.updateUserThresholds(userState.authenticatedUser, userArray).then((res) => {
            dismiss()
            setUserDefaultValues({
                bloodPressureSystolicMin: '',
                bloodPressureSystolicMax: '',
                bloodPressureDiastolicMin: '',
                bloodPressureDiastolicMax: '',
                weightMin: '',
                weightMax: '',
                temperatureMin: '',
                temperatureMax: '',
                oxygenMin: '',
                oxygenMax: '',
                bloodGlucoseMin: '',
                bloodGlucoseMax: '',
                bloodSaturation: '',
                pulseMin: '',
                pulseMax: ''
            })
            setShowUsers(false)
            userArray = []
            alert('Success. Thresholds values updated.')
        }).catch((error) => {
            dismiss()
            alert('Oops something went wrong. Please try again.')
            console.log('error updating threshold values', error)
        })
    }

    if (userdefaultValues) {
        const diastolicMin = {
            thresholdType: 0,
            thresholdValue: userdefaultValues.bloodPressureDiastolicMin,
            thresholdName: 'Diastolic',
        }

        const diastolicMax = {
            thresholdType: 1,
            thresholdValue: userdefaultValues.bloodPressureDiastolicMax,
            thresholdName: 'Diastolic',
        }


        const systolicMin = {
            thresholdType: 0,
            thresholdValue: userdefaultValues.bloodPressureSystolicMin,
            thresholdName: 'Systolic',
        }

        const systolicMax = {
            thresholdType: 1,
            thresholdValue: userdefaultValues.bloodPressureSystolicMax,
            thresholdName: 'Systolic',
        }


        const sugarMin = {
            thresholdType: 0,
            thresholdValue: userdefaultValues.bloodGlucoseMin,
            thresholdName: 'Sugar',
        }


        const sugarMax = {
            thresholdType: 1,
            thresholdValue: userdefaultValues.bloodGlucoseMax,
            thresholdName: 'Sugar',
        }


        const weightMin = {
            thresholdType: 0,
            thresholdValue: userdefaultValues.weightMin,
            thresholdName: 'Weight',
        }

        const weightMax = {
            thresholdType: 1,
            thresholdValue: userdefaultValues.weightMax,
            thresholdName: 'Weight',
        }


        const tempMin = {
            thresholdType: 0,
            thresholdValue: userdefaultValues.temperatureMin,
            thresholdName: 'Temperature',
        }

        const tempMax = {
            thresholdType: 1,
            thresholdValue: userdefaultValues.temperatureMax,
            thresholdName: 'Temperature',
        }

        const oxygenMin = {
            thresholdType: 0,
            thresholdValue: userdefaultValues.bloodSaturation,
            thresholdName: 'Oxygen Saturation',
        }


        const pulseMin = {
            thresholdType: 0,
            thresholdValue: userdefaultValues.pulseMin,
            thresholdName: 'Pulse',
        }

        const pulseMax = {
            thresholdType: 1,
            thresholdValue: userdefaultValues.pulseMax,
            thresholdName: 'Pulse',
        }
        thresholdsData = [systolicMin, systolicMax, diastolicMin, diastolicMax, weightMin, weightMax, tempMin, tempMax, oxygenMin, pulseMin, pulseMax, sugarMin, sugarMax]
    }

    return (
        <Box sx={{ boxShadow: 3 }} style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', margin: 10 }}>
                    <FormFields
                        style={{ m: 2, width: '20ch' }}
                        label={'Blood Pressure Systolic'}
                        prompt={'min'}
                        id={MeasurementTypes.BloodPressureSystolicMin}
                        value={userdefaultValues.bloodPressureSystolicMin}
                        adornment={'mmhg'}
                        handleFormValueChange={handleUserDefaultChange(MeasurementTypes.BloodPressureSystolicMin)}
                        disabled={false}
                    >
                    </FormFields>

                    <FormFields
                        style={{ m: 2, width: '20ch' }}
                        label={''}
                        prompt={'max'}
                        id={MeasurementTypes.BloodPressureSystolicMax}
                        value={userdefaultValues.bloodPressureSystolicMax}
                        adornment={'mmhg'}
                        handleFormValueChange={handleUserDefaultChange(MeasurementTypes.BloodPressureSystolicMax)}
                        disabled={false}
                    >
                    </FormFields>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', margin: 10 }}>

                    <FormFields
                        style={{ m: 2, width: '20ch' }}
                        label={'Blood Pressure Diastolic'}
                        prompt={'min'}
                        id={MeasurementTypes.BloodPressureDiastolicMin}
                        value={userdefaultValues.bloodPressureDiastolicMin}
                        adornment={'mmhg'}
                        handleFormValueChange={handleUserDefaultChange(MeasurementTypes.BloodPressureDiastolicMin)}
                        disabled={false}
                    >
                    </FormFields>

                    <FormFields
                        style={{ m: 2, width: '20ch' }}
                        label={''}
                        prompt={'max'}
                        id={MeasurementTypes.BloodPressureDiastolicMax}
                        value={userdefaultValues.bloodPressureDiastolicMax}
                        adornment={'mmhg'}
                        handleFormValueChange={handleUserDefaultChange(MeasurementTypes.BloodPressureDiastolicMax)}
                        disabled={false}
                    >
                    </FormFields>

                </div>

                <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
                    <FormFields
                        style={{ ml: { md: 11, xs: 2 }, width: '20ch' }}
                        label={'Blood Glucose'}
                        prompt={'min'}
                        id={MeasurementTypes.BloodGlucoseMin}
                        value={userdefaultValues.bloodGlucoseMin}
                        adornment={'mg/dl'}
                        handleFormValueChange={handleUserDefaultChange(MeasurementTypes.BloodGlucoseMin)}
                        disabled={false}
                    >
                    </FormFields>

                    <FormFields
                        style={{ ml: { md: 4, xs: 2 }, width: '20ch' }}
                        label={''}
                        prompt={'max'}
                        id={MeasurementTypes.BloodGlucoseMax}
                        value={userdefaultValues.bloodGlucoseMax}
                        adornment={'mg/dl'}
                        handleFormValueChange={handleUserDefaultChange(MeasurementTypes.BloodGlucoseMax)}
                        disabled={false}
                    >
                    </FormFields>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>

                    <FormFields
                        style={{ ml: { md: 19, xs: 2 }, width: '20ch' }}
                        label={'Pulse'}
                        prompt={'min'}
                        id={MeasurementTypes.PulseMin}
                        value={userdefaultValues.pulseMin}
                        adornment={'bpm'}
                        handleFormValueChange={handleUserDefaultChange(MeasurementTypes.PulseMin)}
                        disabled={false}
                    >
                    </FormFields>

                    <FormFields
                        style={{ ml: { md: 4, xs: 1 }, width: '20ch' }}
                        label={''}
                        prompt={'max'}
                        id={MeasurementTypes.PulseMax}
                        value={userdefaultValues.pulseMax}
                        adornment={'bpm'}
                        handleFormValueChange={handleUserDefaultChange(MeasurementTypes.PulseMax)}
                        disabled={false}
                    >
                    </FormFields>
                </div>


                <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>

                    <FormFields
                        style={{ ml: { md: 12.5, xs: 2 }, width: '20ch' }}
                        label={'Temperature'}
                        prompt={'min'}
                        id={MeasurementTypes.TemperatureMin}
                        value={userdefaultValues.temperatureMin}
                        adornment={'F'}
                        handleFormValueChange={handleUserDefaultChange(MeasurementTypes.TemperatureMin)}
                        disabled={false}
                    >
                    </FormFields>

                    <FormFields
                        style={{ ml: 4, width: '20ch' }}
                        label={''}
                        prompt={'max'}
                        id={MeasurementTypes.TemperatureMax}
                        value={userdefaultValues.temperatureMax}
                        adornment={'F'}
                        handleFormValueChange={handleUserDefaultChange(MeasurementTypes.TemperatureMax)}
                        disabled={false}
                    >
                    </FormFields>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>

                    <FormFields
                        style={{ ml: { md: 18, xs: 2 }, width: '20ch' }}
                        label={'Weight'}
                        prompt={'min'}
                        id={MeasurementTypes.WeightMin}
                        value={userdefaultValues.weightMin}
                        adornment={'lbs'}
                        handleFormValueChange={handleUserDefaultChange(MeasurementTypes.WeightMin)}
                        disabled={false}
                    >
                    </FormFields>

                    <FormFields
                        style={{ ml: 4, width: '20ch' }}
                        label={''}
                        prompt={'max'}
                        id={MeasurementTypes.WeightMax}
                        value={userdefaultValues.weightMax}
                        adornment={'lbs'}
                        handleFormValueChange={handleUserDefaultChange(MeasurementTypes.WeightMax)}
                        disabled={false}
                    >
                    </FormFields>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginLeft: 10, marginTop: 30, marginBottom: 5 }}>
                    <FormFields
                        style={{ ml: { md: 9, xs: 2 }, width: '20ch' }}
                        label={'Oxygen Saturation'}
                        prompt={'min'}
                        id={MeasurementTypes.BloodSaturation}
                        value={userdefaultValues.bloodSaturation}
                        adornment={'%'}
                        handleFormValueChange={handleUserDefaultChange(MeasurementTypes.BloodSaturation)}
                        disabled={false}
                    >
                    </FormFields>
                </div>
                {/* <IonButton disabled={isThresholdValuesEmpty()} style={{ marginLeft: 10, marginTop: 20, width: 200, height: 50 }} onClick={handleApplyToUsers}> Select users </IonButton> */}
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'center', margin: 20 }}>
                <IonSegment value={selectedDefaults} mode="ios" style={{ height: 50, width: 500, margin: 10 }} onIonChange={e => {
                    setSelectedDefaults(e.detail.value)
                }
                }>
                    <IonSegmentButton value='Patients'>
                        <IonLabel>Patients</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value='Groups'>
                        <IonLabel>Groups</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

                {selectedDefaults === 'Patients' &&
                    <UsersThresholdComponent
                        data={patients}
                        handleSelection={handleSelection}
                        setRowSelectionModel={setRowSelectionModel}
                        rowSelectionModel={rowSelectionModel}
                    >
                    </UsersThresholdComponent>
                }
                {selectedDefaults === 'Groups' &&
                    <GroupsThreshold
                        usergroupData={usergroupData}
                        handleGroupsSelection={handleGroupsSelection}
                        setRowSelectionModel={setgrpRowSelectionModel}
                        rowSelectionModel={grpRowSelectionModel}
                    >
                    </GroupsThreshold>
                }
                <IonButton style={{ marginLeft: 10, width: 200, height: 50 }} onClick={handleSaveSelectedUsers} size="default" color="primary" >Apply to users </IonButton>
            </div>


        </Box >
    )
}