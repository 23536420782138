import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { ApplicationContextProvider } from "./ApplicationState";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import "./components/Layout/styles/styles.css";
import "./components/Layout/styles/resizable.css";

import '../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-layouts/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-grids/styles/bootstrap5.css';
import "../node_modules/@syncfusion/ej2-react-filemanager/styles/bootstrap5.css";
import { Provider } from 'react-redux'
import { store } from "./context/store";

ReactDOM.render(
  <ApplicationContextProvider>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>

  </ApplicationContextProvider >,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
