import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonContent, IonHeader, IonInfiniteScroll, IonItem, IonLabel, IonPage, IonSegment, IonSegmentButton, IonTitle, IonToolbar, useIonLoading } from '@ionic/react';
import * as ApiService from '../../services/ApiService';
import { ApplicationContext, ProviderContext } from '../../ApplicationState';
import { DataGrid, GridFilterModel, GridToolbar, GridColDef, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridSearchIcon, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useHistory, useLocation } from 'react-router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Autocomplete, Box, FormControl, FormControlLabel, Button, FormLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, Radio, RadioGroup, TextareaAutosize, TextField, Select, MenuItem, ListItemText, SelectChangeEvent, ButtonGroup, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Alert, Stack, CircularProgress, ThemeProvider, createTheme, lighten, darken, styled, Checkbox } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { da } from 'date-fns/locale';
import { add, format } from 'date-fns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { datetime, RRule, RRuleSet, rrulestr, Options } from 'rrule'
import tableIcons from "./MaterialTableIcons";

import Header from '../Header/Header';
import MaterialTable from 'material-table';
import "./Notifications.css";
import ScheduleDetails from './ScheduleDetails';
import FileManager from '../../pages/FileManager/Filemanager'
import * as Helper from "../../services/Helpers";
import CancelIcon from "@material-ui/icons/Cancel";
import { Icon } from '@iconify/react';

var _ = require('lodash');

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

// function CustomToolbar() {
//     return (
//         <GridToolbarContainer style={{ flex: 1 }}>
//             <GridToolbarColumnsButton onReset={undefined} onResizeCapture={undefined} />
//             <GridToolbarFilterButton onReset={undefined} onResizeCapture={undefined} />
//             <GridToolbarDensitySelector onReset={undefined} onResizeCapture={undefined} />
//             <GridToolbarQuickFilter style={{ marginLeft: 50 }} />
//         </GridToolbarContainer>
//     );
// }
const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: 'darkblue',
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

interface UserDataType {
    id: string;
    label: string;
    phone: number;
    deviceID: string | null;
    deviceType: string | null;
    ruleId: number;
    groupName?: string;
}

interface GroupDataType {
    userId: string;
    groupName: string;
    userName: number;
}

interface ScheduleFrequency {
    date: Date | Dayjs | null
    sendImmediately: boolean
}

// interface ScheduleDelivery {
//     freq: string,
//     interval: number | null
//     weekDay: string[],
//     dtstart: Date | null,
//     until: Date | null,
// }

enum ResponseType {
    YesNO = "Yes - No",
    AcceptDeny = "Accept - Deny",
}

interface ScheduleDataType {
    scheduleUser: UserDataType[]
    scheduleName: string
    scheduleDesc: string
    selectedFile: any
    type?: boolean,
    scheduleDates: Date[] | null
    scheduleRule: Partial<Options>
    status: "Pending"
    ScheduleDate: Date | null,
    user: any
    fileName: string
    responseType: string
}

interface RuleType {
    freq: any,
    interval: number,
    dtstart: Date,
    until: Date,
    byweekday: string
}

const dataArray: UserDataType = {
    label: '',
    phone: 0,
    deviceID: "",
    deviceType: "",
    id: "",
    ruleId: 0
}

function Notifications() {

    const defaultMaterialTheme = createTheme();

    const columns = [
        {
            field: "name",
            title: "Name",
            width: "100%",
        },
        {
            field: "scheduleTitle",
            title: "Title",
            width: 0
        },
        {
            field: "startDate",
            title: "Start Date",
            width: 0
        },
        {
            field: "expireDate",
            title: "Expired Date",
            width: 200
        },
        {
            field: "frequency",
            title: "Frequency",
            width: 200
        },
        {
            field: "details",
            title: " ",
            width: 0,
            render: rowData => <Button variant="contained">Show Details</Button>
        },
        {
            field: "delete",
            title: "  ",
            width: 0,
            render: rowData => <Button variant="contained" onClick={() => handleDelete(rowData)}>Cancel</Button>
        },
    ];

    const timePeriod = [
        { label: 'minute', letter: "s" },
        { label: 'hour', letter: "s" },
        { label: 'day', letter: "s" },
        { label: 'week', letter: "s" },
        { label: 'month', letter: "s" },
        { label: 'year', letter: "s" },
    ]


    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    const frequency = ['Hourly', 'Daily', 'Weekly', 'Monthly']

    const { userState } = useContext(ApplicationContext);
    const { providerState } = useContext(ProviderContext);
    const location: any = useLocation();

    const [present, dismiss] = useIonLoading();

    const [loader, setLoader] = useState(false);
    const [users, setUsers] = React.useState([dataArray]);
    const [userData, setUserData] = useState();

    const [userValue, setUserValue] = React.useState<string | null>("")

    const [frequencyTime, setFrequencyTime] = React.useState("")
    const [scheduleName, setScheduleName] = React.useState("")
    const [scheduleDesc, setScheduleDesc] = React.useState("")
    const [value, setValue] = React.useState('SendImmediately');
    const [dateField, setDateField] = React.useState<Dayjs | Date | null>(null);
    const [scheduleUserData, setScheduleUserData] = React.useState<UserDataType | null>()
    const [scheduleUserDataType, setScheduleUserDataType] = React.useState<UserDataType[] | null>([])

    const [time, setTime] = React.useState<Dayjs | null>(null);
    const [dayPeriod, setDayPeriod] = React.useState([])

    const [startDate, setStartDate] = React.useState<Date | null>(null);
    const [startDateString, setStartDateString] = React.useState("");
    const [startTimeString, setStartTimeString] = React.useState("");

    const [EndDate, setEndDate] = React.useState<Date | null>(null);
    const [EndDateString, setEndDateString] = React.useState("");
    const [EndTimeString, setEndTimeString] = React.useState("");

    const [deliveryInterval, setDeliveryInterval] = React.useState("");
    const [ruleWeekDays, setruleWeekDays] = React.useState<any[] | null>(null)
    const [schedulePeriod, setSchedulePeriod] = React.useState<any>(RRule.DAILY)

    const [pageSize, setPageSize] = useState<number>(200);

    const [selectedFile, setSelectedFile] = useState(undefined);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [interval, setInterval] = useState("");
    const [count, setCount] = useState("");
    const [freqRuleSelected, setFreqRuleSelected] = useState(false)
    const [menuLabel, setMenulabel] = useState('')
    const firstItemRef = useRef(null);
    const inputRef = useRef(null);
    const [usergroupData, setUsergroupData] = useState([]);

    const [responseChecked, setResponseChecked] = useState(false)
    const [responseType, setResponseType] = React.useState('');

    useEffect(() => {
        let pageNum = 1;
        ApiService.fetchUsers(
            userState.authenticatedUser,
            providerState.providerId,
            pageSize,
            pageNum,
            null,
            null,
            null,
            null,
            null,
            "patients"
        ).then((data) => {

            if (data) {

                let rowId = 0
                setUserData(data)
                const userData = data.map((value) => {
                    let attributes: any[] = value["attributes"];
                    const { id, deviceId, deviceType } = value
                    let fullName = (
                        attributes.find((r) => r.name == "given_name").value +
                        " " +
                        attributes.find((r) => r.name == "family_name").value
                    ).replace(/\b(\w)/g, (s) => s.toUpperCase());
                    let email = attributes.find((r) => r.name == "email").value;
                    let phone = attributes.find((r) => r.name == "phone_number").value;

                    const thresholdObject: UserDataType = {
                        label: fullName,
                        phone: phone,
                        deviceID: deviceId,
                        deviceType: deviceType,
                        ruleId: rowId,
                        id: id
                    }
                    rowId = rowId + 1
                    return thresholdObject
                })
                setUsers(userData)
            }

        }).catch(error => {

            alert('OOps something went wrong. Please try again.')
        })

    }, [providerState])

    useEffect(() => {
        const menuLabel = Helper.getDisplayLabel(providerState)
        setMenulabel(menuLabel)
    }, [])

    useEffect(() => {
        ApiService.getProviderGroups(userState.authenticatedUser, providerState.id).then(res => {
            if (res) {
                const grp = _.map(res, data => {
                    return {
                        id: data.id,
                        groupName: data.groupName,
                        groupDescription: data.groupDescription,
                        usersCount: data.usersCount,
                        groupUsers: data.groupUsers
                    }
                })
                const sortgroup = _.orderBy(grp, 'asc')
                setUsergroupData(sortgroup)
            }
        }).catch(error => {
            alert('Issues getting the groups. Please try again.')
        })
    }, [providerState])

    const handleClick = () => {
        console.log("button click notifications.", userArray)

        ApiService.sendNotification(userState.authenticatedUser, userArray).then(data => {
            console.log("API Backedn code Called.")
        })
    }

    let userArray = []


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const changeHandler = (event) => {
        console.log("🚀 ~ file: Notifications.tsx:288 ~ changeHandler ~ event:", event.target.files[0])

        setSelectedFile(event.target.files[0]);
        setSelectedFileName(event.target.files[0].name);
        setIsFilePicked(true);
    };

    // const handleUploadFile = () => {
    //     const selectedFile = localStorage.getItem('selectedFileName')
    //     console.log("🚀 ~ file: Notifications.tsx:297 ~ handleUploadFile ~ selectedFile:", selectedFile)
    //     setSelectedFile(selectedFile)
    //     set
    //     setIsFilePicked(true);
    // }

    const handleCancelFile = () => {

        // ðŸ‘‡ï¸ reset input value
        // inputRef.current.value = null;
        setSelectedFile(undefined);
        setSelectedFileName('')
        setIsFilePicked(false)
    }
    const handleFrequencyInterval = (value) => {
        var date = new Date()
        console.log("ðŸš€ ~ file: Notifications.tsx:228 ~ handleFrequencyInterval ~ date:", date)
        var addInterval = parseInt(interval)
        const result = add(date, {
            years: value === "year" ? addInterval : 0,
            months: value === "month" ? addInterval : 0,
            weeks: value === "week" ? addInterval : 0,
            days: value === "day" ? addInterval : 0,
            hours: value === "hour" ? addInterval : 0,
            minutes: value === "minute" ? addInterval : 0,
        })
        console.log("ðŸš€ ~ file: Notifications.tsx:226 ~ handleFrequencyInterval ~ result:", result)
        setDateField(result)
    }

    const [row, setRow] = useState([])
    const [showScheduleList, setScheduleList] = useState(false)
    const [open, setOpen] = React.useState(false);

    const [openFilemanager, setOpenFilemanager] = React.useState(false);


    //const [deletedRows, setDeletedRows] = useState([]);
    const [deletedRow, setDeletedRow] = useState([]);

    const handleClose = () => {
        setOpen(false)
    };

    const handleCloseFileManager = () => {
        setOpenFilemanager(false)
    };

    const handleFileChoose = () => {
        const selectedFile = localStorage.getItem('selectedFileName')
        //setSelectedFile(selectedFile)
    }

    const handleYes = () => {
        present({
            message: "Deleting...."
        })
        const frequencyInt = convertFrequencytoInt(deletedRow[0].frequency)
        const dataToDelete = { ...deletedRow[0], frequency: frequencyInt }
        ApiService.deleteNotification(userState.authenticatedUser, dataToDelete)
            .then(data => {
                if (data.status === 200) {
                    dismiss()
                    setRow(
                        row.filter((r) => deletedRow.filter((sr) => sr.id === r.id).length < 1)
                    );
                    alert('Deleted Successfully.')
                }
            }).catch(error => {
                dismiss()
                console.log("ðŸš€ ~ file: Notifications.tsx:374 ~ handleYes ~ error:", error)
                alert('OOps something went wrong. Please try again.')
            })

        setOpen(false);
    };

    const history = useHistory();

    const handleShowDetails = (cellValue) => {
        console.log("🚀 ~ file: Notifications.tsx:374 ~ handleShowDetails ~ cellValue:", cellValue)
        history.push("/scheduleDetails", { data: cellValue });
    }

    const handleDelete = (cellValue) => {
        setOpen(true)
        setDeletedRow([cellValue])
    }

    const handleRowSelection = (e) => {
        console.log("ðŸš€ ~ file: Notifications.tsx:352 ~ handleRowSelection ~ e:", e)
        // history.push("/scheduleDetails", { id: e.id });


        // if (e.target.textContent === "Show Details") {
        //     history.push("/scheduleDetails", { id: e.id });
        // }



        //setDeletedRows(e.id)

        // if (e.field === "delete") {
        //     console.log("e.filed===deletee.filed===deletee.filed===deletee.filed===delete")
        //     setOpen(true);
        // }
        // setDeletedRow([e])

        //setDeletedRows([...deletedRows, ...row.filter((r) => r.id === e.id)]);
    };

    const [showHistory, setShowHistory] = useState(false)
    const [historyRow, setHistoryRow] = useState([])

    const handleShowHistory = (id, fullName, title, startDate, until) => {
        setShowHistory(true)
        const history = [{
            id: id,
            name: fullName,
            scheduleTitle: title,
            startDate: startDate,
            expireDate: until,
        }]
        setHistoryRow(history)
    }

    let pageNum = 1
    const handleGetScheduleRule = () => {
        setScheduleList(true)
        //firstItemRef.current.scrollIntoView()
    }

    const getScheduleRules = (page: number, pageSize: number) => {
        var title = ""
        var dateEnd = ""
        let ruleText = ''
        let endDataRule: Date = null
        return ApiService.getScheduledRuleNotifications(userState.authenticatedUser, providerState.providerId, pageSize, page)
            .then(data => {
                if (data) {
                    const schedules = _.map(data.scheduleRules, d => {
                        const isScheduleEmpty = _.isEmpty(d.schedules)

                        if (!isScheduleEmpty) {
                            const isSameRuleId = d.id === d.schedules[0].scheduleRuleId
                            if (isSameRuleId) {
                                title = d.schedules[0].scheduleName
                                if (d.until === "0001-01-01T00:00:00") {
                                    const scheduleLastDate = d.schedules[d.schedules.length - 1].scheduleDate
                                    endDataRule = new Date(scheduleLastDate)
                                    dateEnd = format(new Date(scheduleLastDate), 'yyyy-MM-dd hh:mm a')
                                }
                            }
                        }

                        const dateStart = format(new Date(d.startDate), 'yyyy-MM-dd hh:mm a')
                        if (d.until !== "0001-01-01T00:00:00") {
                            endDataRule = new Date(d.until)
                            dateEnd = format(new Date(d.until), 'yyyy-MM-dd hh:mm a')
                        }

                        if (d.frequency > 0) {

                            ruleText = '(' + new RRule({
                                freq: d.frequency,
                                interval: d.interval,
                                dtstart: dateStart != null ? new Date(d.startDate) : null,
                                until: endDataRule != null ? endDataRule : null,
                                // byweekday: ruleWeekDays != null ? ruleWeekDays : null
                            }).toText() + ')'
                        } else {
                            ruleText = ''
                        }

                        const fullName =
                            d.user.firstName
                            +
                            " " +
                            d.user.lastName
                            ;

                        return {
                            id: d.id,
                            name: fullName,
                            scheduleTitle: title,
                            startDate: dateStart,
                            expireDate: dateEnd,
                            schedules: d.schedules,
                            frequency: RulefrequencyConvert(d.frequency) + ' ' + ruleText,

                        }
                    })

                    const schedulesOrderByDate = _.orderBy(schedules, [(v) => new Date(v.startDate)], ['desc']);
                    pageNum = pageNum + 1
                    const datausers = {
                        data: schedulesOrderByDate,
                        currentPage: data.currentPage,
                        pageSize: data.pageSize,
                        total: data.totalCount,
                        totalPages: data.totalPages
                    }
                    setRow(schedulesOrderByDate)
                    return datausers
                }
            })
        // .catch(err => {
        //     console.log('error getting and showing schedules.', err)
        //     alert('Something went wrong. Please try again.')
        // })
    }

    const RulefrequencyConvert = (freq) => {
        switch (freq) {
            case 0: {
                return "IMMEDIATE"
            }
            case 1: {
                return "MONTHLY"
            }
            case 2: {
                return "WEEKLY"
            }
            case 3: {
                return "DAILY"
            }
            case 4: {
                return "HOURLY"
            }
            case 5: {
                return "MINUTELY"
            }
            default:
                return ""
        }
    }

    const convertFrequencytoInt = (freq) => {
        switch (freq) {
            case "IMMEDIATE": {
                return 0
            }
            case "MONTHLY": {
                return 1
            }
            case "WEEKLY": {
                return 2
            }
            case "DAILY": {
                return 3
            }
            case "HOURLY": {
                return 4
            }
            case "MINUTELY": {
                return 5
            }
            default:
                return 0
        }
    }

    const RuleStartDate = () => {
        if (startDateString === "" || startTimeString === "") {
            return null
        }
        //2023-07-17T15:15
        const stringStartDate = startDateString + 'T' + startTimeString
        const formatStartDate = new Date(stringStartDate)
        return formatStartDate
    }

    const RuleEndDate = () => {
        if (EndDateString === "" || EndTimeString === "") {
            return null
        }
        //2023-07-17T15:15
        const stringEndDate = EndDateString + 'T' + EndTimeString
        const formatEndDate = new Date(stringEndDate)
        return formatEndDate ?? null
    }

    //new Date(new Date(date).setHours(date.getHours() + hours));
    const result = add(new Date(), {
        months: schedulePeriod === RRule.MONTHLY ? parseInt(interval) : 0,
        weeks: schedulePeriod === RRule.WEEKLY ? parseInt(interval) : 0,
        days: schedulePeriod === RRule.DAILY ? parseInt(interval) : 0,
        hours: schedulePeriod === RRule.HOURLY ? parseInt(interval) : 0,
        minutes: schedulePeriod === RRule.MINUTELY ? parseInt(interval) : 0,
    })

    //Rule for Delay Section
    const delayRule = new RRule({
        freq: schedulePeriod,
        count: parseInt(count),
        interval: parseInt(interval),
        dtstart: result,
        // until: new Date(new Date().setHours(23, 59, 59))
    })

    //Rule for Schedule Section
    const rule = new RRule({
        freq: schedulePeriod,
        interval: parseInt(deliveryInterval),
        dtstart: RuleStartDate(),
        until: RuleEndDate(),
        byweekday: ruleWeekDays != null ? ruleWeekDays : null
    })

    const fileToBase64DataURL = file => new Promise(
        resolvePromise => {
            const reader = new FileReader();
            reader.onload = () => resolvePromise(reader.result);
            reader.readAsDataURL(file);
        }
    );

    const chooseFile = (file: string, fileDetails: any) => {
        setSelectedFile(fileDetails)
        setSelectedFileName(file)
        setIsFilePicked(true)
        handleCloseFileManager()
    }

    const handleSendSchedule = async () => {

        // let base64URL = undefined
        // if (selectedFile) {
        //     base64URL = await fileToBase64DataURL(selectedFile);
        // }

        const isScheduleEmpty = _.isEmpty(startDateString) || _.isEmpty(startTimeString) || _.isEmpty(EndDateString) || _.isEmpty(EndTimeString) || _.isEmpty(deliveryInterval) || freqRuleSelected === false
        const isDelayEmpty = _.isEmpty(count) || _.isEmpty(interval) || freqRuleSelected === false
        const isScheduleName = scheduleUserDataType === null || scheduleUserDataType.length === 0 || _.isEmpty(scheduleName)
        const isEmpty = value === 'delay' ? isDelayEmpty : isScheduleEmpty
        const responseEmpty = responseChecked && _.isEmpty(responseType)

        if (value === 'delay' || value === 'schedule') {
            if (isScheduleName || isEmpty) {
                alert('Please fill up the form mark with *')
                return
            }
            if (isNumTextValid()) {
                alert('Please enter Interval > 0')
                return
            }
            // if (isCountTextValid()) {
            //     alert('Please enter Count > 0')
            //     return
            // }
        }

        if (value === 'delay') {
            if (isScheduleName || isEmpty) {
                alert('Please fill up the form mark with *')
                return
            }
            if (isNumTextValid()) {
                alert('Please enter Interval > 0')
                return
            }
            if (isCountTextValid()) {
                alert('Please enter Count > 0')
                return
            }
        }

        if (value === 'SendImmediately') {
            if (isScheduleName) {
                alert('Please fill up the form mark with *')
                return
            }
        }

        if (responseEmpty) {
            alert('Please select response type or uncheck it to not include in the message.')
            return
        }

        const selectedUserData = _.filter(userData, d => d.id === scheduleUserData.id)
        let frequency: ScheduleFrequency = { date: dateField, sendImmediately: value === "SendImmediately" }
        let datetime = new Date()

        let data: ScheduleDataType = {
            scheduleDesc: scheduleDesc, scheduleName: scheduleName, scheduleUser: scheduleUserDataType,
            selectedFile: selectedFile, scheduleDates: value === "SendImmediately" ? [new Date()] : value === 'delay' ? delayRule.all() : rule.all(), scheduleRule: value === "SendImmediately" ? null : value === "delay" ? delayRule.origOptions : rule.origOptions,
            status: 'Pending',
            ScheduleDate: datetime,
            user: selectedUserData[0],
            fileName: selectedFileName,
            responseType: responseChecked ? responseType : ''
        }

        let msg = value === "SendImmediately" ? "Sending..." : value === "delay" ? "Scheduling" + " " + delayRule.toText() : "Scheduling" + " " + rule.toText()

        present({
            message: msg
        })

        if (value === "delay") {
            if (delayRule.all().length > 20) {
                setTimeout(() => {
                    dismiss()
                }, 10000)
            }
        }

        if (value === "schedule") {
            if (rule.all().length > 20) {
                setTimeout(() => {
                    dismiss()
                }, 10000)
            }
        }

        await ApiService.sendNotification(userState.authenticatedUser, data).then(data => {
            console.log("API Backedn code Called.")

            dismiss()
            if (data.status === 200) {
                setScheduleList(true)
                setScheduleDesc("")
                setScheduleName("")
                setSelectedFile(null)
                setStartDateString("")
                setEndDateString("")
                setStartTimeString("")
                setEndTimeString("")
                setStartDate(null)
                setEndDate(null)
                setFrequencyTime("")
                setDeliveryInterval("")
                setruleWeekDays(null)
                setSchedulePeriod(RRule.DAILY)
                setCount("");
                setInterval("");
                setFreqRuleSelected(false)
                setSelectedFileName('')
                setIsFilePicked(false)

                alert('Success!' + " " + msg)
            }
        }).catch(error => {
            dismiss()
            console.log("ðŸš€ ~ file: Notifications.tsx:429 ~ ApiService.sendNotification ~ error:", error)
            alert('OOps something went wrong with scheduling. Please try again.')
        })
    };

    const handeleSelectedUsers = (value) => {
        const addSelectedUsers = _.filter(value, user => user.deviceID !== null)
        setScheduleUserDataType(addSelectedUsers)
    }

    const handleUserOptions = (value) => {
        setScheduleUserData(value)
        return value.label
    };

    const handleDayChange = (event: SelectChangeEvent<typeof dayPeriod>) => {
        const {
            target: { value },
        } = event;

        let weekday = []
        const dayValue = typeof value === 'string' ? value.split(',') : value
        const isSun = dayValue.includes("Sun")
        const isMon = dayValue.includes("Mon")
        const isTue = dayValue.includes("Tue")
        const isWed = dayValue.includes("Wed")
        const isThu = dayValue.includes("Thu")
        const isFri = dayValue.includes("Fri")
        const isSat = dayValue.includes("Sat")

        setDayPeriod(dayValue)


        if (isSun) {
            weekday.push(RRule.SU.weekday)
        }
        if (isMon) {
            weekday.push(RRule.MO.weekday)
        }
        if (isTue) {
            weekday.push(RRule.TU.weekday)
        }
        if (isWed) {
            weekday.push(RRule.WE.weekday)
        }
        if (isThu) {
            weekday.push(RRule.TH.weekday)
        }
        if (isFri) {
            weekday.push(RRule.FR.weekday)
        }
        if (isSat) {
            weekday.push(RRule.SA.weekday)
        }

        setruleWeekDays(weekday)
    };

    const handleFreq = (ev, newValue) => {
        setFreqRuleSelected(true)
        switch (newValue) {
            case "Minute": {
                setSchedulePeriod(RRule.MINUTELY)
                break;
            }
            case "Daily": {
                setSchedulePeriod(RRule.DAILY)
                break;
            }
            case "Hourly": {
                setSchedulePeriod(RRule.HOURLY)
                break;
            }
            case "Weekly": {
                setSchedulePeriod(RRule.WEEKLY)
                break;
            }
            case "Monthly": {
                setSchedulePeriod(RRule.MONTHLY)
                break;
            }
            default:
                setSchedulePeriod(RRule.DAILY)
                break;
        }
    }

    //Start Date for Schedule Section
    const handleStartDate = (newValue) => {
        setStartDateString(newValue.target.value)
        const formatDate = new Date(newValue.target.value)
        setStartDate(formatDate)
    }

    //Start Time for Schedule Section
    const handleStarTime = (newValue) => {
        setStartTimeString(newValue.target.value)
    }

    //End Date for Schedule Section
    const handleEndDate = (newValue) => {
        setEndDateString(newValue.target.value)
        const formatDate = new Date(newValue.target.value)
        setEndDate(formatDate)
    }

    //End Time for Schedule Section
    const handleEndTime = (newValue) => {
        setEndTimeString(newValue.target.value)
    }

    const handleDayLabel = () => {
        switch (schedulePeriod) {
            case RRule.MINUTELY: {
                return "min(s)"
            }
            case RRule.HOURLY: {
                return "hour(s)"
            }
            case RRule.DAILY: {
                return "day(s)"
            }
            case RRule.WEEKLY: {
                return "week(s)"
            }
            case RRule.MONTHLY: {
                return "month(s)"
            }
            default:
                return ""
        }
    }

    const isCountTextValid = () => {
        return parseInt(count) < 1 || count === ""
    }

    const isNumTextValid = () => {
        if (value === 'delay') {
            return parseInt(interval) < 1 || interval === ""
        }
        return parseInt(deliveryInterval) < 1 || deliveryInterval === ""
    }

    const handleSubmitDisable = () => {
        if (scheduleUserDataType === null || scheduleUserDataType.length === 0) {
            return true
        }
        const isEmpty = value === "delay" ? _.isEmpty(frequencyTime) && _.isEmpty(interval) && _.isEmpty(count) : startDate === null && EndDate === null && _.isEmpty(startDateString) && _.isEmpty(EndDateString) && _.isEmpty(dayPeriod) && _.isEmpty(deliveryInterval) && _.isEmpty(scheduleName) && _.isEmpty(scheduleDesc)
        return value === "SendImmediately" ? false : isEmpty
    }

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const emptyUsers = () => {
        const len = users.length === 1
        if (_.isEmpty(users)) return false
        const value = _.isEmpty(users[0].deviceID) && _.isEmpty(users[0].deviceType) && _.isEmpty(users[0].id) && _.isEmpty(users[0].label) && users[0].phone === 0 && users[0].ruleId === 0
        return len && value
    }

    // const grparr = []
    // let arr: UserDataType = {
    //     id: '',
    //     label: '',
    //     phone: 0,
    //     deviceID: '',
    //     deviceType: '',
    //     ruleId: 0,
    //     groupName: ''
    // }


    // const options = usergroupData.map((option, index) => {
    //     let name = ''

    //     const groupName = option.groupName.toUpperCase()

    //     const len = option.groupUsers.length

    //     for (var i = 0; i < len; i++) {
    //         name = option.groupUsers[i].name
    //         arr = {
    //             id: option.groupUsers[i].id,
    //             label: name,
    //             deviceID: option.groupUsers[i].deviceId,
    //             deviceType: option.groupUsers[i].deviceType,
    //             phone: option.groupUsers[i].phone,
    //             ruleId: i,
    //             groupName: groupName
    //         }
    //         grparr.push(arr)
    //     }

    //     return grparr
    // })


    let grpUsersarr: UserDataType = {
        id: '',
        label: '',
        phone: 0,
        deviceID: '',
        deviceType: '',
        ruleId: 0,
        groupName: ''
    }

    const userGroupArray = []
    const handleGroupSelectedUser = (value) => {
        if (_.isEmpty(value)) {
            setScheduleUserDataType([])
            return
        }

        const lastValue = value[value.length - 1] //taking last element which is latest from value array.
        const users = lastValue?.groupUsers

        for (var i = 0; i < users.length; i++) {

            grpUsersarr = {
                id: users[i].id,
                label: users[i].name,
                deviceID: users[i].deviceId,
                deviceType: users[i].deviceType,
                phone: users[i].phone,
                ruleId: i,
                groupName: users[i].groupName
            }
            userGroupArray.push(grpUsersarr)
        }

        const userDeviceidNotNull = _.filter(userGroupArray, user => user.deviceID !== null)

        setScheduleUserDataType(prevstate => [...prevstate, ...userDeviceidNotNull])
        setScheduleUserData(userGroupArray[userGroupArray.length - 1])
    }

    const handleGroupUserOptions = value => {
        //setScheduleUserData(value)
        return value.groupName
    }

    // const checkOption = (option) => {
    //     const check = scheduleUserDataType.some((c) => c.id === option.id && c.groupName === option.groupName);
    //     return check
    // }

    //gives all group name
    // const checkGroup = (group: string) => {
    //     const groupLength = grparr.filter((c) => c.groupName === group).length;
    //     const selectedGroupLength = _.filter(scheduleUserDataType, c => c.groupName === group).length
    //     return groupLength === selectedGroupLength;
    // }

    //only selected group name
    // const selectGroup = (group: string) => {
    //     const groupedUsers = grparr.filter((c) => c.groupName === group);
    //     const selectedGroup = _.filter(scheduleUserDataType, c => c.groupName === group)
    //     if (selectedGroup.length > 0) {
    //         setScheduleUserDataType((prevState) => [...prevState.filter((c) => {
    //             const notgrp = c.groupName !== group
    //             return notgrp
    //         })
    //         ])
    //     } else {
    //         setScheduleUserDataType((prevState) => [...prevState, ...groupedUsers])
    //         setScheduleUserData(groupedUsers[groupedUsers.length - 1])
    //     }
    // }

    // const unselectOption = (id: string) => {
    //     setScheduleUserDataType((prevState) =>
    //         prevState.filter((c) => c.id !== id)
    //     )
    // }

    const [autoComplete, setAutocomplete] = useState('');

    const handleDropDownChange = (event) => {
        setAutocomplete(event.target.value);
    }

    return (
        <IonPage>
            <Header />
            <IonContent style={{ backgroundColor: "white", overflow: "scroll" }}>
                <IonCard style={{ width: "100%", backgroundColor: "white" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <h1 style={{ color: "black" }}>Create Notifications</h1>
                    </div>
                    <IonCard style={{ display: "flex", width: "100%", backgroundColor: "white" }}>
                        <IonCardContent>
                            <div>
                                <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>

                                    <InputLabel sx={{ color: 'black', alignSelf: "center", marginRight: menuLabel === 'Patients' ? 3 : 5 }} htmlFor="standard-adornment-amount">Send to*:</InputLabel>
                                    <Box sx={{ minWidth: 120, marginRight: 5 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={autoComplete}
                                                label="Age"
                                                onChange={handleDropDownChange}
                                            >
                                                <MenuItem value={'Patients'}>{menuLabel}</MenuItem>
                                                <MenuItem value={'Groups'}>{'Groups'}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    {
                                        autoComplete === 'Patients' && (
                                            <Autocomplete
                                                multiple
                                                disableCloseOnSelect
                                                limitTags={2}
                                                loading={emptyUsers() || users === undefined ? true : false}

                                                // value={userValue}
                                                // onChange={(event: any, newValue: string | null) => {
                                                //     setUserValue(newValue);
                                                // }}
                                                // inputValue={inputValue}
                                                // onInputChange={(event, newInputValue) => {
                                                //     setInputValue(newInputValue);
                                                // }}
                                                id="combo-box-demo"
                                                // options={users.map((option) => {
                                                //     let asd = option.deviceID
                                                //     return option.label + " " + "(" + option.phone + ")"
                                                // })}
                                                onChange={(event, value) => {
                                                    handeleSelectedUsers(value) //selected checkboxed users
                                                }
                                                }
                                                options={users}
                                                sx={{ width: 300 }}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionLabel={(option) => handleUserOptions(option)}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.label} ({option.phone})
                                                    </li>
                                                )}

                                                renderInput={(params) => <TextField {...params}
                                                    label={menuLabel}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {emptyUsers() ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />}
                                            />
                                        )
                                    }


                                    {/* <InputLabel sx={{ color: 'black', alignSelf: "center", marginLeft: 1, marginRight: 1 }} htmlFor="standard-adornment-amount">or</InputLabel> */}

                                    {autoComplete === 'Groups' && (
                                        <Autocomplete
                                            multiple
                                            disableCloseOnSelect
                                            limitTags={2}
                                            id="grouped-demo"
                                            options={!_.isEmpty(usergroupData) ? usergroupData : []}
                                            // value={scheduleUserDataType}
                                            //groupBy={(option) => option.groupName}
                                            getOptionLabel={(option) => handleGroupUserOptions(option)}
                                            onChange={(event, value) => {
                                                handleGroupSelectedUser(value) //selected checkboxed users
                                            }
                                            }
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        key={option.id}
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.groupName}
                                                </li>
                                            )}
                                            // renderGroup={(option) => (
                                            //     <>
                                            //         <Checkbox
                                            //             key={option.group}
                                            //             icon={icon}
                                            //             checkedIcon={checkedIcon}
                                            //             checked={checkGroup(option.group)}
                                            //             onChange={() => selectGroup(option.group)}
                                            //         />
                                            //         {option.group}
                                            //         {option.children}
                                            //     </>
                                            // )}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label="Patients By Group"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {_.isEmpty(usergroupData) ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />}

                                        />
                                    )}

                                </div>

                                <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
                                    <InputLabel sx={{ color: 'black', alignSelf: "center", marginRight: 4 }} htmlFor="standard-adornment-amount"> Subject*:</InputLabel>
                                    <FormControl variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-amount">{"Notification Header"}</InputLabel>

                                        <OutlinedInput
                                            id={"1"}
                                            value={scheduleName}
                                            onChange={(v) => setScheduleName(v.target.value)}
                                            aria-describedby="outlined-weight-helper-text"
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                            style={{ width: 300 }}
                                        />
                                    </FormControl>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
                                    <InputLabel sx={{ color: 'black', alignSelf: "center", marginRight: 3 }} htmlFor="standard-adornment-amount"> Message:</InputLabel>
                                    <TextareaAutosize
                                        aria-label="minimum height"
                                        value={scheduleDesc}
                                        placeholder={"Type Notification message here"}
                                        onChange={(v) => setScheduleDesc(v.target.value)}
                                        minRows={8}
                                        maxLength={250}
                                        style={{ width: 290 }} onReset={undefined} onResetCapture={undefined} onResize={undefined} onResizeCapture={undefined} />
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
                                    <InputLabel sx={{ color: 'black', alignSelf: "center", marginRight: 3 }} htmlFor="standard-adornment-amount"> Attachment:</InputLabel>
                                    <Button
                                        startIcon={<Icon icon="tdesign:file-attachment" color="black" width="20" />}
                                        style={{ marginRight: 10, backgroundColor: '#F0F0F0', color: 'black' }} onClick={() => {
                                            setOpenFilemanager(true) //tdesign:file-attachment
                                        }}

                                    >Attach File</Button>
                                    <IonLabel
                                        style={{
                                            marginLeft: 10,
                                            marginRight: 10,
                                            // width: '50%',
                                            borderRadius: 12,
                                            color: 'blue',
                                            alignSelf: 'center'
                                        }} >{selectedFileName}</IonLabel>
                                    {isFilePicked && (
                                        <Icon
                                            onClick={handleCancelFile}
                                            icon="ic:round-cancel"
                                            color="red"
                                            width="20"
                                            style={{ alignSelf: 'center' }} />
                                    )}
                                </div>
                            </div>

                            <div style={{ flexDirection: 'row', marginLeft: -10, marginTop: 30 }}>
                                <Checkbox checked={responseChecked} onChange={() => setResponseChecked(!responseChecked)} />
                                <IonLabel style={{ color: "black", backgroundColor: "white", fontWeight: 'bold', marginRight: 20 }}>Require Response</IonLabel>
                                <Select
                                    disabled={!responseChecked}
                                    value={responseType}
                                    onChange={(event: SelectChangeEvent) => setResponseType(event.target.value)}
                                    displayEmpty
                                //inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value="">
                                        <em>Response Type</em>
                                    </MenuItem>
                                    <MenuItem value={ResponseType.YesNO}>{ResponseType.YesNO}</MenuItem>
                                    <MenuItem value={ResponseType.AcceptDeny}>{ResponseType.AcceptDeny}</MenuItem>
                                </Select>
                            </div>
                        </IonCardContent>
                    </IonCard>

                    <IonCard style={{ width: "100%", backgroundColor: "white" }}>
                        <IonCardHeader>
                            <IonItem>
                                <IonTitle style={{ color: "black", backgroundColor: "white" }}>Frequency</IonTitle>
                            </IonItem>
                        </IonCardHeader>
                        <IonCardContent>

                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <FormControl>
                                    <FormLabel id="demo-controlled-radio-buttons-group">Schedule:</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={value}
                                        onChange={handleChange}
                                    >
                                        <div style={{ flexDirection: "row" }}>
                                            <FormControlLabel value="SendImmediately" control={<Radio />} label="Send Immediately" />
                                            <FormControlLabel value="delay" control={<Radio />} label="Delay" />
                                            <FormControlLabel value="schedule" control={<Radio />} label="Schedule" />
                                        </div>
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            {value === "delay" && (
                                <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, marginTop: 30 }}>
                                    <InputLabel sx={{ color: 'black', alignSelf: "center", marginRight: 2 }} htmlFor="standard-adornment-amount">Send this notification after:</InputLabel>
                                    <InputLabel sx={{ color: 'black', alignSelf: "center", marginRight: 2 }} htmlFor="standard-adornment-amount">Every*</InputLabel>
                                    <FormControl variant="outlined">
                                        <div style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}>
                                            <TextField
                                                required
                                                value={interval}
                                                error={isNumTextValid()}
                                                helperText={isNumTextValid() ? "Enter Interval > 0" : ""}
                                                id="outlined-number"
                                                label="Interval"
                                                type="number"
                                                onChange={(n) => setInterval(n.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>

                                    </FormControl>
                                    <InputLabel sx={{ color: 'black', alignSelf: "center", marginRight: 2 }} htmlFor="standard-adornment-amount">Repeat*</InputLabel>
                                    <Autocomplete
                                        id="combo-box-frequency"
                                        options={frequency}
                                        onChange={handleFreq}
                                        sx={{ width: 200, marginTop: 3 }}
                                        getOptionLabel={(option) => option}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option}
                                            </Box>
                                        )}
                                        renderInput={(params) => <TextField {...params} label="Frequency" />}
                                    />
                                    <InputLabel sx={{ color: 'black', alignSelf: "center", marginLeft: 2 }} htmlFor="standard-adornment-amount">For*</InputLabel>
                                    <FormControl variant="outlined">
                                        <div style={{ marginLeft: 10, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <div style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}>
                                                <TextField
                                                    required
                                                    value={count}
                                                    error={isCountTextValid()}
                                                    helperText={isCountTextValid() ? "Enter Count > 0" : ""}
                                                    id="outlined-number"
                                                    label="Count"
                                                    type="number"
                                                    onChange={(n) => setCount(n.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </div>
                                            <label style={{ marginTop: 10 }}>{"time(s)"}</label>
                                        </div>

                                    </FormControl>
                                </div>
                            )}

                            {value === "schedule" && (
                                <div style={{ display: "flex", flexDirection: "column", marginBottom: 10, marginTop: 30 }}>
                                    <div style={{ marginLeft: 10, marginBottom: 10, display: "flex", flexDirection: "column", }}>

                                        <LocalizationProvider dateAdapter={AdapterDateFns}>

                                            <div style={{ display: "flex", flexDirection: "row", }}>

                                                <InputLabel sx={{ color: 'black', alignSelf: "center", overflow: "visible", marginRight: 1 }} > Start Date*:</InputLabel>

                                                <div style={{ marginRight: 10, display: "flex", flexDirection: "column" }}>
                                                    <label>Enter Start Date*</label>
                                                    <input
                                                        onChange={handleStartDate}
                                                        value={startDateString}
                                                        required={true}
                                                        style={{
                                                            marginBottom: 10,
                                                            height: 50,
                                                            width: 180,
                                                            color: "black",
                                                            borderColor: "grey",
                                                            borderWidth: 1,
                                                            backgroundColor: "white",
                                                            borderRadius: 5
                                                        }}
                                                        type="date"
                                                        name="date start" />
                                                </div>

                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <label>Enter Start Time*</label>
                                                    <input
                                                        onChange={handleStarTime}
                                                        value={startTimeString}
                                                        required={true}
                                                        style={{
                                                            marginBottom: 10,
                                                            height: 50,
                                                            width: 180,
                                                            color: "black",
                                                            borderColor: "grey",
                                                            borderWidth: 1,
                                                            backgroundColor: "white",
                                                            borderRadius: 5
                                                        }}
                                                        type="time"
                                                        name="time start" />
                                                </div>
                                            </div>

                                            <div style={{ display: "flex", flexDirection: "row", }}>
                                                <InputLabel sx={{ color: 'black', alignSelf: "center", overflow: "visible", marginRight: 2 }} > End Date*:</InputLabel>
                                                <div style={{ display: "flex", flexDirection: "column", marginRight: 10, }}>
                                                    <label>Enter End Date*</label>
                                                    <input
                                                        onChange={handleEndDate}
                                                        value={EndDateString}
                                                        required={true}
                                                        style={{
                                                            marginBottom: 10,
                                                            height: 50,
                                                            width: 180,
                                                            color: "black",
                                                            borderColor: "grey",
                                                            borderWidth: 1,
                                                            backgroundColor: "white",
                                                            borderTopColor: "grey",
                                                            borderTopWidth: 1,
                                                            borderRadius: 5
                                                        }}
                                                        type="date"
                                                        name="date end" />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <label>Enter End Time*</label>
                                                    <input
                                                        onChange={handleEndTime}
                                                        value={EndTimeString}
                                                        required={true}
                                                        style={{
                                                            marginBottom: 10,
                                                            height: 50,
                                                            width: 180,
                                                            color: "black",
                                                            borderColor: "grey",
                                                            borderWidth: 1,
                                                            backgroundColor: "white",
                                                            borderRadius: 5
                                                        }}
                                                        type="time"
                                                        name="time end" />
                                                </div>
                                            </div>
                                        </LocalizationProvider>
                                    </div>

                                    <div style={{ marginLeft: 10, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <InputLabel sx={{ color: 'black', alignSelf: "center", overflow: "visible", marginRight: 4 }} > Repeat*: </InputLabel>

                                        <div style={{ marginRight: 10 }}>
                                            <Autocomplete
                                                id="combo-box-frequency"
                                                options={frequency}
                                                onChange={handleFreq}
                                                sx={{ width: 200 }}
                                                getOptionLabel={(option) => option}
                                                renderOption={(props, option) => (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        {option}
                                                    </Box>
                                                )}
                                                renderInput={(params) => <TextField {...params} label="Frequency" />}
                                            />
                                        </div>

                                        {schedulePeriod === RRule.WEEKLY && (
                                            <label>on</label>

                                        )}
                                        {schedulePeriod === RRule.WEEKLY && (
                                            <div style={{ display: "flex", flexDirection: "row", marginLeft: 10 }} >
                                                <FormControl sx={{ m: 1, width: 300 }}>
                                                    <InputLabel id="demo-multiple-checkbox-label">Select Days</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="demo-multiple-checkbox"
                                                        multiple
                                                        value={dayPeriod}
                                                        onChange={handleDayChange}
                                                        input={<OutlinedInput label="Select Days" />}
                                                        renderValue={(selected) => selected.join(', ')}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {days.map((name) => (
                                                            <MenuItem key={name} value={name}>
                                                                <Checkbox checked={dayPeriod.indexOf(name) > -1} />
                                                                <ListItemText primary={name} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        )}
                                    </div>
                                    <div style={{ marginLeft: 10, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <InputLabel sx={{ color: 'black', alignSelf: "center", overflow: "visible", marginRight: 1 }} > Recur Every*: </InputLabel>
                                        <div style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}>
                                            <TextField
                                                required
                                                value={deliveryInterval}
                                                error={isNumTextValid()}
                                                helperText={isNumTextValid() ? "Enter Interval > 0" : ""}
                                                id="outlined-number"
                                                label="Interval"
                                                type="number"
                                                onChange={(n) => setDeliveryInterval(n.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                        <label style={{ marginTop: 10 }}>{handleDayLabel()}</label>
                                    </div>
                                </div>
                            )}
                        </IonCardContent>
                    </IonCard>
                    <Button onClick={handleSendSchedule} style={{ margin: 20 }} variant="contained">Submit</Button>
                    <Button onClick={handleGetScheduleRule} style={{ margin: 20 }} variant="contained">Show Schedules</Button>

                </IonCard>

                {showScheduleList && (
                    <IonCard ref={firstItemRef} style={{ width: "100%", backgroundColor: "white" }}>

                        <IonCardHeader>
                            <div style={{ display: "flex", justifyContent: 'center' }}>
                                <h2 style={{ color: "black", alignSelf: "center" }}>Schedules History</h2>
                            </div>
                        </IonCardHeader>
                        <IonCardContent>
                            <ThemeProvider theme={defaultMaterialTheme}>
                                <MaterialTable
                                    style={{ width: " 100%" }}
                                    title=""
                                    icons={tableIcons}
                                    columns={columns}
                                    options={{
                                        pageSizeOptions: [5],
                                    }}
                                    // isLoading={row.length === 0 || row === null}
                                    //page={5}
                                    // data={row.length === 0 || row === null ? [] : row}   
                                    data={query => {
                                        return new Promise((resolve, reject) => {
                                            getScheduleRules(query.page + 1, query.pageSize).then((result) => {
                                                resolve({
                                                    data: result.data,
                                                    page: result.currentPage - 1,
                                                    totalCount: result.total,
                                                })

                                            }).catch(error => alert('Something went wrong. Please try again.'))
                                        })
                                    }
                                    }

                                    detailPanel={[
                                        {
                                            tooltip: 'Click to show details',
                                            render: rowData => {
                                                return (
                                                    <ScheduleDetails data={rowData} />
                                                )
                                            },
                                        },
                                    ]}

                                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                                />
                            </ThemeProvider>

                        </IonCardContent>
                    </IonCard>
                )
                }
            </IonContent >

            <Dialog
                open={openFilemanager}
                onClose={handleCloseFileManager}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <div style={{ display: "flex", flexDirection: 'column', height: window.innerHeight / 2, width: window.innerWidth }}>
                    <DialogContent style={{ height: window.innerHeight / 2, width: window.innerWidth }}>
                        <FileManager closeDialog={handleCloseFileManager} chooseFile={chooseFile} isModal={true} />
                    </DialogContent>
                </div>
            </Dialog>

            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Deleting a Scheduled Notification"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this scheduled notification?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>No</Button>
                        <Button onClick={handleYes} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </IonPage >


    )
}
export default Notifications;
