import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonAvatar,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonSpinner,
  IonLoading,
  IonHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonTitle,
  IonIcon,
  IonRouterLink,
  IonSegment,
  IonContent,
  IonListHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import {
  ApplicationContext,
  ProviderContext,
  ProviderContextProvider,
} from "../ApplicationState";
import * as ApiService from "../services/ApiService";
import * as Helpers from "../services/Helpers";
import { useHistory, useLocation } from "react-router";
import { IUserProps } from "../models/user";
import {
  ArrowForwardCircleSharp,
  BanSharp,
  CheckmarkCircleSharp,
  CheckmarkDoneSharp,
  CloseCircleSharp,
  CloudDownloadSharp,
  CreateSharp,
  PeopleSharp,
} from "react-ionicons";
import saveAs from "save-as";
import {
  arrowBackSharp,
  arrowDown,
  arrowForward,
  arrowForwardCircle,
  arrowForwardSharp,
  arrowUp,
  checkmarkDoneCircleOutline,
  closeCircleOutline,
  expandSharp,
  happyOutline,
  key,
  sadOutline,
} from "ionicons/icons";
import "./Components.css";
import { convertArrayToObject } from "../services/Helpers";
import moment from "moment";
import { useUserProfile } from "../pages/UserProfile/useUserProfile";

const UsersComponent: React.FC<any> = (props: any) => {
  const providerContext = useContext(ProviderContext);
  const history = useHistory();
  const { fetchSelectedUserMeasurement } = useUserProfile();
  const location: any = useLocation();
  const local = useLocation();
  const { userState } = useContext(ApplicationContext);
  const { providerState } = useContext(ProviderContext);
  const [searchText, setSearchText] = React.useState("");
  const [users, setUsers] = React.useState([]);
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [tempsearchtext, setTempsearchtext] = React.useState("");
  const [showLoading, setShowLoading] = React.useState(true);
  const [type, setType] = React.useState("");
  const [sortKey, setSortKey] = React.useState("name");
  const [sortDirection, setSortDirection] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [disableInfiniteScroll, setDisableInfiniteScroll] =
    useState<boolean>(false);

  useEffect((): any => {
    if (searchText !== "") return
    getUsersfromAPi()
  }, [providerState.providerId, sortKey, sortDirection, searchText]);

  const containsNumber = (str) => {
    // Check if the string contains any digit between 0 and 9
    return /\d/.test(str);
  }

  async function onUserSelectionChange($event, user) {
    //history.push("/readings",{selectedUser:user});

    let toUpdate: IUserProps = {};
    toUpdate.id = user.username;
    toUpdate.username = user.username;
    toUpdate.providerCode = user.attributes.find(
      (r) => r.name === "custom:providercode"
    ).value;
    toUpdate.allowAdminAccess = true;
    toUpdate.roles = user.roles;
    if (user) {
      history.push("/userprofile", { userId: user.id });
    }
  }

  let timer: any;
  function onSearchTextChange(searchTerm: string) {
    clearTimeout(timer);
    setSearchText(searchTerm);

    // timer = setTimeout(() => {
    //   setSortKey("");
    //   setSearchText(searchTerm);
    // }, 2000);



    // setSearchText(searchTerm);
    // if (searchTerm === "") {
    //   setFilteredUsers(users);
    //   return;
    // }
    // //
    // setFilteredUsers(
    //   users.filter(
    //     (user) =>
    //       user.attributes
    //         .find((r) => r.name === "email")
    //         .value.toLowerCase()
    //         .includes(searchTerm.toLowerCase()) ||
    //       user.attributes
    //         .find((r) => r.name === "given_name")
    //         .value.toLowerCase()
    //         .includes(searchTerm.toLowerCase()) ||
    //       user.attributes
    //         .find((r) => r.name === "family_name")
    //         .value.toLowerCase()
    //         .includes(searchTerm.toLowerCase()) ||
    //       user.attributes
    //         .find((r) => r.name === "phone_number")
    //         .value.toLowerCase()
    //         .includes(searchTerm.toLowerCase())
    //   )
    // );
  }

  function onUserActivationClick($event, user) {
    var result = convertArrayToObject(user.attributes, "name");
    result.enabled = user.enabled;

    history.push("/updateprofile", { user: result });
  }

  const rediect = () => {
    if (type === "Users") history.push("/users", { type: "Users" });
    else history.push("/patients", { type: "Patients" });
  };

  function sort(key, direction) {
    if (direction == 1) {
      var sfilteredReadings = filteredUsers.sort((a, b) => {
        const valA = a[key];
        const valB = b[key];
        return valA.localeCompare(valB);
      });
      setFilteredUsers(sfilteredReadings);
    } else if (direction == 2) {
      var sfilteredReadings = filteredUsers.sort((a, b) => {
        const valA = a[key];
        const valB = b[key];
        return valB.localeCompare(valA);
      });
      setFilteredUsers(sfilteredReadings);
    } else {
      setSortDirection(0);
      setSortKey("");
      setFilteredUsers(users);
    }
  }

  const sortBy = (key) => {
    setPageNumber(1);
    if (key == sortKey) {
      setSortDirection((sortDirection % 2) + 1);
      setSortKey(key);
    } else {
      setSortDirection(1);
      setSortKey(key);
    }
  };

  const inputRef = useRef<any>(null);
  const [disableNextButton, setDisableNextButton] = useState<boolean>(false);
  const [disablePrevButton, setDisablePrevButton] = useState<boolean>(false);
  async function LoadNextData($event: CustomEvent<void>) {
    //setPageNumber(pageNumber + 1);

    let userType: any = props.type;
    if (location.state?.type !== undefined) userType = location.state?.type;

    if (providerState !== undefined) {
      if (
        providerState.providerId !== undefined &&
        providerState.providerId !== ""
      ) {
        setShowLoading(true);
        ApiService.fetchUsers(
          userState.authenticatedUser,
          providerState.providerId,
          pageSize,
          pageNumber,
          null,
          null,
          sortKey,
          sortDirection,
          searchText,
          userType
        ).then((data) => {
          if (data && data.length > 0) {
            setUsers(data);
            setFilteredUsers(data);
            setDisableInfiniteScroll(data.length < pageSize);
            setDisableNextButton(data.length < pageSize);
            if (data.length >= pageSize) setPageNumber(pageNumber + 1);
            else setPageNumber(pageNumber - 1);
          } else {
            setDisableInfiniteScroll(true);
            setDisableNextButton(true);
          }
          setShowLoading(false);
          setDisablePrevButton(pageNumber === 1);
          setTimeout(() => inputRef.current.setFocus(), 200);
        });
      }
    }
    //($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  async function LoadPreviousData($event: CustomEvent<void>) {
    // if (pageNumber == 0) return;
    // else setPageNumber(pageNumber - 1);

    let userType: any = props.type;
    if (location.state?.type !== undefined) userType = location.state?.type;

    if (providerState !== undefined) {
      if (
        providerState.providerId !== undefined &&
        providerState.providerId !== ""
      ) {
        setShowLoading(true);
        ApiService.fetchUsers(
          userState.authenticatedUser,
          providerState.providerId,
          pageSize,
          pageNumber,
          null,
          null,
          sortKey,
          sortDirection,
          searchText,
          userType
        ).then((data) => {
          if (data && data.length > 0) {
            setUsers(data);
            setFilteredUsers(data);
            setDisableInfiniteScroll(data.length < pageSize);
            setDisableNextButton(data.length < pageSize);
            if (pageNumber > 1) setPageNumber(pageNumber - 1);
            else setPageNumber(pageNumber + 1);
          } else {
            setDisableInfiniteScroll(true);
            setDisableNextButton(true);
          }
          setShowLoading(false);
          setDisablePrevButton(pageNumber === 1);
          setTimeout(() => inputRef.current.setFocus(), 200);
        });
      }
    }
    //($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  function downloadUserXls() {
    let userType: any = props.type;
    setShowLoading(true)
    ApiService.fetchUserXls(
      userState.authenticatedUser,
      providerState.providerId,
      null,
      null,
      null,
      null,
      sortKey,
      sortDirection,
      searchText,
      userType
    ).then((data) => {
      setShowLoading(false)
      if (data) {
        const downloadedFile = Helpers.base64ToBlob(
          data.fileContents,
          data.contentType
        );
        saveAs(downloadedFile);
      }
    }).catch((err) => {
      setShowLoading(false)
      alert('Downloading failed. Please try again.')
      console.log("error downloadUserXls system user")
    })
  }

  const getUsersfromAPi = () => {
    let isSubscribed = true;
    let user = userState.authenticatedUser;
    let pageNum = 1;
    setPageNumber(1);
    prevbtnref.current?.setAttribute("hidden", "true");
    nextbtnref.current?.setAttribute("hidden", "true");
    let userType: any = props.type;

    const isNumber = containsNumber(searchText)

    if (location.state?.type !== undefined) userType = location.state?.type;

    if (providerState !== undefined) {
      if (
        providerState.providerId !== undefined &&
        providerState.providerId !== ""
      ) {
        setShowLoading(users.length > 0 && searchText === "" && tempsearchtext === "" ? false : true);
        setType(userType);
        ApiService.fetchUsers(
          userState.authenticatedUser,
          providerState.providerId,
          pageSize,
          pageNum,
          null,
          null,
          sortKey,
          sortDirection,
          searchText,
          userType,
          isNumber ? 'number' : 'string',
        ).then((data) => {
          if (isSubscribed) {
            if (data) {
              setPageNumber(pageNumber + 1);
              setUsers(data);
              setFilteredUsers(data);
              setTempsearchtext(searchText)
              setDisableInfiniteScroll(data.length < pageSize);
              setDisableNextButton(data.length < pageSize);
            }
            if (data.length == pageSize) {
              prevbtnref.current?.removeAttribute("hidden");
              nextbtnref.current?.removeAttribute("hidden");
            }
            setShowLoading(false);
            setDisablePrevButton(pageNumber === 1);
          }
        }).catch((err) => {
          setShowLoading(false);
          alert('Something went wrong. Please try again.')
        })
      }
    }
    return () => (isSubscribed = false);
  }

  const search = () => {
    if (searchText !== "") {
      getUsersfromAPi()
    }
  }

  const prevbtnref = useRef<HTMLIonButtonElement>(null);
  const nextbtnref = useRef<HTMLIonButtonElement>(null);

  return (
    <IonCard>
      <IonCardHeader>
        <IonItem>
          <IonTitle hidden={location.pathname === "/users"}>
            List of {Helpers.getDisplayLabel(providerState)}
          </IonTitle>
          <IonTitle
            hidden={
              location.pathname === "/patients" ||
              location.pathname === "/home" ||
              location.pathname === "/"
            }
          >
            List of System Users
          </IonTitle>
          <IonIcon
            hidden={
              location.pathname === "/patients" ||
                location.pathname === "/users"
                ? true
                : false
            }
            icon={expandSharp}
            slot="end"
            onClick={() => {
              rediect();
            }}
          />
        </IonItem>
      </IonCardHeader>
      <IonCardContent>
        <IonRow>
          <IonItem className="col">
            <IonSearchbar
              value={searchText}
              onIonChange={(e) => onSearchTextChange(e.detail.value!)}
              ref={(ref) => (inputRef.current = ref)}
              showCancelButton="always"
              onKeyDown={(event) => {
                if (event.code === 'Enter') {
                  search()
                }
              }}
            ></IonSearchbar>
            <IonButton onClick={() => search()}>Search</IonButton>
          </IonItem>
          <IonItem
            className="col"
            hidden={location.pathname === "/home" ? true : false}
          >
            <IonButton onClick={() => downloadUserXls()} slot="end">
              Xls&nbsp;&nbsp;&nbsp;
              <CloudDownloadSharp
                color={"#00000"}
                title="Download xls"
                height="15px"
                width="15px"
              />
            </IonButton>
          </IonItem>
        </IonRow>

        {/* <IonGrid>
          <IonRow color="primary">
            <IonCol className="col-header col-2">
              <IonLabel></IonLabel>
            </IonCol>
            <IonCol className="col-header col-3">
              <IonLabel>Name</IonLabel>
            </IonCol>
            <IonCol className="col-header col-3">
              <IonLabel>Username</IonLabel>
            </IonCol>
            <IonCol className="col-header d-none">
              <IonLabel>Phone</IonLabel>
            </IonCol>
            <IonCol className="d-none">
              <IonLabel>Provider</IonLabel>
            </IonCol>
            <IonCol className="col-header col-2">
              <IonLabel>Role</IonLabel>
            </IonCol>
            <IonCol className="col-header col-2">
              <IonLabel>Actions</IonLabel>
            </IonCol>
          </IonRow>
          {filteredUsers?.map((a) => {
            let isDisabled: boolean = a["enabled"];
            let attributes: any[] = a["attributes"];
            let fullName = (
              attributes.find((r) => r.name == "given_name").value +
              " " +
              attributes.find((r) => r.name == "family_name").value
            ).replace(/\b(\w)/g, (s) => s.toUpperCase());
            let email = attributes.find((r) => r.name == "email");
            let fname = attributes.find((r) => r.name == "given_name");
            let phone = attributes.find((r) => r.name == "phone_number");
            let lname = attributes.find((r) => r.name == "family_name");
            let groups = attributes.find(
              (r) => r.name == "custom:providercode"
            );
            let roles = attributes.find((r) => r.name == "custom:Roles");
            let img = attributes.find((r) => r.name == "picture");
            let image = "/assets/img/noimageavailable.png";
            if (
              img.value !== "" &&
              img.value !== undefined &&
              img.value !== null
            ) {
              image =
                "data:image/jpeg;base64," +
                attributes.find((r) => r.name == "picture")?.value;
            }
            return (
              <IonRow
                className="link"
                key={a["username"]}
                onClick={(e) => onUserActivationClick(e, a)}
              >
                <IonCol>
                  <IonItem>
                    <IonAvatar className="avatar-thumb">
                      <img src={image} />
                    </IonAvatar>
                    {isDisabled ? (
                      <CheckmarkCircleSharp
                        cssClasses="icons"
                        color={"#00cc44"}
                        title="Active"
                        height="16px"
                        width="16px"
                        onClick={(e) => onUserActivationClick(e, a)}
                      />
                    ) : (
                      <CloseCircleSharp
                        cssClasses="icons"
                        color={"#ff1a1a"}
                        title="Inactive"
                        height="16px"
                        onClick={(e) => onUserActivationClick(e, a)}
                        width="16px"
                      />
                    )}
                  </IonItem>
                </IonCol>
                <IonCol className="col-3">
                  <IonItem>
                    <IonLabel>{fullName}</IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol className="col-3">
                  <IonItem>
                    <IonLabel>
                      <i>{email.value}</i>
                    </IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol className="d-none">
                  <IonItem>
                    <IonLabel>
                      <i>{phone?.value}</i>
                    </IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol className="d-none">
                  <IonItem>
                    <IonLabel>
                      <i>{groups?.value}</i>
                    </IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol className="col-2">
                  {props.type === "Users" ? (
                    <IonItem>
                      <IonLabel>
                        <i>{roles?.value}</i>
                      </IonLabel>
                    </IonItem>
                  ) : (
                    <IonItem>
                      <IonLabel>
                        <i>Patient</i>
                      </IonLabel>
                    </IonItem>
                  )}
                </IonCol>
                <IonCol className="col-2">
                  <IonItem>
                    <CreateSharp
                      cssClasses="icons"
                      color={"#ff751a"}
                      title="Change roles"
                      height="32px"
                      width="32px"
                      onClick={(e) => onUserActivationClick(e, a)}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
            );
          })}
        </IonGrid> */}
        <IonListHeader color="primary">
          <IonLabel
            className={
              location.pathname === "/home" ? "col-1 link d-none" : "col-1 link"
            }
          ></IonLabel>
          <IonLabel className="col-2 link" onClick={() => sortBy("name")}>
            <u>Name</u>&nbsp;
            {sortKey == "name" ? (
              sortDirection % 2 == 0 ? (
                <IonIcon icon={arrowUp} />
              ) : (
                <IonIcon icon={arrowDown} />
              )
            ) : (
              ""
            )}
          </IonLabel>
          <IonLabel
            className={
              location.pathname === "/home" ? "col-4 link" : "col-2 link"
            }
            onClick={() => sortBy("username")}
          >
            <u>Username</u>&nbsp;
            {sortKey == "username" ? (
              sortDirection % 2 == 0 ? (
                <IonIcon icon={arrowUp} />
              ) : (
                <IonIcon icon={arrowDown} />
              )
            ) : (
              ""
            )}
          </IonLabel>
          <IonLabel className="col-2">Phone</IonLabel>
          <IonLabel
            className="col-2 link"
            onClick={() => sortBy("created")}
            hidden={location.pathname === "/home" ? true : false}
          >
            <u>Created</u>&nbsp;
            {sortKey == "created" ? (
              sortDirection % 2 == 0 ? (
                <IonIcon icon={arrowUp} />
              ) : (
                <IonIcon icon={arrowDown} />
              )
            ) : (
              ""
            )}
          </IonLabel>
          <IonLabel
            className="col d-none link"
            onClick={() => sortBy("providercode")}
          >
            <u>Provider</u>&nbsp;
            {sortKey == "providercode" ? (
              sortDirection % 2 == 0 ? (
                <IonIcon icon={arrowUp} />
              ) : (
                <IonIcon icon={arrowDown} />
              )
            ) : (
              ""
            )}
          </IonLabel>
          {props.type === "Users" ? (
            <IonLabel className="col-2 link" onClick={() => sortBy("role")}>
              <u>Role</u>&nbsp;
              {sortKey == "role" ? (
                sortDirection % 2 == 0 ? (
                  <IonIcon icon={arrowUp} />
                ) : (
                  <IonIcon icon={arrowDown} />
                )
              ) : (
                ""
              )}
            </IonLabel>
          ) : (
            <IonLabel className="col-2 d-none">Role</IonLabel>
          )}
          <IonLabel className="col-1"></IonLabel>
        </IonListHeader>
        {location.pathname !== "/configuration/1" ? (
          <IonList color="primary" slot="start">
            {filteredUsers?.map((a) => {
              let isDisabled: boolean = a["enabled"];

              let attributes: any[] = a["attributes"];
              let fullName = (
                attributes.find((r) => r.name == "given_name").value +
                " " +
                attributes.find((r) => r.name == "family_name").value
              ).replace(/\b(\w)/g, (s) => s.toUpperCase());
              let email = attributes.find((r) => r.name == "email");
              let fname = attributes.find((r) => r.name == "given_name");
              let phone = attributes.find((r) => r.name == "phone_number");
              let lname = attributes.find((r) => r.name == "family_name");
              let createdDate = a["userCreateDate"];

              let groups = attributes.find(
                (r) => r.name == "custom:providercode"
              );
              let roles = attributes.find((r) => r.name == "custom:Roles");
              let img = attributes.find((r) => r.name == "picture");

              let image = "/assets/img/noimageavailable.png";
              if (
                img.value !== "" &&
                img.value !== undefined &&
                img.value !== null
              ) {
                image =
                  "data:image/jpeg;base64," +
                  attributes.find((r) => r.name == "picture")?.value;
              }
              return (
                <IonItem key={a["username"]} className="row">
                  <IonItem
                    onClick={(e) => onUserSelectionChange(e, a)}
                    className={
                      location.pathname === "/home"
                        ? "col-1 link d-none"
                        : "col-1 link"
                    }
                  >
                    <IonAvatar className="avatar-thumb">
                      <img src={image} />
                    </IonAvatar>
                    {isDisabled ? (
                      <CheckmarkCircleSharp
                        cssClasses="icons"
                        color={"#00cc44"}
                        title="Active"
                        height="16px"
                        width="16px"
                        onClick={(e) => onUserActivationClick(e, a)}
                      />
                    ) : (
                      <CloseCircleSharp
                        cssClasses="icons"
                        color={"#ff1a1a"}
                        title="Inactive"
                        height="16px"
                        onClick={(e) => onUserActivationClick(e, a)}
                        width="16px"
                      />
                    )}
                  </IonItem>

                  <IonLabel
                    style={{ color: 'rgb(61, 116, 221)' }}
                    className="col-2 link text-wrap underlined"
                    onClick={(e) => onUserSelectionChange(e, a)}
                  >
                    {fullName}
                  </IonLabel>
                  <IonLabel
                    className={
                      location.pathname === "/home"
                        ? "col-4 link"
                        : "col-2 link"
                    }
                    onClick={(e) => onUserSelectionChange(e, a)}
                  >
                    <i>{email.value}</i>
                  </IonLabel>
                  <IonLabel
                    className="col-2 link"
                    onClick={(e) => onUserSelectionChange(e, a)}
                  >
                    <i>{phone?.value}</i>
                  </IonLabel>
                  <IonLabel
                    hidden={location.pathname === "/home" ? true : false}
                    className="col-2 link"
                    onClick={(e) => onUserSelectionChange(e, a)}
                  >
                    <i>
                      {moment(createdDate).local().format("YYYY-MM-DD hh:mm a")}
                    </i>
                  </IonLabel>
                  <IonLabel
                    className="col-2 link d-none"
                    onClick={(e) => onUserSelectionChange(e, a)}
                  >
                    <i>{groups?.value}</i>
                  </IonLabel>
                  {props.type === "Users" ? (
                    <IonLabel
                      className="col-2 link"
                      onClick={(e) => onUserSelectionChange(e, a)}
                    >
                      <i>
                        {roles?.value}{" "}
                        {roles?.value == "Provider"
                          ? "(" + groups?.value + ")"
                          : ""}
                      </i>
                    </IonLabel>
                  ) : (
                    <IonLabel
                      className="col-1 link  d-none"
                      onClick={(e) => onUserSelectionChange(e, a)}
                    >
                      <i>{Helpers.getDisplayLabel(providerState)}</i>
                    </IonLabel>
                  )}
                  <IonItem className="">
                    {/* {isDisabled ? (
                    <CheckmarkDoneSharp
                      cssClasses="icons"
                      color={"#00cc44"}
                      title="Active"
                      height="32px"
                      width="32px"
                      onClick={(e) => onUserActivationClick(e, a)}
                    />
                  ) : (
                    <BanSharp
                      cssClasses="icons"
                      color={"#ff1a1a"}
                      title="Inactive"
                      height="32px"
                      onClick={(e) => onUserActivationClick(e, a)}
                      width="32px"
                    />
                  )}

                  <ArrowForwardCircleSharp
                    cssClasses="icons"
                    color={"#3366ff"}
                    title="View Details"
                    height="32px"
                    width="32px"
                    onClick={(e) => onUserSelectionChange(e, a)}
                  />

                  <PeopleSharp
                    cssClasses="icons"
                    color={"#ff751a"}
                    title="Change roles"
                    height="32px"
                    width="32px"
                    onClick={(e) => onUserActivationClick(e, a)}
                  /> */}
                    <CreateSharp
                      cssClasses="icons"
                      color={"#ff751a"}
                      title="Change roles"
                      height="28px"
                      width="28px"
                      onClick={(e) => onUserActivationClick(e, a)}
                    />
                  </IonItem>
                </IonItem>
              );
            })}
          </IonList>
        ) : (
          <></>
        )}
        {/* <IonInfiniteScroll
          threshold="10px"
          disabled={disableInfiniteScroll}
          onIonInfinite={(e: CustomEvent<void>) => LoadMoreData(e)}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll> */}
        <IonButton
          ref={prevbtnref}
          onClick={(e: any) => LoadPreviousData(e)}
          className="primary"
          disabled={disablePrevButton}
        >
          Previous
        </IonButton>
        <IonButton
          ref={nextbtnref}
          onClick={(e: any) => LoadNextData(e)}
          className="primary"
          disabled={disableNextButton}
        >
          Next
        </IonButton>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
      </IonCardContent>
    </IonCard>
  );
};

export default UsersComponent;
