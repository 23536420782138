import * as React from "react";
import {
    DataGrid,
    GridColDef,
    GridRowSelectionModel,
} from "@mui/x-data-grid";
import { FC } from "react";
var _ = require('lodash');

const columns: GridColDef[] = [
    {
        field: "groupName",
        headerName: "Group Name",
        width: 200,
        editable: false,
    },
    {
        field: "groupDescription",
        headerName: "Group Description",
        width: 400,
        editable: false,
    },
];

export type GroupThresholdProps = {
    usergroupData: any;
    handleGroupsSelection: (value: any) => void;
    setRowSelectionModel: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
    rowSelectionModel: GridRowSelectionModel;
};

export const GroupsThreshold: FC<GroupThresholdProps> = ({
    usergroupData,
    handleGroupsSelection,
    setRowSelectionModel,
    rowSelectionModel
}) => {

    return (
        <div style={{ height: 400, width: "100%" }}>
            <DataGrid
                sx={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    width: "100%",
                }}
                rows={usergroupData}
                autoHeight={true}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                localeText={{
                    noRowsLabel: "Nothing to display"
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                //onRowClick={handleRoute}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel)
                    handleGroupsSelection(newRowSelectionModel)
                }}
                rowSelectionModel={rowSelectionModel}
                disableRowSelectionOnClick
            />
        </div>
    );
};
