import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonAvatar,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCol,
  IonGrid,
  IonRow,
  useIonViewWillEnter,
  IonLoading,
  IonSplitPane,
  IonSpinner,
  IonProgressBar,
  IonContent,
  IonTitle,
  IonHeader,
  IonListHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonIcon,
  NavContext,
  IonDatetime,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  ApplicationContext,
  ProviderContextProvider,
} from "../ApplicationState";
import * as ApiService from "./../services/ApiService";
import { formatMeasurementType, formatReading } from "../services/Helpers";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroller";
import ReactPaginate from "react-paginate";
import { expandSharp, navigate } from "ionicons/icons";
import { useHistory } from "react-router";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
var _ = require("lodash");

const ReadingsComponent: React.FC<any> = (props: any) => {
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [NoteText, setNoteText] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [readings, setReadings] = React.useState<any[]>([]);
  const [filteredReadings, setFilteredReadings] = React.useState<any[]>([]);
  const { userState } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [disableLoadMoreButton, setDisableButton] = React.useState(false);
  const [disableInfiniteScroll, setDisableInfiniteScroll] =
    useState<boolean>(false);

  const [open, setOpen] = React.useState(false);
  const [note, setNote] = useState([]);

  const dateFormatString = "YYYY-MM-DD";
  let startDate = moment(new Date());
  let endDate = moment(new Date());
  const [selectedStartDate, setSelectedStartDate] = React.useState<string>(
    startDate?.add(-12, "months").format(dateFormatString)
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState<string>(
    endDate?.format(dateFormatString)
  );

  const handleClickOpen = (id, breachValues) => {
    let rowid = 0;
    if (breachValues != null) {
      const breach = breachValues.map((d) => {
        return d.reviewStatuses;
      });
      const breachNote = breach.map((v) => {
        if (!_.isEmpty(v)) {
          const note = v[0].note;
          const fullName = v[0].reviewedBy;
          const dateFromString = new Date(v[0].reviewedOn);
          const formatDate = format(dateFromString, "yyyy-MM-dd");
          const formatTime = format(dateFromString, "hh:mm a");
          const fullDate = formatDate + " " + formatTime;
          rowid = rowid + 1;
          setNoteText("");
          return { note, id: rowid, fullName, fullDate };
        } else {
          setNoteText("");
          return {
            note: "",
            id: "",
            fullName: "",
            fullDate: "",
          };
        }
      });
      setNote(_.reverse(breachNote));
    } else {
      setNoteText("");
      setNote([
        {
          note: "",
          id: "",
          fullName: "",
          fullDate: "",
        },
      ]);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function updateDisplayName(selectedUser) {
    let userAttributes: any = selectedUser;
    if (userAttributes !== undefined) {
      let given_name = userAttributes.given_name;
      let family_name = userAttributes.family_name;
      setDisplayName(given_name + " " + family_name);
      setShowLoading(false);
    }
  }

  useEffect((): any => {
    let isSubscribed = true;
    updateDisplayName(props.selectedUser);
    if (props.selectedUser.sub !== undefined) {
      let user = userState.authenticatedUser;

      ApiService.fetchUserReadings(
        user,
        props.selectedUser.sub,
        pageSize,
        pageNumber,
        null,
        null,
        null,
        null,
        null,
        null
      )
        .then((data) => {
          if (isSubscribed) {
            if (data && data.length > 0) {
              setPageNumber(pageNumber + 1);
              setReadings(data);

              if (data && data.length > 0) {
                setPageNumber(pageNumber + 1);
                setReadings(data);
                if (searchText === "") {
                  setFilteredReadings(data);
                } else {
                  let filtered = readings.filter((r) => {
                    return r.measurementType
                      .toLowerCase()
                      .includes(searchText.toLowerCase());
                  });
                  setFilteredReadings(filtered);
                }

                setDisableInfiniteScroll(data.length < pageSize);
              } else {
                setReadings(null);
                setDisableButton(true);
                setDisableInfiniteScroll(true);
              }

              //setDisableInfiniteScroll(data.length < pageSize);
            } else {
              setDisableInfiniteScroll(true);
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
    return () => (isSubscribed = false);
  }, [props.selectedUser]);

  async function fetchReadings($event: CustomEvent<void>) {
    let user = userState.authenticatedUser;
    if (props.selectedUser.sub !== undefined) {
      await ApiService.fetchUserReadings(
        user,
        props.selectedUser.sub,
        pageSize,
        pageNumber,
        moment(selectedStartDate).format(dateFormatString),
        moment(selectedEndDate).format(dateFormatString),
        null,
        null,
        null,
        null
      )
        .then((data) => {
          if (data && data.length > 0) {
            setPageNumber(pageNumber + 1);
            setReadings([...readings, ...data]);
            if (searchText === "") {
              setFilteredReadings([...readings, ...data]);
            } else {
              let filtered = data.filter((r) => {
                return r.measurementType
                  .toLowerCase()
                  .includes(searchText.toLowerCase());
              });
              setFilteredReadings([...readings, ...filtered]);
            }

            setDisableInfiniteScroll(data.length < pageSize);
          } else {
            setDisableInfiniteScroll(true);
          }
        })
        .catch((err) => {
          alert(err);
        });
      ($event.target as HTMLIonInfiniteScrollElement).complete();
    }
  }

  const onSearchTextChange = (searchTerm) => {
    setSearchText(searchTerm);
    if (searchTerm === "") {
      setFilteredReadings(readings);
      return;
    }
    //

    let filtered = readings.filter((r) => {
      return r.measurementType.toLowerCase().includes(searchTerm.toLowerCase());
    });

    setFilteredReadings(filtered);
  };

  const rediect = () => {
    history.push("/readings", { selectedUser: props.selectedUser });
  };

  let breachValues = [];

  const columns: GridColDef[] = [
    { field: "note", headerName: "Note", width: 150 },
    { field: "fullName", headerName: "Review BY", width: 200 },
    { field: "fullDate", headerName: "Review Date", width: 200 },
  ];

  return (
    <IonList>
      <IonItem>
        <h3>Displaying readings for {displayName}</h3>
      </IonItem>

      <IonListHeader lines="full">
        <IonItem>
          <IonSearchbar
            value={searchText}
            onIonChange={(e) => onSearchTextChange(e.detail.value!)}
            showCancelButton="always"
          ></IonSearchbar>
        </IonItem>
        <IonItem>
          <IonSelect placeholder="Sort By" onIonChange={onchange} slot="end">
            <IonSelectOption value="Measurement">Measurement</IonSelectOption>
            <IonSelectOption value="Date">Date</IonSelectOption>
          </IonSelect>
        </IonItem>
        {/* <IonItem>
          <IonLabel>Start Date:</IonLabel>

          <IonDatetime
            pickerFormat={dateFormatString}
            displayFormat={dateFormatString}
            value={selectedStartDate}
            onIonChange={(e) => setSelectedStartDate(e.detail.value!)}
          ></IonDatetime>
        </IonItem>
        <IonItem>
          <IonLabel>End Date:</IonLabel>

          <IonDatetime
            pickerFormat={dateFormatString}
            displayFormat={dateFormatString}
            placeholder="Select Date"
            value={selectedEndDate}
            onIonChange={(e) => setSelectedEndDate(e.detail.value!)}
          ></IonDatetime>
        </IonItem> */}
        <IonItem>
          <IonIcon
            icon={expandSharp}
            slot="end"
            onClick={(r) => {
              rediect();
            }}
          />
        </IonItem>
      </IonListHeader>
      <IonListHeader color="primary">
        <IonLabel className="col-2">Measurement Type</IonLabel>
        <IonLabel className="col-2">Value</IonLabel>
        <IonLabel className="col-2">Date</IonLabel>
        <IonLabel className="col-2 d-none d-lg-block">Is Manual</IonLabel>
        <IonLabel className="col-2 d-none d-lg-block">Device Model</IonLabel>
        <IonLabel className="col-2 d-none d-lg-block">Notes</IonLabel>
      </IonListHeader>
      {filteredReadings.map((a, index) => {
        const breachValues = a.breachValues;
        let isBreach = a.isBreach;
        let isAcknowledge = a.isAcknowledged;
        let measurementType: any[] = a.measurementType;
        let readingOn: any[] = a.readingOn;
        let reading = a.reading;
        let readingId = a.id;
        let utcTime = moment.utc(readingOn);
        let localTime = utcTime.local().format("MMM DD YYYY hh:mm A");
        return (
          <IonItem key={readingId} button onClick={() => {}} lines="full">
            <IonLabel
              className="col-2"
              style={{ color: isBreach ? "red" : "black" }}
            >
              {formatMeasurementType(a)}
            </IonLabel>
            <IonLabel
              className="col-2"
              style={{ color: isBreach ? "red" : "black" }}
            >
              <i>{formatReading(a)}</i>
            </IonLabel>
            <IonLabel
              className="col-2"
              style={{ color: isBreach ? "red" : "black" }}
            >
              <i>{localTime}</i>
            </IonLabel>
            {/* <IonButton href={readingId} color="primary" slot="end">View</IonButton> */}
            <IonLabel
              className="col-2 d-none d-lg-block"
              style={{ color: isBreach ? "red" : "black" }}
            >
              <i>{a.isManualEntry ? "True" : "False"}</i>
            </IonLabel>
            <IonLabel
              className="col-2 d-none d-lg-block"
              style={{ color: isBreach ? "red" : "black" }}
            >
              <i>{a.deviceModel}</i>
            </IonLabel>
            <Button
              variant="outlined"
              color="primary"
              hidden={!isAcknowledge}
              style={{ marginLeft: 25 }}
              onClick={() => handleClickOpen(readingId, breachValues)}
            >
              Notes
            </Button>
          </IonItem>
        );
      })}

      <Dialog open={open} onClose={handleClose}>
        <DialogContent style={{ height: 500, width: 500 }}>
          {/* <DialogContentText>{NoteText}</DialogContentText> */}
          {/* <DataGrid columns={columns} rows={note} onRowClick={(e) => {
            setNoteText(e.row.note)
          }}></DataGrid> */}
          {_.map(note, (v) => {
            return (
              <List
                sx={{
                  width: "100%",
                  maxWidth: 500,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "18px" }}
                    secondaryTypographyProps={{ fontStyle: "italic" }}
                    primary={v.note}
                    secondary={
                      v.fullName != ""
                        ? "by:" +
                          " " +
                          v.fullName +
                          " " +
                          "on:" +
                          " " +
                          v.fullDate
                        : ""
                    }
                  />
                </ListItem>
              </List>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </IonList>
  );
};

export default ReadingsComponent;
