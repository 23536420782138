import { format } from 'date-fns';
import { useContext, useEffect, useState } from "react";
import { ApplicationContext, ProviderContext } from "../../ApplicationState";
import * as ApiService from '../../services/ApiService';
import { BreachColumn } from './BreachThreshold';
var _ = require('lodash');

export const useBreachThreshold = () => {
    const { userState } = useContext(ApplicationContext);
    const { providerState } = useContext(ProviderContext);
    const [measurementData, setMeasurementData] = useState(null)
    const [row, setrow] = useState(null)
    const [columns, setColumns] = useState(null)
    const [loading, setLoading] = useState(true)
    let isApiSubscribed = false;
    
    useEffect(() => {
        
        setrow(null);
        if(!isApiSubscribed){
            getBreachMeasurement().then(r=>{
                setColumns(BreachColumn(r));
                setrow(r); 
            });
        }
        return () => {
            // cancel the subscription
            isApiSubscribed = true;
        };
    }, [providerState])

    const getBreachMeasurement = () => {
        setLoading(true)
        let breachData = {}
        return ApiService.fetchBreachMeasurement(userState.authenticatedUser, providerState.providerId).
            then( data => {
                setMeasurementData(data)
                const casdf = _.map(data, reading => {
                    const { readingSnapShot, email, userFullName, createdOn, reviewStatuses, breachValue, id, userId, measurementTypePart, measurementId } = reading;
                    if (!_.isEmpty(reviewStatuses)) {
                        return {
                            id: 1
                        }
                    }
                    const measurement = JSON.parse(readingSnapShot);
                    const measurementColumn = BreachColumn(measurement);
                    const dateFromString = new Date(createdOn);
                    const formatDate = format(dateFromString, 'yyyy-MM-dd');
                    const formatTime = format(dateFromString, 'hh:mm a');
                    const fullDate = formatDate + ' ' + formatTime;
                    setLoading(false);
                    //setColumns(measurementColumn);
                    
                    const readingValue = _.map(measurement, value => {
                        return value;
                    })

                    breachData = {
                        id: id,
                        email: email,
                        userFullName: userFullName,
                        createdOn: fullDate,
                        vitals: readingValue,
                        reviewStatuses: reviewStatuses,
                        userId: userId,
                        breachValue: breachValue,
                        longName: measurementTypePart.longName,
                        measurementId: measurementId
                    }
                    return breachData;
                })
                
                _.remove(casdf, { id: 1 });

                const orderByid = _.orderBy(_.uniqBy(casdf, 'id'), [(v) => new Date(v.createdOn)], ['desc']);
                const breachthreshold = _.uniqBy(orderByid, 'measurementId');
                return breachthreshold;
            })
    }

    const updateBreachNote = (data) => {
        return ApiService.updateBreachNote(userState.authenticatedUser, data).then((res) => {
            return res
        }).catch(error => {
            console.log("🚀 ~ file: useBreachThreshold.ts ~ line 81 ~ ApiService.updateBreachNote ~ error", error)
            return error
        })
    }

    const deleteRow = (datatoBeDeleted, data) => {
        row.splice(datatoBeDeleted[0], 1)
        refresh()
    }

    const refresh = () => {
        window.location.reload();
    }

    return {
        row,
        columns,
        loading,
        updateBreachNote,
        measurementData,
        deleteRow,
        setrow
    }
}