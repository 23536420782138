import React, { useContext } from "react";
import {
  IonPage,
  IonRow,
  IonCol,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonGrid,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonIcon,
  IonTitle,
} from "@ionic/react";
import { Auth } from "aws-amplify";
import { ApplicationContext, ProviderContext } from "../ApplicationState";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "./Home.css";
import jwt_decode from "jwt-decode";
import UsersComponent from "../components/UsersComponent";
import TotalReadingsChart from "../components/Charts/TotalReadingsChart";
import DrawPieChart from "../components/Charts/UsersCountChart";
import GroupReadingsComponent from "../components/GroupReadingsComponent";
import DrawLineChart from "../components/Charts/MeasurementChart";
import MeasurementChart from "../components/Charts/MeasurementChart";
import UsersCountChart from "../components/Charts/UsersCountChart";
import { arrowBackCircleSharp, addCircle } from "ionicons/icons";

const logout = () => {
  Auth.signOut();
};

const getDecodedAccessToken = (token: string) => {
  try {
    return jwt_decode(token);
  } catch (Error) {
    return null;
  }
};

const ContactUs: React.FC<any> = (props) => {
  const { userState } = useContext(ApplicationContext);
  const { providerState } = useContext(ProviderContext);

  return (
    <IonPage>
      <Header />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text="Go Back"
              icon={arrowBackCircleSharp}
              defaultHref="/"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonCard>
          <IonCardHeader>
            <IonTitle>Contact Us</IonTitle>
          </IonCardHeader>
          <IonCardContent>
            Please contact us at{" "}
            <a href="mailto: MedlineHealthApp@medline.com" target="_blank">
              MedlineHealthApp@medline.com
            </a>
            .
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default ContactUs;
