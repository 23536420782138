import { Box } from "@mui/system";
import { grid } from "ionicons/icons";
import { useContext, useState } from "react";
import { ApplicationContext } from "../../ApplicationState";
import { APP_STATE_NAME, LayoutContext } from "../../context/layout-context";
import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../context/store";

const LayoutsPage = () => {
  //const dispatch = useAppDispatch(); //dispatch actions using useDispatch.
  const { userState, setUserState } = useContext(ApplicationContext);
  // const { state } = useContext(LayoutContext);
  const state = useSelector((state: RootState) => state.layoutViewComponent);

  const [adjustedWidth, setAdjustedWidth] = useState(false);

  const rerenderParent = () => {
    setAdjustedWidth(true);
  };

  const handleReset = () => {
    localStorage.removeItem(
      APP_STATE_NAME + "_" + userState.authenticatedUser.username
    );
    window.location.reload();
  };

  return (
    <div>
      <div className="row mb-4 pb-2 border-bottom justify-content-between">
        <h4 className="col-10 p-0">My Dashboard</h4>
        <div className="col-2 text-end">
          <button className="btn btn-primary float-right" onClick={handleReset}>
            Reset Changes
          </button>
        </div>
      </div>
      <div className="card overflow-hidden">
        {state.views.map((view) =>
          !adjustedWidth ? (
            <Box
              className="justify-content-between"
              key={view.id + Math.random()}
              sx={{
                height: "100%",
                width: "100%",
                minWidth: 200,
              }}
            >
              <Layout
                key={view.id + Math.random()}
                view={view}
                rerenderParent={rerenderParent}
              />
            </Box>
          ) : (
            <Layout
              key={view.id + Math.random()}
              view={view}
              rerenderParent={rerenderParent}
            />
          )
        )}
      </div>
    </div>
  );
};

export default LayoutsPage;
