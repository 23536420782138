import { Autocomplete, Box, CircularProgress, Modal, TextField, useMediaQuery } from "@mui/material"
import React, { useState } from "react";
import { useEffect } from "react"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { IonButton, IonCardContent, IonLoading, IonPage, useIonLoading } from "@ionic/react";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

var _ = require("lodash");

interface UserDataType {
    id: string;
    name: string;
    phone: number;
}

const dataArray: UserDataType = {
    name: '',
    phone: 0,
    id: "",
}

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export const GroupsUsers = ({ isOpen, setIsOpen, users, addUsersToGroup, setUsers, row, handleUserDelete, right, setRight }) => {
    const matches = useMediaQuery('(min-width:600px)');

    const [present, dismiss] = useIonLoading();

    const [checked, setChecked] = useState([]);
    //const [left, setLeft] = useState(users);
    const [checkedusers, setcheckedusers] = useState([]);

    const leftChecked = intersection(checked, users);
    const rightChecked = intersection(checked, right);

    const checkedUserAlreadyAdded = (value) => {
        //const already = _.filter(row.groupUsers, val => val.id === value.id)
        const present = _.some(row.groupUsers, ['id', value.id])
        return present
    }

    // useEffect(() => {
    //     console.log("🚀 ~ GroupsUsers ~ right:", right)
    //     // if (!_.isEmpty(right)) {
    //     setRight([])
    //     setUsers(row.groupUsers)
    //     // }
    // }, [isOpen])

    const handleToggle = (value, side) => () => {

        // const already = row.usersCount > 0 && checkedUserAlreadyAdded(value)

        // if (side === 'left' && already) {
        //     alert(`Patient already added to the group.`)
        //     return
        // }

        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items))
        } else {
            setChecked(union(checked, items))
        }
    };

    const handleCheckedRight = () => {
        setcheckedusers(leftChecked)
        const concatUsers = right.concat(leftChecked)
        const sortRight = _.sortBy(concatUsers, 'name')
        setRight(sortRight)
        setUsers(not(users, leftChecked))
        setChecked(not(checked, leftChecked))
    };

    const handleCheckedLeft = () => {
        const concatUsers = users.concat(rightChecked)
        const sortLeft = _.sortBy(concatUsers, 'name')
        setUsers(sortLeft)
        setRight(not(right, rightChecked))
        setChecked(not(checked, rightChecked))

        _.map(rightChecked, (user, index) => {
            const userslen = rightChecked.length - 1 === index
            const already = row.usersCount > 0 && checkedUserAlreadyAdded(user)
            if (already) {
                handleUserDelete(user, userslen, 'modal')
            }
        })
    };

    const customList = (title, items, side) => {

        return (
            <Card>
                <CardHeader
                    sx={{ px: 2, py: 1 }}
                    avatar={
                        <Checkbox
                            onClick={handleToggleAll(items)}
                            checked={numberOfChecked(items) === items.length && items.length !== 0}
                            indeterminate={
                                numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                            }
                            disabled={items.length === 0}
                            inputProps={{
                                'aria-label': 'all items selected',
                            }}
                        />
                    }
                    title={title}
                    subheader={`${numberOfChecked(items)}/${items.length} selected`}
                />
                <Divider />
                <List
                    sx={{
                        width: 200,
                        height: !matches ? null : 400,
                        bgcolor: 'background.paper',
                        overflow: 'auto',
                    }}
                    dense
                    component="div"
                    role="list"
                >
                    {items.map((value) => {
                        const labelId = `transfer-list-all-item-${value}-label`;
                        const name = value.name

                        return (
                            <ListItem
                                key={value}
                                role="listitem"
                                button
                                onClick={handleToggle(value, side)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={name} />
                            </ListItem>
                        );
                    })}
                </List>
            </Card >
        )
    }

    const style = {
        width: '50%',
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 10,
    }


    return (
        // <div
        //     style={{
        //         margin: "10px",
        //         backgroundColor: 'red'
        //     }}
        // >
        <Modal
            hideBackdrop
            onClose={() => {
                setIsOpen(false);
            }}
            open={isOpen}
            style={{
                display: 'flex',
                //position: 'absolute',
                alignItems: 'center',
                justifyContent: 'center',
                top: 0,
                left: 0,
                overflow: 'hidden',
                overflowY: 'scroll'
            }}
        >
            <Box sx={style}>
                {/* <h1 style={{ color: "black", marginBottom: 30 }}>{row ? row.name : ''}</h1> */}

                <Grid container spacing={{ xs: 1, md: 2 }} justifyContent="center" alignItems="center">
                    <Grid item>{customList('Select All', users, 'left')}</Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                sx={{ my: 0.5 }}
                                variant="contained"
                                size='small'
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="move selected right"
                            >
                                {'Add >>'}
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="contained"
                                size="small"
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="move selected left"
                            >
                                {'<< Remove'}
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item>{customList('Select All', right, 'right')}</Grid>

                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent='center'
                        alignItems="center">
                        <IonButton
                            size="default"
                            disabled={_.isEmpty(right)}
                            color="primary"
                            style={{ height: 50, }}
                            onClick={() => {
                                setIsOpen(false)

                                const addedUsers = _.uniqBy(right, 'id')

                                addUsersToGroup(addedUsers.length, row.groupUsers, _.uniqBy(addedUsers, 'id'))
                            }
                            }>
                            Save
                        </IonButton>
                        <IonButton
                            size="default"
                            color="primary"
                            style={{ height: 50 }}
                            onClick={() => {
                                setIsOpen(false)
                            }
                            }>
                            Close
                        </IonButton>
                    </Grid>

                </Grid>

            </Box>
        </Modal >
        // </div>
    )
}