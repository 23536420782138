import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonLabel,
  IonInput,
  IonToast,
  IonText,
  IonPage,
  IonContent,
  IonCol,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonCardHeader,
  IonDatetime,
  IonList,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonIcon,
  IonSearchbar,
  IonBackButton,
  IonListHeader,
  IonLoading,
} from "@ionic/react";
import { useHistory, useLocation } from "react-router";

import * as ApiService from "../../services/ApiService";
import "./GroupReadings.css";
import {
  ApplicationContext,
  ProviderContext,
  ProviderContextProvider,
} from "../../ApplicationState";
import ReadingsComponent from "../../components/ReadingsComponent";
import Header from "../../components/Header/Header";
import GroupReadingsComponent from "../../components/GroupReadingsComponent";
import moment from "moment";
import { formatReading } from "../../services/Helpers";
import {
  addCircle,
  arrowBackCircleSharp,
  arrowDown,
  arrowUp,
  saveSharp,
  swapVerticalSharp,
} from "ionicons/icons";
import saveAs from "save-as";
import * as Helpers from "../../services/Helpers";
import { CloudDownloadSharp } from "react-ionicons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { format } from "date-fns";
import { GiNotebook } from "react-icons/gi";

var _ = require("lodash");

const GroupReadings: React.FC<any> = (props: any) => {
  const measurementTypesSelect = useRef();
  const location = useLocation();
  const [searchText, setSearchText] = useState("");
  const [searchTempText, setSearchTempText] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [readings, setReadings] = React.useState<any[]>([]);
  const [filteredReadings, setFilteredReadings] = React.useState<any[]>([]);
  const { userState } = useContext(ApplicationContext);
  const { providerState } = useContext(ProviderContext);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [disableLoadMoreButton, setDisableButton] = React.useState(false);
  const [disableInfiniteScroll, setDisableInfiniteScroll] =
    useState<boolean>(false);
  const dateFormatString = "YYYY-MM-DD";
  let startDate = moment(new Date());
  let endDate = moment(new Date());
  const [selectedStartDate, setSelectedStartDate] = React.useState<string>(
    startDate?.add(-12, "months").format(dateFormatString)
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState<string>(
    endDate?.format(dateFormatString)
  );
  const [sortKey, setSortKey] = React.useState("readingOn");
  const [sortDirection, setSortDirection] = React.useState(0);
  const [selectedMeasurementTypes, setSelectedMeasurementTypes] =
    React.useState([]);
  const [selectedReadingTypes, setSelectedReadingTypes] =
    React.useState(location.state["readingType"])
  const [open, setOpen] = React.useState(false);
  const [note, setNote] = useState([]);

  function updateDisplayName(selectedUser) {
    let userAttributes: any = selectedUser;
    if (userAttributes !== undefined) {
      let given_name = userAttributes.given_name;
      let family_name = userAttributes.family_name;
      setDisplayName(given_name + " " + family_name);
    }
  }
  let isSubscribed = true;
  const inputRef = useRef<any>(null);

  useEffect((): any => {
    updateDisplayName(props.selectedUser);
    let isTypeDefined = false;
    if (
      providerState.providerId !== undefined &&
      providerState.providerId !== ""
    ) {
      if (isSubscribed) {
        if (
          location.state !== undefined &&
          location.state["measurementType"] !== undefined
        ) {
          //console.log(location);
          selectedMeasurementTypes.push(location.state["measurementType"]);
        }

        if (
          location.state !== undefined &&
          location.state["readingType"] !== undefined
        ) {
          //console.log(location);

          isTypeDefined = true;

        }
        setShowLoading(true);
        let pageNum = 1;
        setPageNumber(1);
        ApiService.fetchReadings(
          userState.authenticatedUser,
          providerState.providerId,
          pageSize,
          pageNum,
          moment(selectedStartDate).format(dateFormatString),
          moment(selectedEndDate).format(dateFormatString),
          sortKey,
          sortDirection,
          searchText,
          selectedMeasurementTypes.join(","),
          selectedReadingTypes
        ).then((data) => {
          setShowLoading(false);

          if (data && data.length > 0) {
            let temp: any;

            if (isTypeDefined)
              temp = Helpers.filterReadings(
                data,
                "isManualEntry",
                location.state["readingType"]
              );

            setPageNumber(pageNum + 1);
            setReadings(temp !== undefined ? temp : data);
            setFilteredReadings(temp !== undefined ? temp : data);
            //Helpers.formatReadingForChart(data);
            setDisableInfiniteScroll(data.length < pageSize);
          } else {
            setReadings(null);
            setFilteredReadings(null);
            setDisableButton(true);
            setDisableInfiniteScroll(true);
          }
          setTimeout(() => inputRef.current.setFocus(), 200);
        });
      }
    }
    return () => (isSubscribed = false);
  }, [
    searchText,
    sortKey,
    sortDirection,
    selectedStartDate,
    selectedEndDate,
    selectedMeasurementTypes,
    providerState,
    selectedReadingTypes
  ]);

  async function LoadMoreData($event: CustomEvent<void>) {
    let isTypeDefined = false;
    if (
      location.state !== undefined &&
      location.state["measurementType"] !== undefined
    ) {
      //console.log(location);
      selectedMeasurementTypes.push(location.state["measurementType"]);
    }

    if (
      location.state !== undefined &&
      location.state["readingType"] !== undefined
    ) {
      //console.log(location);
      isTypeDefined = true;
    }

    await ApiService.fetchReadings(
      userState.authenticatedUser,
      providerState.providerId,
      pageSize,
      pageNumber,
      moment(selectedStartDate).format(dateFormatString),
      moment(selectedEndDate).format(dateFormatString),
      sortKey,
      sortDirection,
      searchText,
      selectedMeasurementTypes.join(","),
      selectedReadingTypes
    ).then((data) => {
      if (data && data.length > 0) {
        let temp: any;
        if (isTypeDefined)
          temp = Helpers.filterReadings(
            data,
            "isManualEntry",
            location.state["readingType"]
          );
        setPageNumber(pageNumber + 1);
        setReadings(temp !== undefined ? [...readings, ...temp] : [...readings, ...data]);
        //if (searchText === "") {
        setFilteredReadings(temp !== undefined ? [...readings, ...temp] : [...readings, ...data]);
        // } else {
        //   let filtered = data.filter((r) => {
        //     return r.measurementType
        //       .toLowerCase()
        //       .includes(searchText.toLowerCase());
        //   });
        //   setFilteredReadings([...readings, ...filtered]);
        // }
        setDisableInfiniteScroll(data.length < pageSize);
      } else {
        //setDisableButton(false);
        setDisableInfiniteScroll(true);
      }
      setTimeout(() => inputRef.current.setFocus(), 200);
    });
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  let timer: any;
  let searchValue: string;

  const onSearchTextChange = (searchEvent) => {
    //console.log(searchEvent);
    //console.log(searchTempText);
    if (searchEvent.key === "Enter") {
      //clearTimeout(timer);
      //timer = setTimeout(() => {
      setSortKey("");
      setSearchText(searchTempText!);
      //}, 2000);
    }
    // if (searchTerm === "") {
    //   setFilteredReadings(readings);
    //   return;
    // }
    // //
    //
    // let filtered = readings.filter((r) => {
    //
    //   return (
    //     r.measurementType.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     r.userName.toLowerCase().includes(searchTerm.toLowerCase())
    //   );
    // });
    //
    // setFilteredReadings(filtered);
  };

  //sort

  const sortBy = (key) => {
    if (key == sortKey) {
      setSortDirection((sortDirection % 2) + 1);
      setSortKey(key);

      //sort(key, sortDirection + 1);
    } else {
      setSortDirection(1);
      setSortKey(key);

      //sort(key, 1);
    }
  };

  function sort(key, direction) {
    if (direction == 1) {
      var sfilteredReadings = filteredReadings.sort((a, b) => {
        const valA = a[key];
        const valB = b[key];
        return valA.localeCompare(valB);
      });
      setFilteredReadings(sfilteredReadings);
    } else if (direction == 2) {
      var sfilteredReadings = filteredReadings.sort((a, b) => {
        const valA = a[key];
        const valB = b[key];
        return valB.localeCompare(valA);
      });
      setFilteredReadings(sfilteredReadings);
    } else {
      setSortDirection(0);
      setSortKey("");
      setFilteredReadings(readings);
    }
  }
  //end sort

  const downloadPdf = () => {
    setShowLoading(true)
    ApiService.downloadGroupPdf(
      userState.authenticatedUser,
      providerState.providerId,
      moment(selectedStartDate).format(dateFormatString),
      moment(selectedEndDate).format(dateFormatString),
      sortKey,
      sortDirection,
      searchText,
      selectedMeasurementTypes.join(","),
      selectedReadingTypes
    ).then((data) => {
      setShowLoading(false)
      const downloadedFile = Helpers.base64ToBlob(
        data.fileContents,
        data.contentType
      );
      saveAs(downloadedFile);
    }).catch((err) => {
      setShowLoading(false)
      alert('Downloading failed. Please try again.')
      console.log("error downloadUser pdf Group Reading")
    })
  };

  const downloadXls = () => {
    setShowLoading(true)
    ApiService.downloadGroupXls(
      userState.authenticatedUser,
      providerState.providerId,
      moment(selectedStartDate).format(dateFormatString),
      moment(selectedEndDate).format(dateFormatString),
      sortKey,
      sortDirection,
      searchText,
      selectedMeasurementTypes.join(","),
      selectedReadingTypes
    ).then((data) => {
      setShowLoading(false)
      const downloadedFile = Helpers.base64ToBlob(
        data.fileContents,
        data.contentType
      );
      saveAs(downloadedFile);
    }).catch((err) => {
      setShowLoading(false)
      alert('Downloading failed. Please try again.')
      console.log("error downloadUserXls Group Reading")
    })
  };

  const onMeasurementSelectionChange = (measurementTypes) => {
    setSelectedMeasurementTypes(measurementTypes);
  };

  const onReadingTypesSelectionChange = (readingTypes) => {
    setSelectedReadingTypes(readingTypes);
  };

  const handleClickOpen = (id, breachValues) => {
    let rowid = 0;
    if (breachValues != null) {
      const breach = breachValues.map((d) => {
        return d.reviewStatuses;
      });
      const breachNote = breach.map((v) => {
        if (!_.isEmpty(v)) {
          const note = v[0].note;
          const fullName = v[0].reviewedBy;
          const dateFromString = new Date(v[0].reviewedOn);
          const formatDate = format(dateFromString, "yyyy-MM-dd");
          const formatTime = format(dateFromString, "hh:mm a");
          const fullDate = formatDate + " " + formatTime;
          rowid = rowid + 1;
          return { note, id: rowid, fullName, fullDate };
        } else {
          return {
            note: "",
            id: "",
            fullName: "",
            fullDate: "",
          };
        }
      });
      setNote(_.reverse(breachNote));
    } else {
      setNote([
        {
          note: "",
          id: "",
          fullName: "",
          fullDate: "",
        },
      ]);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <IonPage>
      <Header />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text="Go Back"
              icon={arrowBackCircleSharp}
              defaultHref="/"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <IonTitle>
              Displaying readings for {providerState.providerName}
            </IonTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonItem className="col">
                  <IonSearchbar
                    id="q"
                    ref={(ref) => (inputRef.current = ref)}
                    placeholder="Search Patient Name"
                    value={searchTempText}
                    onIonChange={(e) => setSearchTempText(e.detail.value)}
                    onKeyUp={(e) => onSearchTextChange(e)}
                    showCancelButton="always"
                  ></IonSearchbar>
                </IonItem>
                <IonItem className="col">
                  <IonButton onClick={() => downloadPdf()} slot="end">
                    PDF&nbsp;&nbsp;&nbsp;
                    <CloudDownloadSharp
                      color={"#00000"}
                      title="Download pdf"
                      height="15px"
                      width="15px"
                    />
                  </IonButton>
                  <IonButton onClick={() => downloadXls()} slot="end">
                    Xls&nbsp;&nbsp;&nbsp;
                    <CloudDownloadSharp
                      color={"#00000"}
                      title="Download xls"
                      height="15px"
                      width="15px"
                    />
                  </IonButton>
                </IonItem>
              </IonRow>
              <IonRow>
                <IonItem>
                  <IonSelect
                    ref={measurementTypesSelect}
                    multiple
                    placeholder="Select Measurement Type"
                    onIonChange={(e) =>
                      onMeasurementSelectionChange(e.detail.value)
                    }
                  >
                    {Helpers.measurementTypes().map((a, index) => {
                      return (
                        <IonSelectOption value={a} key={index}>
                          <IonItem>{a}</IonItem>
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>

                <IonItem>
                  <IonSelect
                    //ref={measurementTypesSelect}
                    placeholder="Select Reading Type"
                    onIonChange={(e) =>
                      onReadingTypesSelectionChange(e.detail.value)
                    }
                  >
                    {Helpers.readingTypes.map((a, index) => {
                      return (
                        <IonSelectOption value={a} key={index}>
                          <IonItem>{a}</IonItem>
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>

                <IonItem className="col-4">
                  <IonLabel slot="start">Start Date:</IonLabel>
                  <IonDatetime
                    slot="start"
                    pickerFormat={dateFormatString}
                    displayFormat={dateFormatString}
                    value={selectedStartDate}
                    onIonChange={(e) => setSelectedStartDate(e.detail.value!)}
                  ></IonDatetime>
                </IonItem>
                <IonItem className="col-4">
                  <IonLabel slot="start">End Date:</IonLabel>
                  <IonDatetime
                    slot="start"
                    pickerFormat={dateFormatString}
                    displayFormat={dateFormatString}
                    placeholder="Select Date"
                    value={selectedEndDate}
                    onIonChange={(e) => setSelectedEndDate(e.detail.value!)}
                  ></IonDatetime>
                </IonItem>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
        <IonListHeader color="primary">
          <IonLabel onClick={() => sortBy("userName")} className="col-2 link">
            <u>Name</u> &nbsp;
            {sortKey == "userName" ? (
              sortDirection % 2 == 0 ? (
                <IonIcon icon={arrowUp} />
              ) : (
                <IonIcon icon={arrowDown} />
              )
            ) : (
              ""
            )}
          </IonLabel>
          <IonLabel
            onClick={() => sortBy("measurementType")}
            className="col-2 link"
          >
            <u>Measurement Type</u>&nbsp;
            {sortKey == "measurementType" ? (
              sortDirection % 2 == 0 ? (
                <IonIcon icon={arrowUp} />
              ) : (
                <IonIcon icon={arrowDown} />
              )
            ) : (
              ""
            )}
          </IonLabel>
          <IonLabel className="col-2">Value</IonLabel>
          <IonLabel onClick={() => sortBy("readingOn")} className="col-2 link">
            <u>Date</u>&nbsp;
            {sortKey == "readingOn" ? (
              sortDirection % 2 == 0 ? (
                <IonIcon icon={arrowUp} />
              ) : (
                <IonIcon icon={arrowDown} />
              )
            ) : (
              ""
            )}
          </IonLabel>
          <IonLabel
            onClick={() => sortBy("isManualEntry")}
            className="col-2 link d-none d-lg-block"
          >
            {selectedReadingTypes === 'Manual Reading' && <u>{'Manual'}</u>}
            {selectedReadingTypes === 'Automatic Reading' && <u>{'Automatic'}</u>}
            {selectedReadingTypes === undefined && <u>{'Manual/Automatic'}</u>}
            {selectedReadingTypes === 'All' && <u>{'Manual/Automatic'}</u>}
            {sortKey == "isManualEntry" ? (
              sortDirection % 2 == 0 ? (
                <IonIcon icon={arrowUp} />
              ) : (
                <IonIcon icon={arrowDown} />
              )
            ) : (
              ""
            )}
          </IonLabel>
          <IonLabel
            className="col-2 d-none d-lg-block col-3 "
            style={{ marginLeft: -100 }}
          >
            Device
          </IonLabel>
          <IonLabel
            className="col-2 d-none d-lg-block col-3"
            style={{ marginLeft: -100 }}
          >
            Notes
          </IonLabel>
        </IonListHeader>
        <IonList>
          {/* <IonRow>
              <IonCol><IonItem><IonHeader>Patient</IonHeader></IonItem></IonCol>
              <IonCol><IonItem><IonHeader>Measurement</IonHeader></IonItem></IonCol>
              <IonCol><IonItem><IonHeader>Reading</IonHeader></IonItem></IonCol>
              <IonCol><IonItem><IonHeader>Date</IonHeader></IonItem></IonCol>
            </IonRow> */}
          {filteredReadings?.map((a, index) => {
            let username: any[] = a["userName"].replace(/\b(\w)/g, (s) =>
              s.toUpperCase()
            );
            let isBreach = a.isBreach;
            let isAcknowledge = a.isAcknowledged;
            let measurementType: any[] = a["measurementType"];
            let readingOn: any[] = a["readingOn"];
            let reading = a["reading"];
            let readingId = a["id"];
            let utcTime = moment.utc(readingOn);
            let localTime = utcTime.local().format("MMM DD YYYY hh:mm A");
            const breachValues = a.breachValues;
            return (
              // <IonRow key={readingId} onClick={() => { }}>
              //   <IonCol><IonItem><IonLabel>{username}</IonLabel></IonItem></IonCol>
              //   <IonCol><IonItem><IonLabel>{measurementType}</IonLabel></IonItem></IonCol>
              //   <IonCol><IonItem><IonLabel><i>{formatReading(a)}</i></IonLabel></IonItem></IonCol>
              //   <IonCol><IonItem><IonLabel><i>{localTime}</i></IonLabel></IonItem></IonCol>
              // </IonRow>
              <IonItem key={readingId} button onClick={() => { }} lines="full">
                <IonLabel
                  className="col-2 text-wrap"
                  style={{ color: isBreach ? "red" : "black" }}
                >
                  {username}
                </IonLabel>
                <IonLabel
                  className="col-2"
                  style={{ color: isBreach ? "red" : "black" }}
                >
                  {Helpers.formatMeasurementType(a)}
                </IonLabel>
                <IonLabel
                  className="col-2"
                  style={{ color: isBreach ? "red" : "black" }}
                >
                  <i>{formatReading(a)}</i>
                </IonLabel>
                <IonLabel
                  className="col-2"
                  style={{ color: isBreach ? "red" : "black" }}
                >
                  <i>{localTime}</i>
                </IonLabel>
                <IonLabel
                  className="col-2 d-none d-lg-block"
                  style={{ color: isBreach ? "red" : "black" }}
                >
                  <i>{a.isManualEntry ? "True" : "False"}</i>
                </IonLabel>
                <IonLabel
                  className="col-2 d-none d-lg-block col-3 "
                  style={{
                    color: isBreach ? "red" : "black",
                    marginLeft: -100,
                  }}
                >
                  <i>{a.deviceModel}</i>
                </IonLabel>
                <Button
                  key={readingId}
                  color="primary"
                  hidden={!isAcknowledge}
                  style={{ marginLeft: -100 }}
                  onClick={() => handleClickOpen(readingId, breachValues)}
                >
                  <GiNotebook />
                </Button>
              </IonItem>
            );
          })}
          {/* <IonItem>
              <IonButton
                onClick={(e: any) => LoadMoreData(e)}
                color="primary"
                slot="end"
                disabled={disableLoadMoreButton}
              >
                Load More Data
              </IonButton>
              <IonButton
                onClick={() => {
                  setPageNumber(1);
                  LoadMoreData(null);
                }}
                color="primary"
                slot="end"
                disabled={!disableLoadMoreButton}
              >
                Reset
              </IonButton>
            </IonItem> */}
        </IonList>
        <IonInfiniteScroll
          threshold="10px"
          disabled={disableInfiniteScroll}
          onIonInfinite={(e: CustomEvent<void>) => LoadMoreData(e)}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
      </IonContent>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent style={{ height: 500, width: 500 }}>
          {/* <DialogContentText>{NoteText}</DialogContentText> */}
          {/* <DataGrid columns={columns} rows={note} onRowClick={(e) => {
            setNoteText(e.row.note)
          }}></DataGrid> */}
          {_.map(note, (v) => {
            return (
              <List
                sx={{
                  height: 400,
                  minWidth: 400,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "18px" }}
                    secondaryTypographyProps={{ fontStyle: "italic" }}
                    primary={v.note}
                    secondary={
                      v.fullName != ""
                        ? "by:" +
                        " " +
                        v.fullName +
                        " " +
                        "on:" +
                        " " +
                        v.fullDate
                        : ""
                    }
                  />
                </ListItem>
              </List>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </IonPage>
  );
};

export default GroupReadings;
