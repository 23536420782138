import { IonAlert, IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React, { useContext, useEffect } from "react";
import { Route } from "react-router-dom";
import Home from "./pages/Home";

import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { AmplifyAuthenticator } from "@aws-amplify/ui-react";
import Amplify, { Auth } from "aws-amplify";

import { ENV } from "../src/environments/env";
import {
  ApplicationContext,
  ProviderContextProvider,
} from "./ApplicationState";
import { Menu } from "./components/Menu/Menu";
import ContactUs from "./pages/ContactUs";
import Patients from "./pages/patients/patients";
import Provider from "./pages/providers/provider";
import Providers from "./pages/providers/providers";
import GroupReadings from "./pages/Readings/GroupReadings";
import Readings from "./pages/Readings/Readings";
import BillingReport from "./pages/Reports/BillingReport";
import Threshold from "./pages/Threshold/Threshold";
import Register from "./pages/UserProfile/Register";
import UpdateProfile from "./pages/UserProfile/UpdateProfile";
import UserProfile from "./pages/UserProfile/UserProfile";
import Users from "./pages/Users/Users";
import { getUserAttributes } from "./services/Helpers";
import Notifications from "./components/Notifications/Notifications";
import ScheduleDetails from "./components/Notifications/ScheduleDetails";
import HangfireDashboard from "./components/Notifications/HangfireDashboard";
import Filemanager from "./pages/FileManager/Filemanager";
import { Groups } from "./pages/GroupsAndUsers/Groups";
import { Survey } from "./pages/Survey/Survey";

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: `${ENV.AWS_REGION}`,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: `${ENV.AWS_USER_POOL_ID}`,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: `${ENV.AWS_USER_POOL_WEBCLIENT_ID}`,
  },
});
Auth.configure();
const App: React.FC = () => {
  const { userState, setUserState } = useContext(ApplicationContext);
  //Auth.signOut();
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<any | undefined>(undefined);
  const [userAttributes, setUserAttributes] = React.useState<any | undefined>(
    undefined
  );

  useEffect(() => {
    if (user) {
      //console.log(user);
      setUserState({ authenticatedUser: user });
    }
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      let data: any = authData;
      if (data !== undefined) {
        setUserAttributes(
          getUserAttributes(data.signInUserSession.idToken.jwtToken)
        );
        setUser(authData);
      }
    });
  }, [authState, user]);

  if (authState === AuthState.SignedIn && user) {
    if (
      userAttributes?.roles?.includes("Administrator") ||
      userAttributes?.roles?.includes("Provider") ||
      userAttributes?.roles?.includes("Support")
    ) {
      //console.log(userAttributes);
      return (
        <IonApp>
          <IonSplitPane contentId="main">
            <ProviderContextProvider>
              <Menu />
              <IonReactRouter>
                <IonRouterOutlet id="main">
                  <Route path="/">
                    <Home />
                  </Route>
                  <Route path="/home" exact>
                    <Home />
                  </Route>
                  <Route exact path="/users">
                    <Users />
                  </Route>
                  <Route exact path="/groups">
                    <Groups />
                  </Route>
                  <Route exact path="/threshold">
                    <Threshold />
                  </Route>
                  <Route exact path="/fileManager">
                    <Filemanager />
                  </Route>
                  <Route exact path="/readings">
                    <Readings />
                  </Route>
                  <Route exact path="/group-readings">
                    <GroupReadings />
                  </Route>
                  <Route exact path="/schedule">
                    <Notifications />
                  </Route>
                  {/* <Route exact path="/survey">
                    <Survey />
                  </Route> */}
                  <Route exact path="/scheduleDetails">
                    <ScheduleDetails />
                  </Route>
                  <Route exact path="/hangfire">
                    <HangfireDashboard />
                  </Route>
                  <Route exact path="/patients">
                    <Patients />
                  </Route>
                  <Route exact path="/userprofile">
                    <UserProfile />
                  </Route>
                  <Route exact path="/register">
                    <Register />
                  </Route>
                  <Route exact path="/updateprofile">
                    <UpdateProfile />
                  </Route>
                  <Route exact path="/contact-us">
                    <ContactUs />
                  </Route>
                  <Route exact path="/providers">
                    <Providers />
                  </Route>
                  <Route exact path="/provider">
                    <Provider />
                  </Route>
                  <Route exact path="/billing-report">
                    <BillingReport />
                  </Route>
                </IonRouterOutlet>
              </IonReactRouter>
            </ProviderContextProvider>
          </IonSplitPane>
        </IonApp>
      );
    } else {
      return (
        <IonAlert
          isOpen={true}
          header={"Login Failed"}
          message={
            "Sorry! you do not have permission to login to the application."
          }
          buttons={[
            {
              text: "Okay",
              handler: () => {
                Auth.signOut();
              },
            },
          ]}
        />
      );
      return <AmplifyAuthenticator />;
    }
  } else {
    return <AmplifyAuthenticator />;
  }
};

export default App;
