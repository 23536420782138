import { useIonLoading } from "@ionic/react";
import { useContext, useEffect, useMemo, useState } from "react"
import * as ApiService from '../../services/ApiService'
import { ApplicationContext, ProviderContext } from "../../ApplicationState";

interface ProviderThreshold {
    thresholdType: string
    longName: string
    thresholdValue: string
    providerName: string
}

export const useThreshold = () => {
    const { userState } = useContext(ApplicationContext);
    const { providerState } = useContext(ProviderContext);
    const [providers, setProviders] = useState(null)
    const [providerData, setProviderData] = useState(null)
    const [present, dismiss] = useIonLoading();
    const [load, setLoad] = useState(false);

    useEffect(() => {
        getProviderThreshold().then((value) => {

            if (value) {
                setProviders(value)
            }

        })

    }, [providerState])

    const getProviderThreshold = async () => {
        setLoad(true)
        // present({
        //     message: 'Loading...'
        // })
        return await ApiService.fetchProviderAndThreshold(
            userState.authenticatedUser,
        ).then((data) => {
            const providerData = data.filter((value) => {
                const { id, providerName, thresholds } = value
                return providerName === providerState.providerName
            })
            dismiss()
            const { thresholds, providerName } = providerData[0]

            setProviderData(providerData[0])
            if (thresholds.length !== 0) {
                return thresholds.map((thres) => {
                    const { providerId, measurementTypePart, thresholdType, thresholdValue } = thres
                    const { longName } = measurementTypePart

                    return {
                        thresholds,
                        longName,
                        thresholdType,
                        thresholdValue,
                        providerName
                    }
                })
            } else {
                return [{
                    longName: '',
                    thresholdType: '',
                    thresholdValue: '',
                    providerName: ''
                }]
            }

        }).catch(err => {
            console.log("🚀 ~ file: useThreshold.ts ~ line 30 ~ ).then ~ err", err)
            setLoad(false)
            dismiss()
        })
    }

    return {
        providerData,
        providers,
        load,
        userState
    }

}