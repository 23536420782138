import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { IonButton, IonCard } from "@ionic/react";

export default function GroupsFields({ handleAddGroups }) {
    const [groupName, setGroupName] = React.useState('')
    const [groupDesc, setGroupdesc] = React.useState('')

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4
    };

    return (
        < >
            <TextField
                style={{ width: 300, marginRight: 20, marginLeft: 20 }}
                id="outlined-basic"
                label="Group Name"
                variant="outlined"
                value={groupName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    console.log("🚀 ~ file: provider.tsx:717 ~ event:", event.target)
                    setGroupName(event.target.value)
                    //setGroupArray([...groupArray, event.target.value]);
                }}
            />

            <TextField
                style={{ width: 300, marginRight: 20 }}
                id="outlined-basic-desc"
                label="Group Description"
                variant="outlined"
                // disabled={_.isEmpty(groupArray)}
                value={groupDesc}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setGroupdesc(event.target.value)
                }}
            />
            <IonButton
                size="default"
                color="primary"
                style={{ marginLeft: 30, height: 50, marginBottom: 10 }
                }
                onClick={() => {
                    handleAddGroups(groupName, groupDesc)
                    setGroupName('')
                    setGroupdesc('')
                }
                }> + Create Group </IonButton >

        </>

    )

    // return (
    //     <div
    //         style={{
    //             margin: "10px"
    //         }}
    //     >
    //         <Modal
    //             onClose={() => {
    //                 setIsOpen(false);
    //             }}
    //             open={isOpen}
    //         >
    //             <Box sx={style}>
    //                 <div>
    //                     <TextField fullWidth
    //                         name="name"
    //                         label="Group Name"
    //                         onChange={(event) => setGroupName(event.target.value)}
    //                     />
    //                     <TextField fullWidth
    //                         name="name"
    //                         label="Group Description"
    //                         onChange={(event) => setGroupdesc(event.target.value)}
    //                     />


    //             </Box>
    //         </Modal>
    //     </div>
    // );
}
